import { Col, Row } from "reactstrap";
import { ApiFormsProps } from "./ApiFormsProps";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import RequiredLabel from "Components/Common/RequiredLabel";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import TextInput from "Components/Form/TextInput";
import { useDebounce } from "Components/Hooks/HelperHooks";
import * as Yup from "yup";

const FedexApiForm = (props: ApiFormsProps) => {
    const { t } = useTranslation();

    let value = props.value ?? {};

    const validation = useFormik({
        initialValues: {
            accountNumber: value["accountNumber"] ?? "",
            apiKey: value["apiKey"] ?? "",
            apiSecret: value["apiSecret"] ?? ""
        },
        validationSchema: Yup.object({
            accountNumber: Yup.string().required(t("FedEx Account Number is required")),
            apiKey: Yup.string().required(t("FedEx API Key is required")),
            apiSecret: Yup.string().required(t("FedEx API Secret is required"))
        }),
        onSubmit: (values) => {
            props.onChange?.(values);
        }
    });

    const submitDebounced = useDebounce(() => validation.isValid && validation.submitForm(), 200);

    return <Row className="g-2">
        <Col xs={12}>
            <RequiredLabel>{t("FedEx Account Number")}</RequiredLabel>
            <ValidationWrapper validation={validation} field="accountNumber">
                <TextInput onChange={submitDebounced} />
            </ValidationWrapper>
        </Col>
        <Col xs={12}>
            <RequiredLabel>{t("FedEx API Key")}</RequiredLabel>
            <ValidationWrapper validation={validation} field="apiKey">
                <TextInput onChange={submitDebounced} />
            </ValidationWrapper>
        </Col>
        <Col xs={12}>
            <RequiredLabel>{t("FedEx API Secret")}</RequiredLabel>
            <ValidationWrapper validation={validation} field="apiSecret">
                <TextInput onChange={submitDebounced} />
            </ValidationWrapper>
        </Col>
    </Row>;
}

export default FedexApiForm;
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CompanyContract } from "api/types/contracts/companies";
import { PagedList } from "helpers/api_helper";
import { ApiError, LoadingStates } from "helpers/types";

type CompanyLoadingStates = LoadingStates<"list" | "delete" | "selectList" | "status">;

export type CompanyState = {
    list: Partial<PagedList<CompanyContract>>,
    selectList: CompanyContract[],
    loading: CompanyLoadingStates,
    error?: ApiError
}

export const initialState: CompanyState = {
    list: {},
    selectList: [],
    loading: {
        list: false,
        selectList: false,
        delete: false,
        status: false
    }
};

const CompanySlice = createSlice({
    name: "CompanySlice",
    initialState,
    reducers: {
        setList(state, action: PayloadAction<PagedList<CompanyContract>>) {
            state.list = action.payload;
        },
        setSelectList(state, action: PayloadAction<CompanyContract[]>) {
            state.selectList = action.payload;
        },
        loading(state, action: PayloadAction<[keyof CompanyLoadingStates, boolean]>) {
            const [operation, loadingState] = action.payload;
            state.loading[operation] = loadingState;

            if (loadingState) {
                state.error = undefined;
            }
        },
        apiError(state, action: PayloadAction<ApiError | undefined>) {
            state.error = action.payload;
        }
    },
});

export const {
    setList,
    setSelectList,
    loading,
    apiError
} = CompanySlice.actions;

export default CompanySlice.reducer;

import { InputGroup, InputGroupText } from "reactstrap";
import NumberInput, { NumberInputProps } from "./NumberInput";
import classNames from "classnames";

export type PercentInputProps = NumberInputProps;

const PercentInput = ({ className, size, ...rest }: PercentInputProps) => {
    return <InputGroup size={size}>
        <NumberInput {...rest} decimalPlaces={2} className={classNames("border-end-0", className)} />
        <InputGroupText className="bg-transparent pe-2 ps-0">%</InputGroupText>
    </InputGroup>
}

export default PercentInput;
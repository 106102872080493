import { ShippingPurpose } from "api/types/contracts/inbound";
import { EnumSelectRadio } from "helpers/types";
import { useTranslation } from "react-i18next";
import SelectInput from "Components/Form/SelectInput";
import { useMemo } from "react";

const ShippingPurposeRadio = (props: EnumSelectRadio<ShippingPurpose>) => {
    const { t } = useTranslation();
    const { value, onChange, direction, disabledCallback, ...rest } = props;

    const options = useMemo(() => ["storage", "fba", "dropship"].map(p => ({
        value: p as ShippingPurpose,
        label: t(`enums:ShippingPurpose.${p}`),
        disabled: disabledCallback?.(p as ShippingPurpose) ?? false
    })), [t]);

    return <SelectInput options={options} onChange={onChange} value={value} 
        display={direction === "horizontal" ? "inline-radio" : "radio"} {...rest} />;
};

export default ShippingPurposeRadio;

import { APIClient } from "../helpers/api_helper";

const api = new APIClient();

export const getCookieAuthorizationValues = async () => {
    const result = await api.get<Record<string, string>>("/api/storage/auth-cookie");
    return result;
}

export const getQueryAuthorizationValues = async () => {
    const result = await api.get<Record<string, string>>("/api/storage/auth-query");
    return result;
}
import { PropsWithChildren, useEffect, useId, useRef } from "react";
import { FormGroup, Input, InputProps, Label } from "reactstrap";
import { ValidatableInputProps } from "helpers/types";
import classNames from "classnames";

export type CheckboxProps = ValidatableInputProps<boolean> & PropsWithChildren & Pick<InputProps, 'id' | 'disabled' | 'className' | 'style' | "onClick" | "color"> & {
    switch?: boolean,
    indeterminate?: boolean,
    label?: string,
    inline?: boolean,
}

const Checkbox = (props: CheckboxProps) =>  {
    const { isValid, className, switch: isSwitch,  value, onChange, label, name, indeterminate, children, color, id, ...otherProps } = props;
    const inputRef = useRef<HTMLInputElement>(null);
    
    const inputId = `${id ?? ""}${useId()}`;

    useEffect(() => {
        if (inputRef.current && indeterminate) {
            inputRef.current.indeterminate = indeterminate;
        }
    }, [inputRef, indeterminate]);

    return (<>
        <FormGroup switch={isSwitch} check={!isSwitch} inline={props.inline} className={classNames("d-flex gap-1", color ? `form-check-${color}` : "", {
            "form-switch-md": isSwitch,
            "is-invalid": value && isValid === false,
            "is-valid": value && isValid
        })}>
            <Input
                innerRef={inputRef}
                valid={value && isValid} 
                invalid={value && isValid === false} 
                className={classNames(className)}
                type={isSwitch ? "switch" : "checkbox"}
                onChange={e => onChange?.(e.target.checked)}
                checked={value || false}
                name={name}
                id={inputId}
                {...otherProps} />
                {(label || children) ? <Label htmlFor={inputId} check>{children || label}</Label> : null}
        </FormGroup>
    </>)
}

export default Checkbox;
import { useMutation } from "@tanstack/react-query";
import { DangerAlert } from "Components/Alerts";
import BusyOverlay from "Components/Common/BusyOverlay";
import RequiredLabel from "Components/Common/RequiredLabel";
import Checkbox from "Components/Form/Checkbox";
import ColorPicker from "Components/Form/ColorPicker";
import NumberInput from "Components/Form/NumberInput";
import TextInput from "Components/Form/TextInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { postArea, putArea } from "api/area";
import { AreaContract, AreaForm } from "api/types/contracts/locations";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Col, Form, Label, Row } from "reactstrap";
import * as Yup from 'yup';

type FormProps = {
    warehouseId: string,
    area?: AreaContract,
    onSuccess: VoidFunction
}

const defaultForm: Partial<AreaForm> = {
    isClimateControl: false,
    isFBAPrep: false,
    isHazardousArea: false
};

const createAreaForm = (area: AreaContract | undefined): AreaForm | undefined => {
    return area ? {
        name: area.name,
        ...area.settings
    } as AreaForm : undefined
};

const AreaFormView = (props: FormProps) => {
    const { t } = useTranslation();
    const warehouseForm = useMemo(() => createAreaForm(props.area) || defaultForm, [props.area]);
    warehouseForm.warehouseId = props.warehouseId;

    const saveAreaMutation = useMutation({
        mutationFn: async (area: AreaForm) => {
            if (props.area?.areaId) {
                await putArea({ areaId: props.area.areaId, area });
            }
            else {
                await postArea({ area });
            }
        },
        mutationKey: ["save-area"],
        onSuccess: () => {
            if (props.area?.areaId) {
                toast.success(t("Area updated"));
            }
            else {
                toast.success(t("Area created"));
            }

            props.onSuccess();
        }
    });

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: warehouseForm,
        validationSchema: Yup.object<AreaForm, TypedShape<AreaForm>>({
            warehouseId: Yup.string().required(),
            name: Yup.string().required(t("Please enter a name")),
            isClimateControl: Yup.bool().required(),
            isHazardousArea:  Yup.bool().required(),
            isFBAPrep:  Yup.bool().required(),
            color: Yup.string().notRequired(),
            lowestTemperature: Yup.number().notRequired(),
            hightestTemperature: Yup.number().notRequired(),
            humidity: Yup.number().notRequired(),
            notes: Yup.string().notRequired(),
            size: Yup.number().notRequired()
        }),
        onSubmit: values => {
            const area = values as AreaForm;

            saveAreaMutation.mutate(area);
        },
    });


    return <>
        <Form className="needs-validation form-steps" action="#" onSubmit={validation.handleSubmit}>
            <div>
                <div className="mb-4">
                    <div>
                        <h5 className="mb-1">{t("Area Information")}</h5>
                        <p className="text-muted">
                            
                        </p>
                    </div>
                </div>
                <Row>
                    <Col lg={6} className="g-3">
                        <RequiredLabel htmlFor="name">{t("Name")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="name">
                            <TextInput placeholder="Enter Name" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={6} className="g-3">
                        <Label htmlFor="name">{t("Select a Color")}</Label>
                        <ValidationWrapper validation={validation} field="color">
                            <ColorPicker placeholder="" />
                        </ValidationWrapper>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} className="g-3">
                        <ValidationWrapper validation={validation} field="isClimateControl">
                            <Checkbox label={t("Climate Control")} />
                        </ValidationWrapper>
                        {validation.values.isClimateControl && <Row>
                            <Col xs={8}>
                                <Label htmlFor="lowestTemperature">{t("Temperature Range ({{unit}})", { unit: "F" })}</Label>
                            </Col>
                            <Col xs={4}>
                                <Label htmlFor="humidity">{t("Humdity (%)")}</Label>
                            </Col>
                            <Col xs={4}>
                                <ValidationWrapper validation={validation} field="lowestTemperature">
                                    <NumberInput placeholder="Lowest" />
                                </ValidationWrapper>
                            </Col>
                            <Col xs={4}>
                                <ValidationWrapper validation={validation} field="hightestTemperature">
                                    <NumberInput placeholder="Highest" />
                                </ValidationWrapper>
                            </Col>
                            <Col xs={4}>
                                <ValidationWrapper validation={validation} field="humidity">
                                    <NumberInput placeholder="Humidity" />
                                </ValidationWrapper>
                            </Col>
                        </Row>}
                    </Col>
                    <Col lg={6} className="g-3 d-flex align-items-center">
                        <ValidationWrapper validation={validation} field="isFBAPrep">
                            <Checkbox label={t("FBA Prep")} />
                        </ValidationWrapper>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} className="g-3">
                        <Label htmlFor="name">{t("Size ({{unit}})", { unit: "ft²" })}</Label>
                        <ValidationWrapper validation={validation} field="size">
                            <NumberInput placeholder="Size" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={6} className="g-3 d-flex align-items-center">
                        <ValidationWrapper validation={validation} field="isHazardousArea">
                            <Checkbox label={t("Hazardous Materials")} />
                        </ValidationWrapper>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="g-3">
                        <Label htmlFor="name">{t("Notes")}</Label>
                        <ValidationWrapper validation={validation} field="notes">
                            <TextInput type="textarea" placeholder="Additional Information" />
                        </ValidationWrapper>
                    </Col>
                </Row>
            </div>
            {saveAreaMutation.isError && <DangerAlert>{saveAreaMutation.error.message}</DangerAlert>}
            <div className="d-flex justify-content-end gap-3 mt-4">
                <BusyOverlay busy={saveAreaMutation.isPending} size="sm">
                    <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label right nexttab">
                        <i className="ri-save-3-line label-icon align-middle fs-16 ms-2"></i>
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </>;
}

export default AreaFormView;
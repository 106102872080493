import { Row, Col, Label, Form, Nav, NavItem, NavLink, TabContent, TabPane, InputGroup, InputGroupText, FormGroup, } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { CompanyContract, CompanyForm } from "api/types/contracts/companies";
import ValidatedInput from "Components/Form/Validated/ValidatedInput";
import ValidatedFileUpload from "Components/Form/Validated/ValidatedFileUpload";
import ValidatedColorPicker from "Components/Form/Validated/ValidatedColorPicker";
import ValidatedCheckbox from "Components/Form/Validated/ValidatedCheckbox";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import Checkbox from "Components/Form/Checkbox";
import BusyOverlay from "Components/Common/BusyOverlay";
import { TypedShape } from "helpers/types";
import { DangerAlert } from "Components/Alerts";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import CountrySelect from "Components/EntitySelects/CountrySelect";
import StateSelect from "Components/EntitySelects/StateSelect";
import { CountryContract } from "api/types/contracts/common";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { useTenant } from "Components/Hooks/TenantHooks";
import TextInput from "Components/Form/TextInput";
import { useMutation } from "@tanstack/react-query";
import { postCompany, putCompany } from "api/company";
import { toast } from "react-toastify";

type WizardFormProps = {
    company?: CompanyContract,
    onSuccess?: VoidFunction
}

const defaultForm: Partial<CompanyForm> = {
    maxCustomerAmount: 10,
    maxWarehouseCount: 10,
    companyStatus: "active",
    companyType: "regular",
    maxDailyTransactions: 10000,
    enableCustomerRegistration: false
};

const createCompanyForm = (company: CompanyContract | undefined): CompanyForm | undefined => {
    return company ? {
        legalName: company.legalName,
        companyType: company.companyType,
        companyStatus: company.companyStatus,
        domain: company.tenant.domain,
        isCustomDomain: company.tenant.isCustomDomain,
        ...company.settings,
        ...company.tenant.preferences,
        ...company.contactAddress,
        name: company.name
    } as CompanyForm : undefined
};

const WizardForm = (props: WizardFormProps) => {
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const companyForm = useMemo(() => createCompanyForm(props.company) || defaultForm, [props.company]);
    const [activeTab, setActiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState<number[]>([]);
    const { refresh } = useTenant();
    const [selectedCountry, setSelectedCountry] = useState<CountryContract>();

    setPageTitle(`${t(props.company ? "Update Company" : "Create Company")} - ${t("Management")}`);

    const saveCompanyMutation = useMutation({
        mutationFn: async (company: CompanyForm) => {
            if (props.company?.companyId) {
                await putCompany({ companyId: props.company.companyId, company });
            }
            else {
                return postCompany({ company });
            }
        },
        mutationKey: ["save-company", props.company?.companyId],
        onSuccess: async () => {
            if (props.company?.companyId) {
                toast.success(t("Company updated"));
            }
            else {
                toast.success(t("Company created"));
            }

            props.onSuccess?.();
            await refresh();
        }
    });

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: companyForm,
        validationSchema: Yup.object<CompanyForm, TypedShape<CompanyForm>>({
            countryCode: Yup.string().required(t("Please select a Country")),
            state: Yup.string().required(t("Please enter a State")),
            stateCode: Yup.string().notRequired(),
            zipCode: Yup.string().required(t("Please enter a Zip Code")),
            address1: Yup.string().required(t("Please enter an Address")),
            address2: Yup.string().notRequired(),
            city: Yup.string().required(t("Please enter a City")),
            email: Yup.string().email(t("Please enter a Valid Email")).required(t("Please enter an Email")),
            phone: Yup.string().required(t("Please enter a Contact Number")).matches(/\+\d{1,5}[ -]?\d{3}[ -]?\d{3}[ -]?\d{4}/, t("Plase enter 10 digits phone number")),
            name: Yup.string().required(t("Please enter Company Name")),
            legalName: Yup.string().required(t("Please enter Company's Legal Name")),
            domain: Yup.string().when("isCustomDomain", ([isCustomDomain], schema) => {
                if (isCustomDomain) {
                    return schema.required(t("Please enter a domain"));
                }
                else {
                    return schema.required(t("Please enter a valid subdomain"));
                }
            }).required(),
            isCustomDomain: Yup.boolean().required(),
            province: Yup.string().notRequired(),
            logo: Yup.string().notRequired(),
            favicon: Yup.string().notRequired(),
            backgroundImage: Yup.string().notRequired(),
            primaryColor: Yup.string().notRequired(),
            secondaryColor: Yup.string().notRequired(),
            coverPageText: Yup.string().notRequired(),
            enableCustomerRegistration: Yup.boolean().required(),
            companyType: Yup.string().oneOf(["regular", "demo"]).required(),
            companyStatus: Yup.string().oneOf(["passive", "active"]).required(),
            maxCustomerAmount: Yup.number().moreThan(1, t("Customer amount must be larger than 1")).required(t("Please enter maximum customer amount for company")),
            maxWarehouseCount: Yup.number().moreThan(1, t("Warehouse count must be larger than 1")).required(t("Please enter maximum warehouse count for company")),
            maxDailyTransactions: Yup.number().moreThan(1, t("Daily transaction count must be larger than 1")).required(t("Please enter maximum transaction count company can make daily"))
        }),
        onSubmit: values => {
            const company = values as CompanyForm;

            saveCompanyMutation.mutate(company);
        },
    });

    function toggleTab(tab: number) {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab];

            setActiveTab(tab);
            setPassedSteps(modifiedSteps);
        }
    }

    return <>
        <Form className="needs-validation form-steps" action="#" onSubmit={validation.handleSubmit}>
            <div className="step-arrow-nav mb-4">
                <Nav className="nav-pills custom-nav nav-justified" role="tablist">
                    <NavItem>
                        <NavLink href="#" className={classnames({
                            active: activeTab === 1,
                            done: passedSteps.includes(1),
                        })} onClick={() => toggleTab(1)}>{t("Contact Info")}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="#" className={classnames({
                            active: activeTab === 2,
                            done: passedSteps.includes(2),
                        })} onClick={() => toggleTab(2)}>{t("Customization")}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="#" className={classnames({
                            active: activeTab === 3,
                            done: passedSteps.includes(3),
                        })} onClick={() => toggleTab(3)}>{t("Settings")}</NavLink>
                    </NavItem>
                </Nav>
            </div>

            <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                    <div>
                        <div className="mb-4">
                            <div>
                                <h5 className="mb-1">{t("Contact Information")}</h5>
                                <p className="text-muted">
                                    {t("In this part company primary contact information exists")}
                                </p>
                            </div>
                        </div>
                        <Row>
                            <Col lg={6} className="g-3">
                                <Label htmlFor="name">{t("Company Name")}</Label>
                                <ValidatedInput validation={validation} field="name" placeholder="Enter Name" />
                            </Col>
                            <Col lg={6} className="g-3">
                                <Label htmlFor="name">{t("Legal Name")}</Label>
                                <ValidatedInput validation={validation} field="legalName" placeholder="Enter Legal Name" />
                            </Col>
                        </Row>
                        <div className="hstack gap-3 align-items-center mt-3">
                                <h5 className="mb-0">{t("Address")}</h5>
                                <hr className="w-100" />
                            </div>
                        <Row>
                            <Col lg={6} className="g-3">
                                <Label htmlFor="countryCode">{t("Country")}</Label>
                                <ValidationWrapper validation={validation} field="countryCode">
                                    <CountrySelect onSelect={setSelectedCountry} />
                                </ValidationWrapper>
                            </Col>
                            <Col lg={6} className="g-3">
                                <Label htmlFor="state">{t("State")}</Label>
                                <ValidationWrapper validation={validation} field="state">
                                    <StateSelect showClear countryCode={validation.values.countryCode} onSelect={val => {
                                        validation.setFieldValue("stateCode", val?.code);
                                    }} />
                                </ValidationWrapper>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} className="g-3">
                                <Label htmlFor="city">{t("City")}</Label>
                                <ValidatedInput validation={validation} field="city" placeholder="Enter City" />
                            </Col>
                            <Col lg={6} className="g-3">
                                <Label htmlFor="zipCode">{t("Zip Code")}</Label>
                                <ValidatedInput validation={validation} field="zipCode" placeholder="Enter Zip code" />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} className="g-3">
                                <Label htmlFor="address1">{t("Address 1")}</Label>
                                <ValidatedInput validation={validation} field="address1" placeholder="Enter Address" />
                            </Col>
                            <Col lg={6} className="g-3">
                                <Label htmlFor="address2">{t("Address 2")}</Label>
                                <ValidatedInput validation={validation} field="address2" placeholder="Enter Address" />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} className="g-3">
                                <Label htmlFor="phone">{t("Phone Number")}</Label>
                                <ValidationWrapper validation={validation} field="phone">
                                    {/* <TextInput country={selectedCountry?.code} /> */}
                                    <TextInput />
                                </ValidationWrapper>
                            </Col>
                            <Col lg={6} className="g-3">
                            <Label htmlFor="email">{t("Email")}</Label>
                                <ValidatedInput validation={validation} field="email" placeholder="Enter Email" />
                            </Col>
                        </Row>
                    </div>
                    <div className="d-flex justify-content-end gap-3 mt-4">
                        <ValidatorButton type="button" color="success" onClick={() => toggleTab(2)} 
                            validation={validation} fields={["name", "legalName", "countryCode", "state", "city", "zipCode", "address1", "address2", "phone", "email"]}
                            className="btn-label right ms-auto nexttab">
                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                            {t("Customization")}
                        </ValidatorButton>
                    </div>
                </TabPane>
                <TabPane tabId={2}>
                    <div>
                        <div className="mb-4">
                            <div>
                                <h5 className="mb-1">{t("White-Labeling")}</h5>
                                <p className="text-muted">
                                    {t("In this part company whitelabeling settings exists")}
                                </p>
                            </div>
                        </div>
                        <div className="mb-3">
                            <Row>
                                <Col lg={6} className="g-3">
                                    <Label>Logo</Label>
                                    <ValidatedFileUpload validation={validation} field="logo" />
                                </Col>
                                <Col lg={3} className="g-3">
                                    <Label>Favicon</Label>
                                    <ValidatedFileUpload validation={validation} field="favicon" />
                                </Col>
                                <Col lg={3} className="g-3">
                                    <Row>
                                        <Col>
                                            <Label>{t("Color {{number}}", { number: 1 })}</Label>
                                            <ValidatedColorPicker validation={validation} field="primaryColor" placeholder="Color 1" />
                                        </Col>
                                        <Col>
                                        <Label>{t("Color {{number}}", { number: 2 })}</Label>
                                            <ValidatedColorPicker validation={validation} field="secondaryColor" placeholder="Color 2" />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} className="g-3">
                                    <Label>{t("Background Image")}</Label>
                                    <ValidatedFileUpload validation={validation} field="backgroundImage" />
                                </Col>
                                <Col lg={6} className="g-3">
                                    <Label>{t("Cover page tex")}t</Label>
                                    <ValidatedInput validation={validation} type="textarea" field="coverPageText" placeholder="Cover page text" />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} className="g-3">
                                    <Label>{t("Domain Preference")}</Label>
                                    <div>
                                        <ValidatedCheckbox validation={validation} switch field="isCustomDomain" label={t("Use custom domain")} />
                                    </div>
                                </Col>
                                <Col lg={6} className="g-3">
                                    <Label>{validation.values.isCustomDomain ? t("Domain") : t("Subdomain")}</Label>
                                    <InputGroup>
                                        <ValidatedInput validation={validation} field="domain" />
                                        {!validation.values.isCustomDomain ? <InputGroupText>.tyloc.com</InputGroupText> : null }
                                    </InputGroup>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between gap-3 mt-4">
                        <button type="button" className="btn btn-light btn-label previestab" onClick={() => toggleTab(1)}>
                            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                            {t("Back")}
                        </button>
                        <ValidatorButton type="button" onClick={() => toggleTab(3)} 
                            validation={validation} fields={["domain"]}
                            className="btn btn-success btn-label right ms-auto nexttab nexttab">
                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                            {t("Settings")}
                        </ValidatorButton>
                    </div>
                </TabPane>

                <TabPane tabId={3}>
                    <div>
                        <div className="mb-4">
                            <div>
                                <h5 className="mb-1">{t("Settings")}</h5>
                                <p className="text-muted">
                                    {t("In this part company settings and limitation exists")}
                                </p>
                            </div>
                        </div>
                        <div className="mb-3">
                            <Row>
                                <Col lg={6} className="g-3">
                                    <FormGroup row>
                                        <Col lg={9}>
                                            <Label htmlFor="enableCustomerRegistration">{t("Accept customer registration from page")}</Label>
                                        </Col>
                                        <Col lg={3}>
                                            <ValidatedCheckbox validation={validation} switch field="enableCustomerRegistration" />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col lg={6} className="g-3">
                                    <FormGroup row>
                                        <Col lg={9}>
                                            <Label htmlFor="isDemoCustomer">{t("Is demo customer")}</Label>
                                        </Col>
                                        <Col lg={3}>
                                            <Checkbox switch value={validation.values.companyType === "demo"} 
                                                onChange={val => {
                                                    validation.setFieldValue("companyType", val ? "demo" : "regular");
                                            }} />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <FormGroup row>
                                        <Col lg={9}>
                                            <Label htmlFor="maxCustomerAmount">{t("Max customer amount")}</Label>
                                        </Col>
                                        <Col lg={3}>
                                            <ValidatedInput validation={validation} field="maxCustomerAmount" />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <FormGroup row>
                                        <Col lg={9}>
                                            <Label htmlFor="maxWarehouseCount">{t("Max warehouse count")}</Label>
                                        </Col>
                                        <Col lg={3}>
                                            <ValidatedInput validation={validation} field="maxWarehouseCount" />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <FormGroup row>
                                        <Col lg={9}>
                                            <Label htmlFor="maxDailyTransactions">{t("Max transactions per day")}</Label>
                                        </Col>
                                        <Col lg={3}>
                                            <ValidatedInput validation={validation} field="maxDailyTransactions" />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="mt-4">{saveCompanyMutation.isError && <DangerAlert>{saveCompanyMutation.error.message}</DangerAlert>}</div>
                    <div className="d-flex justify-content-end gap-3">
                        <button type="button" className="btn btn-light btn-label previestab" onClick={() => toggleTab(2)}>
                            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                            {t("Back")}
                        </button>
                        <BusyOverlay busy={saveCompanyMutation.isPending} size="sm">
                            <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label right nexttab">
                                <i className="ri-save-3-line label-icon align-middle fs-16 ms-2"></i>
                                {t("Save")}
                            </ValidatorButton>
                        </BusyOverlay>
                    </div>
                </TabPane>
                {/* <TabPane tabId={4}>
                    <div className="text-center">
                        <div className="avatar-md mt-5 mb-4 mx-auto">
                            <div className="avatar-title bg-light text-success display-4 rounded-circle">
                                <i className="ri-checkbox-circle-fill"></i>
                            </div>
                        </div>
                        <h5>{t("Well done")}!</h5>
                        <p className="text-muted">{t("You have successfully created the company")}</p>
                        <Button type="button" color="warning" onClick={() => props.onSuccess?.()}>{t("Close wizard")}</Button>
                    </div>
                </TabPane> */}
            </TabContent>
        </Form>
    </>;
};

export default WizardForm;

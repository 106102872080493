import { AddressContract } from "api/types/contracts/common";
import classNames from "classnames";

type AddressDisplayProps = {
    address: AddressContract,
    suitNumber?: string,
    className?: string,
}

const InlineAddressDisplay = ({ address, suitNumber, className }: AddressDisplayProps) => {
    return <p className={classNames("mb-0", className)}>
        {address.address1} 
        {address.address2 ? `, ${address.address2}` : ""}
        {suitNumber ? `, ${suitNumber}` : ""}
        {address.city ? ` ${address.city}` : ""}
        {address.state ? `, ${address.state}` : ""}
        {address.zipCode ? ` ${address.zipCode}` : ""}
        {address.country.code ? ` ${address.country.code}` : ""}
    </p>
};

export default InlineAddressDisplay;
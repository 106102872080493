import { APIClient, PagedList } from "helpers/api_helper";
import type { GetCreditRequestQuery, ListCreditCardsQuery, ListCreditRequestsQuery, ListTransactionHistoryQuery } from "./types/queries";
import type { DeleteCreditCardCommand, LoadCreditWithStripeCommand, RequestCreditCommand, ReviewCreditRequestCommand, SetDefaultCreditCardCommand } from "./types/commands";
import { TransactionHistoryContract, type CreditCardContract, type CreditRequestContract } from "./types/contracts/payment";

const api = new APIClient();

export const getCardList = async (data: ListCreditCardsQuery) => {
    const result = await api.get<CreditCardContract[]>(`/api/payment/card`, data);
    return result;
}

export const postRequestCredit = async (data: RequestCreditCommand) => {
    await api.post(`/api/payment/credit-request`, { ...data });
}

export const postLoadCreditWithStripe = async (data: LoadCreditWithStripeCommand) => {
    await api.post(`/api/payment/load-credit/stripe`, { ...data });
}

export const getTransactionHistoryList = async (data: ListTransactionHistoryQuery) => {
    return await api.get<PagedList<TransactionHistoryContract>>(`/api/payment/history`, { ...data });
}

export const getCreditRequestList = async (data: ListCreditRequestsQuery) => {
    return await api.get<PagedList<CreditRequestContract>>(`/api/payment/credit-request`, { ...data });
}

export const getCreditRequest = async (data: GetCreditRequestQuery) => {
    return await api.get<CreditRequestContract>(`/api/payment/credit-request/${data.creditRequestId}`);
}

export const patchReviewCreditRequest = async (data: ReviewCreditRequestCommand) => {
    const { creditRequestId, ...rest } = data;
    await api.patch(`/api/payment/credit-request/${creditRequestId}/review`, { ...rest });
}

export const patchSetDefaultCreditCard = async (data: SetDefaultCreditCardCommand) => {
    await api.patch(`/api/payment/card/${data.creditCardId}/default`, {});
}

export const deleteCreditCard = async (data: DeleteCreditCardCommand) => {
    await api.delete(`/api/payment/card/${data.creditCardId}`);
}
import BusyOverlay from "Components/Common/BusyOverlay";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import Loader from "Components/Common/Loader";
import FbaShipmentItemStatusBadge from "Components/Displays/FbaShipmentItemStatusBadge";
import ProductIdentifiersDisplay, { ProductIdentifiersHeader } from "Components/Displays/ProductIdentifiersDisplay";
import ProductImageDisplay from "Components/Displays/ProductImageDisplay";
import Currency from "Components/Displays/UnitDisplay/Currency";
import Dimensions from "Components/Displays/UnitDisplay/Dimensions";
import Weight from "Components/Displays/UnitDisplay/Weight";
import PrepServiceSelect from "Components/EntitySelects/PrepServiceSelect";
import TextInput from "Components/Form/TextInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { ItemAvatar, PackageItemColors } from "Components/Shared/ShipmentPackageView";
import { FbaShipmentContract, FbaShipmentItemContract, FbaShipmentItemStatus, FbaShipmentUpdateFormItem } from "api/types/contracts/shipping";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Form, Modal, ModalBody, Table } from "reactstrap";
import { loadFbaShipment, removeFbaShipmentItem, updateFbaShipmentItems } from "slices/shipping/fbaShipping/thunk";
import * as Yup from "yup";
import AddProductForm from "./_AddProductForm";
import NumberInput from "Components/Form/NumberInput";
import { commaSeperated } from "helpers/string";
import TruncatableText from "Components/Common/TruncatableText";
import { useCommon } from "Components/Hooks/CommonHooks";
import { convertDimensions, convertWeight } from "helpers/unitConversions";
import { createAppSelector, useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import type { ProductContract } from "api/types/contracts/products";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import ProductEditForm from "Components/Shared/ProductEditForm";

const createFbaShipmentItemsForm = (fbaShipment: FbaShipmentContract | undefined): FbaShipmentUpdateFormItem[] => {
    return fbaShipment?.items.map(i => ({
        productId: i.product.productId,
        count: i.count,
        fnsku: i.fnsku,
        expireDate: undefined,
        status: undefined,
        prepServices: i.prepServices.map(p => p.prepService.prepServiceId)
    })) ?? [];
}

type ProductListProps = {
    
}

const ProductList = (props: ProductListProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const saveChangesConfirmDialogRef = useRef<DialogRef>(null);
    const deleteItemConfirmDialogRef = useRef<DialogRef>(null);
    const { calculateExchange } = useCommon();
    const [selectedItem, setSelectedItem] = useState<FbaShipmentItemContract>();
    const [selectedProduct, setSelectedProduct] = useState<ProductContract>();

    const { fbaShipment, loading } = useAppSelector(
        createAppSelector([state => state.FbaShipping],
            (fbaShipping) => ({
                fbaShipment: fbaShipping.processingItem,
                loading: fbaShipping.loading
            })
        )
    );

    const deleteItem = (item: FbaShipmentItemContract) => {
        setSelectedItem(item);
        deleteItemConfirmDialogRef.current?.show();
    }
    
    const fbaShipmentUpdateFormItems = useMemo(() => createFbaShipmentItemsForm(fbaShipment), [fbaShipment]);

    const productColorCodes = useMemo(() => _.chain(fbaShipment?.items ?? []).map((item, i) => [item.product.productId, PackageItemColors[i % PackageItemColors.length]]).uniqWith(_.isEqual).fromPairs().value(), [fbaShipment?.items]);
    const canEdit = !!fbaShipment && (["productPending", "fnskuPending"].includes(fbaShipment.status));

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: fbaShipmentUpdateFormItems,
        validationSchema: Yup.array<FbaShipmentUpdateFormItem>(Yup.object<FbaShipmentUpdateFormItem, TypedShape<FbaShipmentUpdateFormItem>>({
            productId: Yup.string().required(),
            count: Yup.number().required(t("Count is required")),
            fnsku: Yup.string().notRequired(),
            status: Yup.string<FbaShipmentItemStatus>().notRequired(),
            expireDate: Yup.date().notRequired(),
            prepServices: Yup.array(Yup.string().required()).required()
        })),
        onSubmit: async values => {
            await dispatch(updateFbaShipmentItems({
                fbaShipmentId: fbaShipment!.fbaShipmentId,
                items: values
            }));

            saveChangesConfirmDialogRef.current?.hide();

            await dispatch(loadFbaShipment({
                fbaShipmentId: fbaShipment!.fbaShipmentId
            }));
        }
    });

    const saveFnskuLabelOf = async (index: number) => {
        const item = validation.values[index];

        await dispatch(updateFbaShipmentItems({
            fbaShipmentId: fbaShipment!.fbaShipmentId,
            items: [item]
        }));

        saveChangesConfirmDialogRef.current?.hide();

        validation.setFieldTouched(`${index}.fnsku`, false, false);
        
        await dispatch(loadFbaShipment({
            fbaShipmentId: fbaShipment!.fbaShipmentId
        }));
    }

    // useEffect(() => {
    //     validation.resetForm({
    //         values: fbaShipmentUpdateFormItems
    //     });
    // }, [fbaShipmentUpdateFormItems]);

    console.log("Validation items 1", validation.values[1]?.prepServices?.length, validation.values[1]?.productId);

    const totalPrice = useMemo(() => fbaShipment && _.sumBy(validation.values.map((item, index) => ({ 
        price: fbaShipment.items[index] ? calculateExchange(fbaShipment.items[index].buyPrice, fbaShipment.items[index].product.options.unitOfCurrency, fbaShipment.warehouse.settings.unitOfCurrency) : 0, 
        count: item.count 
    })), i => i.price * (i.count ?? 0)), [fbaShipment, validation.values]);

    const totalWeight = useMemo(() => fbaShipment && _.sumBy(validation.values.map((item, index) => ({ 
        weight: fbaShipment.items[index] ? convertWeight(fbaShipment.items[index].product.weight, fbaShipment.items[index].product.options.unitOfWeight, fbaShipment.warehouse.settings.unitOfWeight) : 0, 
        count: item.count 
    })), i => i.weight * (i.count ?? 0)), [fbaShipment, validation.values]);

    if (!fbaShipment) {
        return <Loader height="150px" />;
    }

    return <>
        <Form className="needs-validation" action="#" onSubmit={validation.handleSubmit} onReset={validation.handleReset}>
            <Card className="mt-2">
                <CardHeader>
                    <div className="d-flex justify-content-between align-items-center">
                        <CardTitle tag="h5" className="mb-0">
                            {t("Selected Products ({{skuCount}} SKU & {{count}} items)", { 
                                skuCount: fbaShipment.items.length, 
                                count: _.sumBy(fbaShipment.items, i => i.count)
                            })}
                        </CardTitle>
                        {canEdit && <AddProductForm />}
                    </div>
                </CardHeader>
                <CardBody>
                    <div className="table-responsive table-card">
                        <Table className="mb-0 align-middle" size="sm" borderless>
                            <thead className="table-light text-muted">
                                <tr>
                                    <th></th>
                                    <th>{t("Image")}</th>
                                    <th style={{ width: "30%" }}>{t("Name")}</th>
                                    <th><ProductIdentifiersHeader /></th>
                                    <th>{t("Count")}</th>
                                    <th>{t("Reserved")}</th>
                                    <th>{t("Dimensions")}</th>
                                    <th>{t("Weight")}</th>
                                    <th>{t("Buy Price")}</th>
                                    <th>{t("Total")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading.load && <tr>
                                    <td colSpan={10}>
                                        <Loader height="100px" />
                                    </td>
                                </tr>}
                                {!loading.load && fbaShipment.items.map((shipmentItem, index) => <React.Fragment key={index}>
                                    <tr>
                                        <td className="align-bottom pb-0">
                                            <Button color="ghost-danger" type="button" className="btn-icon" size="sm" disabled={!canEdit || validation.values.length <= 1} onClick={() => deleteItem(shipmentItem)}>
                                                <i className="ri-delete-bin-5-fill fs-16"></i>
                                            </Button>
                                        </td>
                                        <td>
                                            <ProductImageDisplay product={shipmentItem.product} />
                                        </td>
                                        <td>
                                            <Button color="link" className="text-start" onClick={e => {
                                                setSelectedProduct(shipmentItem.product);
                                            }}>
                                                <TruncatableText maxLines={2}>
                                                    {shipmentItem.product.name}
                                                </TruncatableText>
                                            </Button>
                                            {shipmentItem.isBundle && shipmentItem.bundleItems.length > 0 && <div className="d-flex align-items-center gap-1">
                                                <i className="ri-spam-fill text-warning"></i>
                                                <span className="fs-10">{t("Bundling from {{products}}", {
                                                    products: commaSeperated(shipmentItem.bundleItems.map(p => p.product.asin ?? "???"))
                                                })}</span> 
                                            </div>}
                                            {shipmentItem.isSplit && shipmentItem.splitItem && <div className="d-flex align-items-center gap-1">
                                                <i className="ri-spam-fill text-warning"></i>
                                                <span className="fs-10">{t("Splitting from {{product}}", {
                                                    product: shipmentItem.splitItem.product.asin ?? "???"
                                                })}</span> 
                                            </div>}
                                        </td>
                                        <td>
                                            <ProductIdentifiersDisplay product={shipmentItem.product} />
                                        </td>
                                        <td>
                                            <ValidationWrapper validation={validation} field={`${index}.count`}>
                                                <NumberInput size="sm" disabled={!canEdit} style={{ width: "5rem" }} min={1} />
                                            </ValidationWrapper>
                                        </td>
                                        <td>{shipmentItem.reserved}</td>
                                        <td>
                                            <div>
                                                <Dimensions 
                                                    value={convertDimensions(shipmentItem.product, shipmentItem.product.options.unitOfLength, fbaShipment.warehouse.settings.unitOfLength)} 
                                                    unit={fbaShipment.warehouse.settings.unitOfLength} />
                                            </div>
                                            {shipmentItem.product.options.unitOfLength !== fbaShipment.warehouse.settings.unitOfLength && <div className="text-muted small">
                                                <Dimensions value={shipmentItem.product} unit={shipmentItem.product.options.unitOfLength} />
                                            </div>}
                                        </td>
                                        <td>
                                            
                                            <div>
                                                <Weight 
                                                    value={convertWeight(shipmentItem.product.weight, shipmentItem.product.options.unitOfWeight, fbaShipment.warehouse.settings.unitOfWeight)} 
                                                    unit={fbaShipment.warehouse.settings.unitOfWeight} />
                                            </div>
                                            {shipmentItem.product.options.unitOfLength !== fbaShipment.warehouse.settings.unitOfLength && <div className="text-muted small">
                                                <Weight value={shipmentItem.product.weight} unit={shipmentItem.product.options.unitOfWeight} />
                                            </div>}
                                        </td>
                                        <td>
                                            <Currency 
                                                value={calculateExchange(shipmentItem.buyPrice, shipmentItem.product.options.unitOfCurrency, fbaShipment.warehouse.settings.unitOfCurrency)} 
                                                currency={fbaShipment.warehouse.settings.unitOfCurrency} />
                                        </td>
                                        <td>
                                            <Currency 
                                                value={calculateExchange(shipmentItem.buyPrice * (validation.values[index]?.count ?? 0), shipmentItem.product.options.unitOfCurrency, fbaShipment.warehouse.settings.unitOfCurrency)} 
                                                currency={fbaShipment.warehouse.settings.unitOfCurrency} />
                                        </td>
                                    </tr>
                                    <tr className="border-bottom">
                                        <td></td>
                                        <td>
                                            <FbaShipmentItemStatusBadge value={shipmentItem.status} />
                                        </td>
                                        <td className="d-flex gap-2">
                                            <ValidationWrapper validation={validation} field={`${index}.fnsku`}>
                                                <TextInput size="sm" className="w-50" disabled={!canEdit} placeholder={t("FNSKU")} />
                                            </ValidationWrapper>
                                            {(shipmentItem.fnsku !== validation.values[index]?.fnsku) && <BusyOverlay busy={loading.save} size="sm">
                                                <Button color="secondary" size="sm" type="button" disabled={!canEdit} onClick={() => saveFnskuLabelOf(index)}>
                                                    {t("Save")}
                                                </Button>
                                            </BusyOverlay>}
                                        </td>
                                        <td>
                                            <ItemAvatar size={1} color={productColorCodes[shipmentItem.product.productId]} />
                                        </td>
                                        <td colSpan={6}>
                                            <ValidationWrapper validation={validation} field={`${index}.prepServices`}>
                                                <PrepServiceSelect disabled={!canEdit} checkboxes productOptionsOnly 
                                                    isMulti channel="fba" warehouseId={fbaShipment.warehouse.warehouseId}
                                                    onChange={val => {
                                                        console.log("Prep Services", shipmentItem.product.asin, val);
                                                    }} />
                                            </ValidationWrapper>
                                        </td>
                                    </tr>
                                </React.Fragment>)}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={4} className="text-end py-2">
                                        {t("Total")}
                                    </td>
                                    <th>
                                        {_.sumBy(validation.values, p => p.count ?? 0)}
                                    </th>
                                    <th colSpan={2}></th>
                                    <th>
                                        <Weight value={totalWeight} unit={fbaShipment.warehouse.settings.unitOfWeight} />
                                    </th>
                                    <th></th>
                                    <th>
                                        <Currency value={totalPrice} currency={fbaShipment.warehouse.settings.unitOfCurrency} />
                                    </th>
                                </tr>
                            </tfoot>
                        </Table>
                    </div>
                </CardBody>
                {canEdit && <CardFooter>
                    <div className="d-flex justify-content-end">
                        <BusyOverlay busy={loading.save} size="sm">
                            <ValidatorButton type="button" color="success" onClick={() => saveChangesConfirmDialogRef.current?.show()} validation={validation}>
                                {t("Save Changes")}
                            </ValidatorButton>
                        </BusyOverlay>
                    </div>
                </CardFooter>}
            </Card>
        </Form>
        <Modal backdrop="static" isOpen={!!selectedProduct} toggle={() => setSelectedProduct(undefined)} size="xl">
            <ModalBody className="p-0">
                <ModalCloseButton onClick={() => setSelectedProduct(undefined)} />
                <ProductEditForm product={selectedProduct} />
            </ModalBody>
        </Modal>
        <Dialog ref={saveChangesConfirmDialogRef} color="success" buttons={["yes", "no"]} busy={loading.save} iconClass="ri-a-b"  
            message={t('paragraphs:FbaShipmentProductChangesConfirmation')} title={`Are you sure?`}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    validation.submitForm();
                }
                else {
                    hide();
                }
            }} />
        <Dialog ref={deleteItemConfirmDialogRef} color="danger" buttons={["yes", "no"]} busy={loading.delete} iconClass="ri-delete-bin-line"  
            message={t('The product will be deleted')} title={`Are you sure?`}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    dispatch(removeFbaShipmentItem({
                        fbaShipmentId: fbaShipment!.fbaShipmentId,
                        fbaShipmentItemId: selectedItem!.fbaShipmentItemId
                    })).then(() => {
                        hide();
                        setSelectedItem(undefined);
                        dispatch(loadFbaShipment({
                            fbaShipmentId: fbaShipment!.fbaShipmentId
                        }));
                    });
                }
                else {
                    hide();
                }
            }} />
    </>;
}

export default ProductList;
import SelectInput from "Components/Form/SelectInput"
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { ProductSupplierContract } from "api/types/contracts/products";
import { ListProductSuppliersQuery } from "api/types/queries";
import { EntitySelectInputProps, SelfPopulatedSelectInputRef } from "helpers/types";
import { ForwardedRef, RefAttributes, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { loadList } from "slices/product/supplier/thunk";
import { useImmer } from "use-immer";

type ProductSupplierSelectProps = EntitySelectInputProps<ProductSupplierContract, "supplierId"> & {
    userId?: string
}

const ProductSupplierSelect = ({ isMulti, value, onChange, onSelect, name, isValid, userId, ...rest }: ProductSupplierSelectProps, ref: ForwardedRef<SelfPopulatedSelectInputRef>) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [query, updateQuery] = useImmer<ListProductSuppliersQuery>({
        page: 1,
        pageSize: 99,
        search: "",
        userId
    });
    const { suppliers, loading } = useAppSelector(
        (state) => ({
            suppliers: state.ProductSupplier.list?.items || [],
            loading: state.ProductSupplier.loading.list
        })
    );

    const productSupplierList = useMemo(() => suppliers.map(s => ({ 
        label: s.name, 
        value: s.supplierId 
    })), [suppliers]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = suppliers.filter(c => val.includes(c.supplierId));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = suppliers.find(s => s.supplierId === val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.supplierId);
                return;
            }
        }

        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        dispatch(loadList (query));
    }, [dispatch, query]);

    useImperativeHandle(ref, () => {
        return {
            reload: () => dispatch(loadList(query))
        };
    }, [dispatch, query]);

    const onSearch = useCallback((val?: string) => {
        updateQuery(q => {
            q.search = val;
        });
    }, [updateQuery]);

    useEffect(() => {
        if (!value) {
            return;
        }

        if (suppliers.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suppliers]);
    
    const selectProps = {
        ...rest,
        placeholder: rest.placeholder ?? t("Product Supplier"),
        busy: loading,
        options: productSupplierList,
        showClear: true,
        onSearch: onSearch,
        isValid: isValid
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default forwardRef(ProductSupplierSelect) as (
    props: ProductSupplierSelectProps & RefAttributes<SelfPopulatedSelectInputRef>
) => ReturnType<typeof ProductSupplierSelect>;
import { UnitOfCurrency } from "api/types/contracts/common";
import { createAppSelector, useAppSelector } from "./StoreHooks";

const useCommon = () => {
    const { exchangeRates } = useAppSelector(
        createAppSelector([state => state.Common], 
            (common) => ({
                exchangeRates: common.usdExchangeRates
            })
        )
    );

    const calculateExchange = (amount: number | undefined, from: UnitOfCurrency, to: UnitOfCurrency) => {
        if (amount === undefined) {
            return 0;
        }

        if (to === from) {
            return amount;
        }

        console.log("Calculating exchange rate from", from, "to", to);
        

        return amount / exchangeRates[from] * exchangeRates[to];
    }

    return { calculateExchange };
};

export { useCommon };

import { useRef } from "react";
import { Container, Modal, ModalBody } from "reactstrap";

//import images 
import { useTranslation } from "react-i18next";

import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import List, { CustomerListRef } from "./_List";
import { useLoaderData, useNavigate } from "react-router-dom";
import Profile from "./_Profile";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import ResolveWrapper from "Components/Common/ResolveWrapper";
import Restricted from "Components/Common/Restricted";
import type { CustomerContract } from "api/types/contracts/customers";

type CustomersPageProps = {
    edit?: boolean
}

type LoaderData = {
    customer: Promise<CustomerContract> | undefined
}

const CustomersPage = (props: CustomersPageProps) => {
    const loaderData = useLoaderData() as LoaderData;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const listRef = useRef<CustomerListRef>(null);

    return <>
        <Restricted require="management.customer" throw>
            <div className="page-content">
                <Container fluid>
                    <TitleBreadcrumb active={t("Customers")} parents={[t("Management")]} />
                    <List ref={listRef} />
                    {props.edit && 
                        <Modal backdrop="static" size="xl" isOpen toggle={() => navigate("/customers")} unmountOnClose>
                            <ModalBody className="bg-light">
                                <ResolveWrapper promise={loaderData?.customer}>
                                    {(customer) => <>
                                        <ModalCloseButton onClick={() => navigate("/customers")} />
                                        <Profile customer={customer} onSuccess={() => {
                                            listRef.current?.reload();
                                        }} />
                                    </>}
                                </ResolveWrapper>
                            </ModalBody>
                        </Modal>
                    }
                </Container>
            </div>
        </Restricted>
    </>;
};

export default CustomersPage;

import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteCreditCard, getCardList, patchSetDefaultCreditCard } from "api/payment";
import Loader from "Components/Common/Loader";
import { useTranslation } from "react-i18next";
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, UncontrolledButtonDropdown } from "reactstrap";
import LinkCreditCard from "./_LinkCreditCard";
import type { WarehouseContract } from "api/types/contracts/warehouses";
import { useEffect } from "react";
import classNames from "classnames";
import BusyOverlay from "Components/Common/BusyOverlay";

type CreditCardSelectProps = {
    value?: string,
    warehouse: WarehouseContract,
    isValid?: boolean,
    onChange?: (value: string | undefined) => void
}

const CreditCardSelect = (props: CreditCardSelectProps) => {
    const { t } = useTranslation();

    const { data: cards, isFetching: cardLoading, refetch: reloadCards } = useQuery({
        queryKey: ["credit-cards"],
        queryFn: () => getCardList({})
    });

    const setCardDefaultMutation = useMutation({
        mutationFn: patchSetDefaultCreditCard,
        mutationKey: ["set-credit-card-default"],
        onSuccess: () => {
            reloadCards();
        }
    });

    const deleteCardMutation = useMutation({
        mutationFn: deleteCreditCard,
        mutationKey: ["delete-credit-card"],
        onSuccess: () => {
            reloadCards();
        }
    });

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange?.(e.target.value);
    }

    useEffect(() => {
        if (!props.value) {
            const defaultCard = cards?.find(c => c.isDefault);

            if (defaultCard) {
                props.onChange?.(defaultCard.creditCardId);
            }
        }
    }, [cards, props.onChange])

    return <>
        <div className={classNames("vstack credit-card-select", {
            "is-invalid": props.isValid === false
        })}>
            {cardLoading ? <Loader height="150px" /> : <>
                <div className="vstack px-3 gap-3">
                    {cards?.length === 0 && <div className="text-center text-muted">{t("No credit cards found")}</div>}
                    {cards!.map((card, i) => {
                        const busy = (deleteCardMutation.isPending && deleteCardMutation.variables.creditCardId === card.creditCardId) 
                            || (setCardDefaultMutation.isPending && setCardDefaultMutation.variables.creditCardId === card.creditCardId);

                        return <div className="d-flex gap-3 align-items-center" key={i}>
                            <div className="form-check card-radio flex-grow-1">
                                <Input id={`creditCards_${card.creditCardId}`} name="creditCards" onChange={onChange}
                                    type="radio" className="form-check-input" value={card.creditCardId} checked={props.value === card.creditCardId} />
                                <BusyOverlay busy={busy} spinnerColor="tenant-primary" backgroundColor="white" blur>
                                    <Label className="form-check-label d-flex gap-2 align-items-center" htmlFor={`creditCards_${card.creditCardId}`}>
                                        <span className="d-block">
                                            <img src={`/creditCardType/${card.type}.svg`} style={{ height: "40px" }} alt="visa" />
                                        </span>
                                        <span className="d-block">
                                            <span className="fs-14 mb-1 text-wrap d-block">
                                                <strong>{t(`enums:CreditCardType.${card.type}`)} - {card.maskedCardNumber}</strong>
                                                {card.isDefault && <Badge color="dark-subtle" className="text-dark ms-2">{t("Default")}</Badge>}
                                            </span>
                                            <span className="text-muted fw-normal text-wrap d-block">
                                                {t("Expires")} {card.expirationMonth}/{card.expirationYear}
                                            </span>
                                        </span>
                                    </Label>
                                </BusyOverlay>
                            </div>
                            <div>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle tag="button" className="btn btn-sm btn-ghost-dark btn-icon" disabled={busy}>
                                        <i className="ri-more-fill fs-2"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => setCardDefaultMutation.mutate({ creditCardId: card.creditCardId })}>
                                            <i className="ri-check-fill align-middle me-2"></i> {t("Set as Default")}
                                        </DropdownItem>
                                        <DropdownItem onClick={() => deleteCardMutation.mutate({ creditCardId: card.creditCardId })}>
                                            <i className="ri-delete-bin-2-line align-middle me-2"></i> {t("Remove")}
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                        </div>;
                    })}
                </div>
                <div className="mt-2">
                    <LinkCreditCard warehouse={props.warehouse} onSuccess={() => {
                        reloadCards();
                    }} />
                </div>
            </>}
        </div>
    </>;
}

export default CreditCardSelect;
//import images 
import { useTranslation } from "react-i18next";

import Grid from "./_Grid";
import { WarningAlert } from "Components/Alerts";
import BusyOverlay from "Components/Common/BusyOverlay";
import { createAppSelector, useAppSelector } from "Components/Hooks/StoreHooks";
import { useMetadata } from "Components/Hooks/MetadataHooks";

type AreasViewProps = {
    
}

const AreasView = (props: AreasViewProps) => {
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();

    const { areas, warehouse, loading } = useAppSelector(
        createAppSelector([state => state.Location],
            (location) => ({
                loading: location.loading,
                warehouse: location.warehouse,
                areas: location.areas
            })
        )
    );

    setPageTitle(`${(t("Warehouse {{warehouse}}", { warehouse: warehouse?.name }))} - ${t("Locations")} - ${t("Management")}`);

    return <>
        <BusyOverlay busy={loading.data} blur>
            {areas && areas.length > 0 ? 
                <Grid areas={areas} />
            :
                <WarningAlert>{t("There are no defined areas yet")}</WarningAlert>
            }
        </BusyOverlay>
    </>;
};

export default AreasView;

import { useProfile } from "./Hooks/ProfileHooks";
import { useTenant } from "./Hooks/TenantHooks";

const TenantCode = () => {
    const { tenant } = useTenant();
    const { userProfile } = useProfile();

    if (!["companyAdmin", "employee", "systemAdmin"].includes(userProfile?.user.userType ?? "")) {
        return undefined;
    }

    return <>
        <div className="ms-1 header-item">
            <span className="fs-16 badge badge-gradient-success">{tenant?.code}</span>
        </div>
    </>;
}

export default TenantCode;
import { Container } from "reactstrap";
import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { useTranslation } from "react-i18next";

const DashboardPage = () => {
    const { setPageTitle } = useMetadata();
    const { t } = useTranslation();

    setPageTitle(t("Dashboard")); 

    return <>
        <div className="page-content">
            <Container fluid>
                <TitleBreadcrumb active={t("Dashboard")} parents={[t("Dashboard")]} />
            </Container>
        </div>
    </>;
};

export default DashboardPage;

import SelectInput from "Components/Form/SelectInput"
import { useDebounce } from "Components/Hooks/HelperHooks";
import { createAppSelector, useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import type { ClientPrinterContract } from "api/types/contracts/printing";
import { EntitySelectInputProps } from "helpers/types";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { loadPrinterList } from "slices/printers/thunk";

type ClientPrinterSelectProps = EntitySelectInputProps<ClientPrinterContract, "clientPrinterId"> & {
    
}

const ClientPrinterSelect = ({ isMulti, value, onChange, onSelect, name, isValid, ...rest }: ClientPrinterSelectProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { printers, loading } = useAppSelector(
        createAppSelector([state => state.Printers],
            (printers) => ({
                printers: printers.printers,
                loading: printers.loading.list
            })
        )
    );

    const printerList = useMemo(() => [undefined, ...printers.filter(p => !p.isDisabled && p.isConnected)].map(p => (p ? { 
        value: p.clientPrinterId, 
        label: <>
            <strong>{p.name}</strong>
            <small className="ms-2 text-muted">{p.desktopClientInstance?.deviceName}</small>
        </>
    } : {
        value: "",
        label: <>
            <span>{t("Preview in browser")}</span>
        </>
    })), [printers]);

    const debouncedLoadList = useDebounce(() => {
        dispatch(loadPrinterList({}));
    }, 200);

    useEffect(() => {
        debouncedLoadList();
    }, [debouncedLoadList]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = printers.filter(c => val.includes(c.clientPrinterId));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = printers.find(c => c.clientPrinterId === val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.clientPrinterId);
                return;
            }
        }

        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        if (!value) {
            return;
        }

        if (printers.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [printers]);

    const selectProps = {
        ...rest,
        placeholder: rest.placeholder ?? t("Printer"),
        busy: loading,
        options: printerList,
        showClear: true,
        isValid: isValid
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default ClientPrinterSelect;
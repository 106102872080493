import { InfoAlert } from "Components/Alerts";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import Currency from "Components/Displays/UnitDisplay/Currency";
import PrepServiceSelect from "Components/EntitySelects/PrepServiceSelect";
import NumberInput from "Components/Form/NumberInput";
import CurrencyInput from "Components/Form/UnitInputs/CurrencyInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import { useDebounce } from "Components/Hooks/HelperHooks";
import { UnitOfCurrency } from "api/types/contracts/common";
import { FbaShipmentCostContract, FbaShipmentCostTableItemContract, PrepServiceContract } from "api/types/contracts/shipping";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader, Col, Modal, ModalBody, Row, Table } from "reactstrap";
import * as Yup from "yup";

type ShipmentCostBreakdownViewProps = {
    value: FbaShipmentCostContract,
    canAdd?: boolean,
    canEdit?: boolean,
    warehouseId?: string,
    currency: UnitOfCurrency,
    onPrepServiceAdded?: (prepServiceId: string) => void,
    onPrepServiceRemoved?: (prepServiceId: string) => void,
    onChange?: (value: FbaShipmentCostContract) => void
}

const ShipmentCostBreakdownView = (props: ShipmentCostBreakdownViewProps) => {
    const { t } = useTranslation();
    const [prepServiceAddModal, setPrepServiceAddModal] = useState(false);
    const [selectedPrepServiceToAdd, setSelectedPrepServiceToAdd] = useState<PrepServiceContract>();

    const validation = useFormik({
        initialValues: {
            ...props.value,
            items: props.value.items.map(i => ({
                ...i
            }))
        },
        validationSchema: Yup.object<FbaShipmentCostContract, TypedShape<FbaShipmentCostContract>>({
            import: Yup.number().required().min(0),
            shipping: Yup.number().required().min(0),
            prep: Yup.number().required().min(0),
            insurance: Yup.number().required().min(0),
            oversize: Yup.number().required().min(0),
            overweight: Yup.number().required().min(0),
            total: Yup.number().required().min(0),
            items: Yup.array<FbaShipmentCostTableItemContract>(Yup.object<FbaShipmentCostTableItemContract, TypedShape<FbaShipmentCostTableItemContract>>({
                count: Yup.number().required().min(0),
                total: Yup.number().required().min(0),
                unitPrice: Yup.number().required().min(0),
                serviceName: Yup.string().required(),
                prepServiceId: Yup.string().required(),
                discount: Yup.number().notRequired().min(0)
            }).required()).required(),
        }),
        onSubmit: values => {
            values.prep = _.sumBy(values.items, i => i.total);
            values.total = values.shipping + values.import + values.prep;

            if (props.onChange) {
                props.onChange(values);
            }
        }
    })

    const prepServicesTotal = useMemo(() => _.sumBy(validation.values.items, i => i.total), [validation.values]);

    const togglePrepServiceAddModal = () => {
        setSelectedPrepServiceToAdd(undefined);
        setPrepServiceAddModal(prev => !prev);
    };

    const debouncedSave = useDebounce(() => validation.submitForm(), 1000, [validation.values]);

    useEffect(() => {
        validation.setValues({
            ...props.value,
            items: props.value.items.map(i => ({
                ...i
            }))
        });
    }, [props.value]);

    return <>
        <Card>
            <CardHeader className="border-0 pb-0">
                <h5 className="card-title mb-0">{t("Total Cost Breakdown")}</h5>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md={6}>
                        {props.canAdd && <div className="d-flex justify-content-center align-items-center border-bottom mb-0 p-2">
                            <h6 className="mb-0">{t("FPA Prep Cost")}</h6>
                            <Button className="ms-auto" size="sm" color="secondary" type="button" onClick={togglePrepServiceAddModal}>{t("Add New Service")}</Button>
                        </div>}
                        {validation.values.items && validation.values.items.length > 0 ? <Table striped borderless className="mb-0 align-middle" size="sm">
                            <thead>
                                <tr>
                                    <th>{t("Service Name")}</th>
                                    <th>{t("Unit Price")}</th>
                                    <th>{t("Count")}</th>
                                    <th>{t("Total")}</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {validation.values.items.map((costItem, i) => <tr key={i}>
                                    <td>{costItem.serviceName}</td>
                                    <td><Currency value={costItem.unitPrice} currency={props.currency} /></td>
                                    <td>
                                        {props.canEdit ? <ValidationWrapper validation={validation} field={`items.${i}.count`}>
                                            <NumberInput onChange={val => {
                                                validation.setFieldValue(`items.${i}.total`, (val ?? 0) * costItem.unitPrice);
                                                debouncedSave();
                                            }} />
                                        </ValidationWrapper> : costItem.count}
                                    </td>
                                    <td>
                                        {props.canEdit ? <ValidationWrapper validation={validation} field={`items.${i}.total`}>
                                            <CurrencyInput onChange={() => debouncedSave()} />
                                        </ValidationWrapper> : <Currency value={costItem.total} currency={props.currency} />}
                                    </td>
                                    <td>
                                        {<Button disabled={!costItem.deletable} color="ghost-danger" size="sm" className="btn-icon" onClick={() => {
                                            props.onPrepServiceRemoved?.(costItem.prepServiceId);
                                            validation.setFieldValue("items", validation.values.items.filter((_, index) => index !== i));
                                            debouncedSave();
                                        }}>
                                            <i className="ri-delete-bin-2-line fs-16" />
                                        </Button>}
                                    </td>
                                </tr>)}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colSpan={3} className="text-end">{t("FBA Prep Total")}</th>
                                    <th>
                                        <Currency value={validation.values.prep} currency={props.currency} />
                                    </th>
                                </tr>
                            </tfoot>
                        </Table> : <>
                            <InfoAlert>{t("No Prep Services Selected")}</InfoAlert>
                        </>}
                    </Col>
                    <Col md={6}>
                        <h6 className="text-center border-bottom mb-0 p-2">{t("Shipping Cost")}</h6>
                        <Table borderless className="align-middle" size="sm">
                            <tbody>
                                <tr>
                                    <td>{t("Shipping Cost")}</td>
                                    <th className="w-25">
                                        <Currency value={validation.values.shipping} currency={props.currency} />
                                    </th>
                                </tr>
                                <tr>
                                    <td>{t("Import Duty & Customs Tax")}</td>
                                    <th>
                                        <Currency value={validation.values.import} currency={props.currency} />
                                    </th>
                                </tr>
                                <tr>
                                    <td>{t("Insurance Cost")}</td>
                                    <th>
                                        <Currency value={validation.values.insurance} currency={props.currency} />
                                    </th>
                                </tr>
                                <tr>
                                    <td>{t("Oversize Cost")}</td>
                                    <th>
                                        <Currency value={validation.values.oversize} currency={props.currency} />
                                    </th>
                                </tr>
                                <tr>
                                    <td>{t("Overweight Cost")}</td>
                                    <th>
                                        <Currency value={validation.values.overweight} currency={props.currency} />
                                    </th>
                                </tr>
                                <tr>
                                    <th>{t("Total")}</th>
                                    <th><Currency value={validation.values.shipping + validation.values.import} currency={props.currency} /></th>
                                </tr>
                            </tbody>
                        </Table>
                        <h6 className="text-center border-bottom mb-0 p-2">{t("Summary")}</h6>
                        <Table borderless className="mb-0 align-middle" size="sm">
                            <tbody>
                                <tr>
                                    <td>{t("Shipping & Tax")}</td>
                                    <th className="w-25"><Currency value={validation.values.shipping + validation.values.import} currency={props.currency} /></th>
                                </tr>
                                <tr>
                                    <td>{t("FBA Preps")}</td>
                                    <th><Currency value={prepServicesTotal} currency={props.currency} /></th>
                                </tr>
                                <tr>
                                    <th className="text-right">{t("Total")}</th>
                                    <th>{validation.values.shipping ? <Currency value={validation.values.shipping + validation.values.import + prepServicesTotal} currency={props.currency} /> : "-"}</th>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </CardBody>
        </Card>
        <Modal backdrop="static" isOpen={prepServiceAddModal} toggle={togglePrepServiceAddModal} size="sm">
            <ModalBody>
                <ModalCloseButton onClick={togglePrepServiceAddModal} />
                <div className="vstack justify-content-center gap-3">
                    <h5 className="mb-3">{t("Add Prep Service")}</h5>
                    <PrepServiceSelect channel="fba" value={selectedPrepServiceToAdd?.prepServiceId} warehouseId={props.warehouseId} onSelect={setSelectedPrepServiceToAdd} />
                    <Button color="primary" disabled={!selectedPrepServiceToAdd} onClick={() => {
                        if (selectedPrepServiceToAdd) {
                            togglePrepServiceAddModal();

                            if (validation.values.items.find(i => i.prepServiceId === selectedPrepServiceToAdd.prepServiceId)) {
                                return;
                            }

                            validation.setFieldValue("items", [...validation.values.items, {
                                count: 1,
                                discount: 0,
                                prepServiceId: selectedPrepServiceToAdd.prepServiceId,
                                serviceName: selectedPrepServiceToAdd.name,
                                total: selectedPrepServiceToAdd.price,
                                unitPrice: selectedPrepServiceToAdd.price,
                                deletable: true
                            }]);
                            
                            props.onPrepServiceAdded?.(selectedPrepServiceToAdd.prepServiceId);
                            debouncedSave();
                        }
                    }}>{t("Add Service")}</Button>
                </div>
            </ModalBody>
        </Modal>
    </>;
}

export default ShipmentCostBreakdownView;
import { setList, loading, apiError } from "./reducer";
import { ListPrepServicesQuery } from "api/types/queries";
import { DeletePrepServiceCommand } from "api/types/commands";
import { ApiError } from "helpers/types";
import { toast } from "react-toastify";
import i18n from 'i18n';
import { deletePrepService, getPrepServiceList } from "api/prepService";
import { AppDispatch, RootState } from "slices";

export const loadPrepServiceList = (params: ListPrepServicesQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["list", true]));

        var result = await getPrepServiceList(params);
        
        dispatch(setList(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false]));
    }
};

export const removePrepService = (params: DeletePrepServiceCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["delete", true]));

        await deletePrepService(params);

        toast.success(i18n.t("Prep service deleted", { count: 1 }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const removeMultiPrepServices = (params: DeletePrepServiceCommand[]) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["delete", true]));

        for (const item of params) {
            await deletePrepService(item);
        }

        toast.success(i18n.t("Prep services deleted", { count: params.length }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const resetError = () => (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(apiError(undefined));
}

import { FormikValidation } from "helpers/types";
import { FormFeedback } from "reactstrap";
import FileUpload, { FileUploadProps, FileUploadRef } from "../FileUpload";
import { ForwardedRef, RefAttributes, forwardRef } from "react";

export type ValidatedFileUploadProps<T> = Omit<FileUploadProps, "initialFiles"> & {
    validation: FormikValidation<T>,
    field: keyof T
}

const ValidatedFileUpload = <T,>(props: ValidatedFileUploadProps<T>, ref: ForwardedRef<FileUploadRef>) =>  {
    const { validation, onUploaded, ...rest } = props;
    const fieldName = props.field.toString(); 
    const { error, touched, initialValue } = validation.getFieldMeta(fieldName);
    const setValue = (fileIds: string[]) => {
        onUploaded?.(fileIds);
        props.validation.setFieldValue(fieldName, props.multiple ? fileIds : fileIds[0]);
    };
    
    const initialFiles = initialValue ? (props.multiple ? initialValue : [initialValue]) : undefined;
    
    return (<>
        <FileUpload {...rest} initialFiles={initialFiles} onUploaded={setValue} ref={ref} />
        {touched && error ? (
            <FormFeedback type="invalid" tooltip>
                <div>{error}</div>
            </FormFeedback>
        ) : null}
    </>)
}

export default forwardRef(ValidatedFileUpload<any>) as 
    <T>(props: ValidatedFileUploadProps<T> & RefAttributes<FileUploadRef>) => JSX.Element;
import { getAuthToken } from "api/auth";

import {
    loginSuccess,
    logoutUserSuccess,
    apiError,
    reset_login_flag,
    loading
} from "./reducer";

import { LoginCommand } from "api/types/commands";
import { ApiError } from "helpers/types";
import { AppDispatch, RootState } from "slices";
import { setLoggedInUser } from "helpers/localStorage";
import { getCurrentCustomer } from "api/customer";
import { setCustomer } from "slices/tenant/reducer";

export const loginUser = (login: LoginCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading());

        var result = await getAuthToken(login);
        
        dispatch(loginSuccess(result));
        setLoggedInUser(result);

        if (result.user.userType === "customer") {
            var customer = await getCurrentCustomer();

            dispatch(setCustomer(customer));
        }
        else {
            dispatch(setCustomer(undefined));
        }

        return true;
    } catch (error) {
        const e = error as ApiError;

        throw new Error(e.message);
    }
};

export const logoutUser = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        setLoggedInUser(undefined);
        dispatch(logoutUserSuccess());
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
};

export const resetLoginFlag = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const response = dispatch(reset_login_flag());
        return response;
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
};
import { Card, CardBody, CardHeader, CardTitle, FormGroup, Label } from "reactstrap";
import type { EditSectionProps } from "./PrintDocumentEditPage";
import { useTranslation } from "react-i18next";
import Checkbox from "Components/Form/Checkbox";
import ShippingLabelCustomFieldSelect from "Components/EnumSelects/ShippingLabelCustomFieldSelect";
import { useEffect } from "react";
import type { ShippingLabelCustomField, ShippingLabelPrintPreferences } from "api/types/contracts/printing";

const ShippingLabelEditSection = (props: EditSectionProps) => {
    const values = { ...props.value } as Record<keyof ShippingLabelPrintPreferences, string | undefined>;

    const { t } = useTranslation();

    return <>
        <Card>
            <CardHeader>
                <CardTitle tag="h6" className="mb-0">{t("Label Message")}</CardTitle>
            </CardHeader>
            <CardBody>
                <FormGroup>
                    <Checkbox switch value={values.enableCustomMessages === "true"} onChange={val => {
                        if (val === (values.enableCustomMessages === "true")) {
                            return;
                        }
                        
                        values.enableCustomMessages = val ? "true" : "false";
                        props.onChange(values);
                    }}>
                        <span>{t("Enable Custom messages")}</span>
                        <br />
                        <span className="text-muted fw-light">
                            {t("paragraphs:ShippingLabelCustomMessageDescription")}
                        </span>
                    </Checkbox>
                </FormGroup>
                <FormGroup>
                    <Label className="mb-1">{t("Custom Message 1")}</Label>
                    <ShippingLabelCustomFieldSelect disabled={values.enableCustomMessages !== "true"} showClear value={values.field1 as ShippingLabelCustomField} onChange={val => {
                        if (values.field1 === val) {
                            return;
                        }
                        
                        values.field1 = val;
                        props.onChange(values);
                    }} />
                </FormGroup>
                <FormGroup>
                    <Label className="mb-1">{t("Custom Message 2")}</Label>
                    <ShippingLabelCustomFieldSelect disabled={values.enableCustomMessages !== "true"} showClear value={values.field2 as ShippingLabelCustomField} onChange={val => {
                        if (values.field2 === val) {
                            return;
                        }
                        
                        values.field2 = val;
                        props.onChange(values);
                    }} />
                </FormGroup>
                <FormGroup>
                    <Label className="mb-1">{t("Custom Message 3")}</Label>
                    <ShippingLabelCustomFieldSelect disabled={values.enableCustomMessages !== "true"} showClear value={values.field3 as ShippingLabelCustomField} onChange={val => {
                        if (values.field3 === val) {
                            return;
                        }
                        
                        values.field3 = val;
                        props.onChange(values);
                    }} />
                </FormGroup>
            </CardBody>
        </Card>
    </>;
}

export default ShippingLabelEditSection;
import { useMutation, useQuery } from "@tanstack/react-query";
import { getPrinterPreferences, patchPrinterPreferences } from "api/printing";
import type { PrintingSetupContract } from "api/types/contracts/printing";
import { GetPrinterPreferencesQuery } from "api/types/queries";
import Loader from "Components/Common/Loader";
import Restricted from "Components/Common/Restricted";
import TitleBreadcrumb from "Components/Common/TitleBreadcrumb"
import ClientPrinterSelect from "Components/EntitySelects/ClientPrinterSelect";
import UserSelect from "Components/EntitySelects/UserSelect";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { useProfile } from "Components/Hooks/ProfileHooks";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Label, Row, Table, UncontrolledButtonDropdown } from "reactstrap"
import { useImmer } from "use-immer";

const templateTypeMap: (keyof PrintingSetupContract)[] = [
    "shippingLabel",
    "packingSlip",
    "invoice",
    "binLabel",
    "pickList",
    "fnskuLabel",
    "locationLabel",
    "binLocationLabel"
];

const PrintingSetupPage = () => {
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const { userProfile } = useProfile();
    const [query, updateQuery] = useImmer<GetPrinterPreferencesQuery>({
        userId: userProfile?.user.userId
    });
    
    setPageTitle(`${t("Printer Setup")} - ${t("Management")}`);

    const printPreferencesQuery = useQuery({
        queryKey: ["print-preferences", query],
        queryFn: () => getPrinterPreferences(query)
    });

    const prefferredPrinterSaveMutation = useMutation({
        mutationFn: patchPrinterPreferences,
        mutationKey: ["save-preferred-printer"],
        onSuccess: () => {
            printPreferencesQuery.refetch();
        }
    });

    return <>
        <div className="page-content">
            <Container fluid>
                <TitleBreadcrumb active={t("Printer Setup")} parents={[t("Management")]} />
                <Card body>
                    <h5 className="mb-3">
                        <i className="ri-printer-fill me-2"></i>
                        {t("Printing Setup")}
                    </h5>
                    <p>{t("paragraphs:PrintingSetupDescription")}</p>
                    <Restricted require="management.printer" write>
                        <Row>
                            <Col xs={12} md={6}>
                                <FormGroup row className="align-items-center" noMargin>
                                    <Label className="col-6 mb-0">{t("Select operator")}</Label>
                                    <Col xs={6}>
                                        <UserSelect value={query.userId} userTypes={["companyAdmin", "employee"]} onChange={val => updateQuery(q => {
                                            q.userId = val;
                                        })} />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Restricted>
                </Card>
                <Card>
                    <CardBody>
                        <div className="table-card table-responsive">
                            <Table className="align-middle mb-0" borderless>
                                <thead>
                                    <tr>
                                        <th style={{ width: "25%" }}>{t("Document Type")}</th>
                                        <th style={{ width: "30%" }}>{t("Print To")}</th>
                                        <th>{t("Document Format")}</th>
                                        <th style={{ width: "10%" }}>{t("Options")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {printPreferencesQuery.isFetching ? <tr>
                                        <td colSpan={4}>
                                            <Loader height="30px" />
                                        </td>
                                    </tr> : <>
                                        {templateTypeMap.map((templateType, i) => <React.Fragment key={i}>
                                            {printPreferencesQuery.isSuccess && <tr>
                                                <td>{t(`enums:PrintTemplateType.${templateType}`)}</td>
                                                <td>
                                                    <ClientPrinterSelect 
                                                        disabled={prefferredPrinterSaveMutation.isPending} 
                                                        size="sm" 
                                                        value={printPreferencesQuery.data[templateType].preferredClientPrinter?.clientPrinterId ?? ""} 
                                                        onChange={val => {
                                                            if (printPreferencesQuery.data[templateType].preferredClientPrinter?.clientPrinterId === val) {
                                                                return;
                                                            }
                                                            
                                                            prefferredPrinterSaveMutation.mutate({
                                                                printPreferenceId: printPreferencesQuery.data[templateType].printPreferenceId, 
                                                                preferredClientPrinterId: val
                                                            });
                                                        }} />
                                                </td>
                                                <td>
                                                    {printPreferencesQuery.data[templateType].printSize.name}
                                                </td>
                                                <td>
                                                    <UncontrolledButtonDropdown>
                                                        <DropdownToggle color="ghost-primary" size="sm" className="btn-icon">
                                                            <i className="ri-settings-5-fill fs-16"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem tag={Link} to={`/printing-setup/${printPreferencesQuery.data[templateType]?.printPreferenceId}`}>
                                                                {t("Edit Document")}
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </td>
                                            </tr>}
                                        </React.Fragment>)}
                                    </>}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </div>
    </>;
}

export default PrintingSetupPage;
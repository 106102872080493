import { BinLocationContract } from "api/types/contracts/locations";
import { useTranslation } from "react-i18next";
import { DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, UncontrolledButtonDropdown } from "reactstrap";
import UpdateBinLocationFormView from "./_UpdateBinLocationFormView";
import { useRef, useState } from "react";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import { reloadLocations, removeBinLocation } from "slices/location/thunk";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";

type BinItemProps = {
    binLocation: BinLocationContract,
    mini?: boolean
}

const BinItem = (props: BinItemProps) => {
    const [editModal, setEditModal] = useState(false);
    const dialogRef = useRef<DialogRef>(null);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { warehouse, loading } = useAppSelector(
        (state) => ({
            warehouse: state.Location.warehouse,
            loading: state.Location.loading
        })
    );

    const reload = () => dispatch(reloadLocations({ warehouseId: warehouse!.warehouseId }));

    const onUpdated = () => { 
        setEditModal(false);
        reload();
    };

    const deleteBin = async () => {
        await dispatch(removeBinLocation({ binLocationId: props.binLocation.binLocationId }));
        await reload();
        dialogRef.current?.hide();
    };

    return <>
        <div className="bin-item position-relative border rounded border-dark-subtle aspect-1x1">
            <div className="d-flex justify-content-center align-items-center w-100 h-100">
                <span># {props.binLocation.name}</span>
                    <div className="bin-actions auto-hide-actions">
                        <div className="position-absolute top-0 end-0">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle tag="button" className="btn btn-sm btn-ghost-dark btn-icon">
                                <i className="ri-more-2-fill"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => setEditModal(true)}>{t("Edit")}</DropdownItem>
                                <DropdownItem onClick={() => dialogRef.current?.show()}>{t("Delete")}</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </div>
                </div>
            </div>
        </div>
        <Modal backdrop="static" isOpen={editModal} toggle={() => setEditModal(prev => !prev)} size="lg" unmountOnClose>
            <ModalBody>
                <ModalCloseButton onClick={() => setEditModal(false)} />
                <UpdateBinLocationFormView binLocation={props.binLocation} onSuccess={onUpdated} />
            </ModalBody>
        </Modal>
        <Dialog ref={dialogRef} color="warning" buttons={["yes", "no"]} busy={loading.delete} iconClass="ri-delete-bin-line"  
            message={`Do you want to continue?`} title={t("Deleting \"Bin #{{binName}}\"", { binName: props.binLocation.name })}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    deleteBin();
                }
                else {
                    hide();
                }
            }} />
    </>;
}

export default BinItem;
import BusyOverlay from "Components/Common/BusyOverlay";
import { useTranslation } from "react-i18next";
import Grid from "./_Grid";
import { createAppSelector, useAppSelector } from "Components/Hooks/StoreHooks";
import { useMetadata } from "Components/Hooks/MetadataHooks";

const BaysView = () => {
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();

    const { selectedAisle, loading } = useAppSelector(
        createAppSelector([state => state.Location],
            (location) => ({
                loading: location.loading,
                selectedAisle: location.areas?.find(a => a.areaId === location.selectedAreaId)?.aisles?.find(a => a.aisleId === location.selectedAisleId)
            })
        )
    );

    setPageTitle(`${(t("Aisle {{aisle}}", { aisle: selectedAisle?.name }))} - ${t("Locations")} - ${t("Management")}`);

    return <>
        <BusyOverlay busy={loading.data} blur>
            {selectedAisle && <Grid aisle={selectedAisle} />}
        </BusyOverlay>
    </>;
}

export default BaysView;
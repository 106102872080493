import { ColumnDef } from "@tanstack/react-table";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import ListSummaryDisplay from "Components/Common/ListSummaryDisplay";
import Restricted from "Components/Common/Restricted";
import TableContainer, { TableContainerRef, selectRowColumn } from "Components/Common/TableContainer";
import PrepServicePricingTypeBadge from "Components/Displays/PrepServicePricingTypeBadge";
import Currency from "Components/Displays/UnitDisplay/Currency";
import CompanySelect from "Components/EntitySelects/CompanySelect";
import PrepServiceChannelSelect from "Components/EnumSelects/PrepServiceChannelSelect";
import { useDebounce } from "Components/Hooks/HelperHooks";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { PrepServiceContract } from "api/types/contracts/shipping";
import { ListPrepServicesQuery } from "api/types/queries";
import { Ref, RefAttributes, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Badge, Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { loadPrepServiceList, removeMultiPrepServices, removePrepService } from "slices/shipping/prepService/thunk";
import { useImmer } from "use-immer";

export type PrepServiceListRef = {
    reload: VoidFunction
}

const List = (props: {}, ref: Ref<PrepServiceListRef>) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const tableRef = useRef<TableContainerRef>(null);
    const dialogRef = useRef<DialogRef>(null);
    const [deleteMode, setDeleteMode] = useState<"single" | "multi">();
    const [selectedItems, setSelectedItems] = useState<PrepServiceContract[]>([]);
    const prepService = useMemo(() => selectedItems[0], [selectedItems]);
    
    const [query, updateQuery] = useImmer<ListPrepServicesQuery>({
        page: 1,
        pageSize: 10,
    });

    const { list, loading } = useAppSelector(
        (state) => ({
            loading: state.PrepService.loading,
            list: state.PrepService.list,
            error: state.PrepService.error,
        })
    );

    setPageTitle(`${t("Prep Service List")} - ${t("Management")}`);

    const debouncedLoadList = useDebounce(() => {
        dispatch(loadPrepServiceList(query)).then(() => {
            tableRef.current?.resetSelection();
        });
    }, 200);

    useEffect(() => {
        debouncedLoadList();
    }, [debouncedLoadList, dispatch, query]);

    useImperativeHandle(ref, () => {
        return {
            reload: () => {
                debouncedLoadList();
            }
        };
    }, [debouncedLoadList]);

    const deletePrepService = useCallback(async () => {
        if (deleteMode === "multi" && selectedItems.length > 0) {
            return await dispatch(removeMultiPrepServices(selectedItems.map(item => ({
                prepServiceId: item.prepServiceId
            }))));
        }
        else if (deleteMode === "single" && prepService) {
            return await dispatch(removePrepService({
                prepServiceId: prepService.prepServiceId
            }));
        }

        return false;
    }, [prepService, deleteMode, selectedItems, dispatch]);

    const handleDeleteClick = (arg: PrepServiceContract) => {
        setSelectedItems([arg]);
        setDeleteMode("single");
    };

    const handleMultiDeleteClick = () => {
        setDeleteMode("multi");
    };
    useEffect(() => {
        if (deleteMode) {
            dialogRef.current?.show();
        }
        else {
            dialogRef.current?.hide();
        }
    }, [deleteMode]);

    // Column
    const columns = useMemo<ColumnDef<PrepServiceContract, any>[]>(() => [
        selectRowColumn<PrepServiceContract>(), 
        {
            header: t("ACTIONS"),
            enableHiding: false,
            cell: (cell) => <>
                <div className="hstack gap-1">
                    <Restricted require="management.prepservice" write ownership={cell.row.original} fallback={() => <Button color="ghost-dark" size="sm" className="btn-icon" disabled>
                            <i className="ri-pencil-fill fs-16"></i>
                        </Button>}>
                        <Link to={`/prep-services/edit/${cell.row.original.prepServiceId}`} className="btn btn-sm btn-icon btn-ghost-dark">
                            <i className="ri-pencil-fill fs-16"></i>
                        </Link>
                    </Restricted>
                    <Restricted require="management.prepservice" delete ownership={cell.row.original} fallback={() => <Button color="ghost-dark" size="sm" className="btn-icon" disabled>
                            <i className="ri-delete-bin-5-fill fs-16"></i>
                        </Button>}>
                        <Button color="ghost-danger" size="sm" className="btn-icon"
                            onClick={() => handleDeleteClick(cell.row.original)}>
                            <i className="ri-delete-bin-5-fill fs-16"></i>
                        </Button>
                    </Restricted>
                </div>
            </>
        }, 
        {
            header: t("SERVICE NAME"),
            accessorFn: item => item.name,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <Restricted require="management.prepservice" ownership={cell.row.original} write fallback={() => <>{cell.row.original.name}</>}>
                <Link to={`/prep-services/edit/${cell.row.original.prepServiceId}`} className="fw-medium link-secondary">{cell.row.original.name}</Link>
            </Restricted>
        },
        {
            header: t("WAREHOUSE"),
            accessorFn: item => item.warehouse.name,
            enableHiding: false,
            enableColumnFilter: false
        },
        {
            header: t("USED CHANNEL(S)"),
            accessorFn: item => item.channels,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <div className="hstack gap-1">
                {cell.row.original.channels.map((c, i) => <Badge key={i}>{t(`enums:PrepServiceChannel.${c}`)}</Badge>)}
            </div>
        },
        {
            header: t("UNIT PRICE"),
            accessorFn: item => item.price,
            enableColumnFilter: false,
            cell: (cell) => cell.row.original.priceRanges.length !== 0 ? <>
                <ListSummaryDisplay items={cell.row.original.priceRanges} displayFn={pr => <>{<Currency maxDigits={5} value={pr.from} currency={cell.row.original.warehouse.settings.unitOfCurrency} />} - {<Currency maxDigits={5} value={pr.to} currency={cell.row.original.warehouse.settings.unitOfCurrency} />} (<Currency maxDigits={5} value={pr.value} currency={cell.row.original.warehouse.settings.unitOfCurrency} />)</>} displayCount={1} />
            </> : <Currency value={cell.row.original.price} currency={cell.row.original.warehouse.settings.unitOfCurrency} />
        },
        {
            header: t("PRICING TYPE"),
            accessorFn: item => item.pricingType,
            enableColumnFilter: false,
            cell: (cell) => <PrepServicePricingTypeBadge value={cell.row.original.pricingType} />
        }],
        [t]
    );

    return <>
        <Row>
            <Col lg={12}>
                <Card>
                    <CardHeader className="border-0">
                        <Row className="align-items-center gy-3">
                            <div className="col-sm">
                                <h5 className="card-title mb-0">{t("Prep Services")}</h5>
                            </div>
                            <div className="col-sm-auto">
                                <div className="d-flex gap-1 flex-wrap">
                                    <Link to="/prep-services/new" className="btn btn-info add-btn">
                                        <i className="ri-add-line align-bottom me-1"></i> {t("Add New Prep Service")}
                                    </Link>
                                    {selectedItems.length > 0 && deleteMode !== "single" && <Button color="soft-danger" onClick={() => handleMultiDeleteClick()}>
                                        <i className="ri-delete-bin-2-line"></i>
                                    </Button>}
                                </div>
                            </div>
                        </Row>
                    </CardHeader>
                    <CardBody className="pt-0">
                        <div>
                            <Row>
                                <Restricted require="management.company" read>
                                    <Col sm={6} md={3}>
                                        <CompanySelect value={query.companyId} onSelect={company => updateQuery(q => {
                                            q.companyId = company?.companyId;
                                        })} />
                                    </Col>
                                </Restricted>
                                <Col sm={6} md={3}>
                                    <PrepServiceChannelSelect value={query.channel} showClear
                                        onChange={val => updateQuery(q => {
                                            q.channel = val;
                                        })} ></PrepServiceChannelSelect>
                                </Col>
                            </Row>
                            <TableContainer
                                ref={tableRef}
                                busy={loading.list}
                                columns={columns}
                                nowrap
                                data={(list?.items || [])}
                                totalDataLength={list?.totalCount}
                                pagination={{
                                    pageIndex: query.page - 1,
                                    pageSize: query.pageSize
                                }}
                                onPaginationChanged={pagination => updateQuery(q => {
                                    q.page = pagination.pageIndex + 1;
                                    q.pageSize = pagination.pageSize;
                                })}
                                onSelectionChanged={selection => {
                                    setSelectedItems(selection);
                                }}
                                divClass="mb-1"
                                tableClass="align-middle"
                            />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        {deleteMode && <Dialog ref={dialogRef} color="warning" buttons={["yes", "no"]} busy={loading.delete} iconClass="ri-delete-bin-line"  
            message={`Do you want to continue?`} title={`Deleting "${(deleteMode === "multi" ? `${selectedItems.length} prep service(s)` : prepService?.name)}"`}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    deletePrepService().then(success => {
                        if (success) {
                            debouncedLoadList();
                        }
                    }).finally(() => {
                        setDeleteMode(undefined);
                        setSelectedItems([]);
                    });
                }
                else {
                    setDeleteMode(undefined);
                }
            }} />}
    </>;
}

export default forwardRef(List) as (
    props: RefAttributes<PrepServiceListRef>
) => ReturnType<typeof List>;
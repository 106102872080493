import Loader from "Components/Common/Loader";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import SearchBox from "Components/Form/SearchBox";
import { CarrierContract } from "api/types/contracts/carrier";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import EditCarrierAccountForm from "./_EditCarrierAccountForm";
import classNames from "classnames";
import { hover } from "helpers/styleHelpers";
import { useQuery } from "@tanstack/react-query";
import { getCarrierList } from "api/carrier";

type AddCarrierButtonProps = {
    onSuccess?: (params: { selectedWarehouseId: string }) => void;
}

const AddCarrierButton = (props: AddCarrierButtonProps) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState("");
    const [selectedCarrier, setSelectedCarrier] = useState<CarrierContract>();
    const [carrierSelectModal, setCarrierSelectModal] = useState(false);
    const [addCarrierModal, setAddCarrierModal] = useState(false);
    
    const toggleCarrierSelectModal = () => setCarrierSelectModal(prev => !prev);
    const toggleAddCarrierModal = () => setAddCarrierModal(prev => !prev);

    const { data: carriers, isFetching: loading } = useQuery({
        queryKey: ["carriers"],
        queryFn: () => getCarrierList({}),
    });

    const filteredCarriers = useMemo(() => carriers?.filter(c => c.name.toLowerCase().includes(search.toLowerCase())) ?? [], [carriers, search]);

    const selectCarrier = (carrier: CarrierContract) => {
        setSelectedCarrier(carrier);
        setCarrierSelectModal(false);
        setAddCarrierModal(true);
    }

    return <>
        <Button color="secondary" onClick={toggleCarrierSelectModal}>{t("Add Carrier")}</Button>
        <Modal backdrop="static" isOpen={carrierSelectModal} toggle={toggleCarrierSelectModal} unmountOnClose>
            <ModalCloseButton onClick={toggleCarrierSelectModal} />
            <ModalBody>
                <p>{t("paragraphs:AddCarrierAccountDescription")}</p>
                <div>
                    <SearchBox value={search} onChange={setSearch} className="mb-3" />
                    {loading ? <Loader height="300px" /> : <>
                        <Row className="g-3">
                            {filteredCarriers.map((carrier, i) => <Col key={i} xs={3} className="text-center">
                                <div {...hover(classNames("position-relative p-3 rounded rounded-2 border border-transparent", {
                                    "border-primary bg-primary-subtle": selectedCarrier?.carrierId === carrier.carrierId
                                }), "border-primary")}>
                                    <img src={carrier.logoImage} alt={carrier.name} className="w-100 mb-2 aspect-1x1 object-fit-contain" />
                                    <a href="#" className="stretched-link position-static" onClick={() => { selectCarrier(carrier); return false; }}>{carrier.name}</a>
                                </div>
                            </Col>)}
                        </Row>
                        <Row>
                            <Col className="text-center g-3">
                                <div className="fs-5 mb-1">{t("Want an additional carrier?")}</div>
                                <div className="fs-10"><Link to="/contact">{t("Contact support")}</Link>, {t("we add new carriers all the time")}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-end">
                                <Button color="secondary" onClick={toggleCarrierSelectModal}>{t("Cancel")}</Button>
                            </Col>
                        </Row>
                    </>}
                </div>
            </ModalBody>
        </Modal>
        <Modal backdrop="static" isOpen={addCarrierModal} toggle={toggleAddCarrierModal}>
            <ModalBody>
                <ModalCloseButton onClick={toggleAddCarrierModal} />
                <EditCarrierAccountForm carrier={selectedCarrier!} onSuccess={params => {
                    toggleAddCarrierModal();
                    props.onSuccess?.(params);
                }} onCancel={() => {
                    toggleAddCarrierModal();
                    toggleCarrierSelectModal();
                }} />
            </ModalBody>
        </Modal>
    </>;
}

export default AddCarrierButton;
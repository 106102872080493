import { useQuery } from "@tanstack/react-query";
import SelectInput from "Components/Form/SelectInput"
import type { UserContract, UserType } from "api/types/contracts/users";
import type { ListUsersQuery } from "api/types/queries";
import { getUserList } from "api/user";
import { EntitySelectInputProps } from "helpers/types";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useImmer } from "use-immer";

type UserSelectProps = EntitySelectInputProps<UserContract, "userId"> & {
    roleId?: string,
    userTypes?: UserType[],
}

const UserSelect = ({ isMulti, value, onChange, onSelect, name, userTypes, ...rest }: UserSelectProps) => {
    const { t } = useTranslation();
    const [query, updateQuery] = useImmer<ListUsersQuery>({
        search: "",
        userTypes: userTypes,
        page: 1,
        pageSize: 50
    });

    const { data: users, isFetching: usersLoading } = useQuery({
        queryKey: ["users", query],
        queryFn: () => getUserList(query)
    })

    const userList = useMemo(() => users?.items.map(c => ({ 
        value: c.userId, 
        label: c.name
    })) ?? [], [users]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = users?.items.filter(u => val.includes(u.userId));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = users?.items.find(s => s.userId === val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.userId);
                return;
            }
        }

        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        if (!value) {
            return;
        }

        if (!users?.items || users.items.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users]);
    
    const onSearch = useCallback((val?: string) => {
        updateQuery(q => {
            q.search = val;
            return q;
        });
    }, [updateQuery]);

    useEffect(() => {
        updateQuery(q => {
            q.userTypes = userTypes;
            return q;
        });
    }, [userTypes])

    const selectProps = {
        ...rest,
        placeholder: rest.placeholder ?? t("User"),
        busy: usersLoading,
        options: userList,
        showClear: true,
        onSearch: onSearch
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default UserSelect;
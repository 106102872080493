import { setList, loading, apiError, setSelectList } from "./reducer";
import { GetCompanyQuery, ListCompaniesQuery, ListCompaniesQueryForSelect } from "api/types/queries";
import { getCompanyList, deleteCompany, getCompany, getCompanyListForSelect, patchCompanyStatus } from "api/company";
import { DeleteCompanyCommand, SetCompanyStatusCommand } from "api/types/commands";
import { ApiError } from "helpers/types";
import { toast } from "react-toastify";
import i18n from "i18n";
import { AppDispatch, RootState } from "slices";

export const loadCompany = (params: GetCompanyQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        var result = await getCompany(params);
        
        return result;
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
};

export const loadList = (params: ListCompaniesQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["list", true]));

        var result = await getCompanyList(params);
        
        dispatch(setList(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false]));
    }
};

export const loadSelectList = (params: ListCompaniesQueryForSelect) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["selectList", true]));

        var result = await getCompanyListForSelect(params);
        
        dispatch(setSelectList(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["selectList", false]));
    }
};

export const resetList = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setList({
        items: [],
        currentPage: 1,
        totalCount: 0
    }));
}

export const removeCompany = (params: DeleteCompanyCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["delete", true]));

        await deleteCompany(params);
        toast.success(i18n.t("Company deleted", { count: 1 }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const removeMultipleCompanies = (params: DeleteCompanyCommand[]) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["delete", true]));

        for (const item of params) {
            await deleteCompany(item);
        }

        toast.success(i18n.t("Company deleted", { count: params.length }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const setCompanyStatus = (params: SetCompanyStatusCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["status", true]));

        await patchCompanyStatus(params);
        toast.success(i18n.t("Company status updated"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["status", false]));
    }
}
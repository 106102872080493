import Loader from "Components/Common/Loader";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import AddressDisplay from "Components/Displays/AddressDisplay";
import CountryDisplay from "Components/Displays/CountryDisplay";
import CustomerDisplay from "Components/Displays/CustomerDisplay";
import FbaShipmentStatusBadge from "Components/Displays/FbaShipmentStatusBadge";
import Currency from "Components/Displays/UnitDisplay/Currency";
import { createAppSelector, useAppSelector } from "Components/Hooks/StoreHooks";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col, Modal, ModalBody, Row } from "reactstrap";

type TopInfoProps = {
    
}

const TopInfo = (props: TopInfoProps) => {
    const { t } = useTranslation();
    const [addressModal, setAddressModal] = useState(false);
    
    const { fbaShipment, loading } = useAppSelector(
        createAppSelector([state => state.FbaShipping],
            (fbaShipping) => ({
                fbaShipment: fbaShipping.processingItem,
                loading: fbaShipping.loading
            })
        )
    );

    if (!fbaShipment) {
        return <Loader height="50px" />;
    }

    return <Card body>
        <Row className="align-items-center">
            <Col md={9}>
                <dl className="row align-items-center mb-0 gx-1 gy-3">
                    <dt className="col">{t("ID")}</dt>
                    <dd className="col-2 mb-0"># {fbaShipment.shipmentCode}</dd>
                    <dt className="col-2">{t("Status")}</dt>
                    <dd className="col-2 mb-0"><FbaShipmentStatusBadge value={fbaShipment.status} /></dd>
                    <dt className="col-2">{t("Country")}</dt>
                    <dd className="col-2 mb-0"><CountryDisplay country={fbaShipment.shipmentTarget.address.country} /></dd>
                    <dt className="col-2">{t("Customer")}</dt>
                    <dd className="col-2 mb-0"><CustomerDisplay customer={fbaShipment.customer} /></dd>
                    <dt className="col-2">{t("W/T Products")}</dt>
                    <dd className="col-2 mb-0">{_.sumBy(fbaShipment.items, "count")}/{0}</dd>
                    <dt className="col-2">{t("Expected Total Price")}</dt>
                    <dd className="col-2 mb-0"><Currency value={fbaShipment.actualCost.total} currency={fbaShipment.warehouse.settings.unitOfCurrency} /></dd>
                </dl>
            </Col>
            <Col md={3}>
                <Card className="mb-0 card-animate">
                    <CardBody>
                        <div className="d-flex position-relative">
                            <a className="stretched-link position-absolute top-0 start-0 w-100 h-100" href="#" onClick={e => {
                                setAddressModal(true);

                                e.preventDefault();
                                return false;
                            }}>&nbsp;</a>
                            <AddressDisplay 
                                className="fs-12"
                                copyButtonClassName="z-1"
                                address={fbaShipment.warehouse.address} 
                                suitNumber={fbaShipment.customer.suitNumber}
                                warehouseName={fbaShipment.warehouse.name} />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        <Modal backdrop="static" isOpen={addressModal} toggle={() => setAddressModal(false)} unmountOnClose>
            <ModalCloseButton onClick={() => setAddressModal(false)} />
            <ModalBody>
                <div className="text-center pb-4">
                    <i className="ri-mail-send-line text-success ri-5x"></i>
                    <p className="mb-4">{t("You must send your shipment to address below")}</p>
                    <AddressDisplay 
                        inline
                        className="fs-16 gap-2 flex-wrap justify-content-center"
                        address={fbaShipment.warehouse.address} 
                        suitNumber={fbaShipment.customer.suitNumber}
                        warehouseName={fbaShipment.warehouse.name}  />
                </div>
            </ModalBody>
        </Modal>
    </Card>;
}

export default TopInfo;
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AisleContract, AreaContract, BayContract, ShelfContract } from "api/types/contracts/locations";
import { WarehouseContract } from "api/types/contracts/warehouses";
import { ApiError, LoadingStates } from "helpers/types";

type LocationLoadingStates = LoadingStates<"init" | "data" | "save" | "delete" | "printLabel">;
export type LocationViewModes = "area" | "aisle" | "bay" | "shelf" | "bin";

export type LocationState = {
    warehouse?: WarehouseContract,
    areas?: AreaContract[],
    selectedAreaId?: string,
    selectedAisleId?: string,
    selectedBayId?: string,
    selectedShelfId?: string,
    view?: LocationViewModes,
    loading: LocationLoadingStates,
    error?: ApiError
}

export const initialState: LocationState = {
    loading: {
        init: false,
        data: false,
        save: false,
        delete: false,
        printLabel: false
    }
};

const LocationSlice = createSlice({
    name: "LocationSlice",
    initialState,
    reducers: {
        setSelectedArea(state, action: PayloadAction<{ areaId: string } | undefined>) {
            state.selectedAreaId = action.payload?.areaId;
        },
        setSelectedAisle(state, action: PayloadAction<{ aisleId: string } | undefined>) {
            state.selectedAisleId = action.payload?.aisleId;
        },
        setSelectedBay(state, action: PayloadAction<{ bayId: string } | undefined>) {
            state.selectedBayId = action.payload?.bayId;
        },
        setSelectedShelf(state, action: PayloadAction<{ shelfId: string } | undefined>) {
            state.selectedShelfId = action.payload?.shelfId;
        },
        setView(state, action: PayloadAction<LocationViewModes | undefined>) {
            state.view = action.payload;
        },
        setWarehouse(state, action: PayloadAction<WarehouseContract | undefined>) {
            state.warehouse = action.payload;
        },
        setAreas(state, action: PayloadAction<AreaContract[] | undefined>) {
            state.areas = action.payload;
        },
        reset(state) {
            state.warehouse = undefined;
            state.areas = undefined;
            state.selectedAreaId = undefined;
            state.selectedAisleId = undefined;
            state.selectedBayId = undefined;
            state.selectedShelfId = undefined;
            state.view = undefined;
        },
        loading(state, action: PayloadAction<[keyof LocationLoadingStates, boolean]>) {
            const [operation, loadingState] = action.payload;
            state.loading[operation] = loadingState;
        },
        apiError(state, action: PayloadAction<ApiError>) {
            state.error = action.payload;
        }
    },
});

export const {
    setView,
    setAreas,
    loading,
    reset,
    setWarehouse,
    setSelectedArea,
    setSelectedAisle,
    setSelectedBay,
    setSelectedShelf,
    apiError
} = LocationSlice.actions;

export default LocationSlice.reducer;
import ModalCloseButton from "Components/Common/ModalCloseButton";
import TrackingStatusBadge from "Components/Displays/TrackingStatusBadge";
import React, { useRef, useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, Col, Modal, ModalBody, Row, Table } from "reactstrap";
import CreateBoxForm from "./_CreateBoxForm";
import { ReceivingEntryBoxForm, ReceivingBoxContract } from "api/types/contracts/receivings";
import { addReceivingEntryBox, addReceivingEntryDocument, createReceivingEntry, loadReceivingEntry, loadReceivingForGateEntry, removeReceivingEntryBox, selectReceivingForGateEntry } from "slices/receivings/thunk";
import { toast } from "react-toastify";
import BusyOverlay from "Components/Common/BusyOverlay";
import CustomerSuitDisplay from "Components/Displays/CustomerSuitDisplay";
import DocumentUpload, { DocumentUploadRef } from "Components/Form/DocumentUpload";
import { DocumentForm } from "api/types/contracts/common";
import { InfoAlert, SecondaryAlert } from "Components/Alerts";
import InboundCodeDisplay from "Components/Displays/InboundCodeDisplay";
import ListSummaryDisplay from "Components/Common/ListSummaryDisplay";
import InlineAddressDisplay from "Components/Displays/InlineAddressDisplay";
import _ from "lodash";
import ProductImageDisplay from "Components/Displays/ProductImageDisplay";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import DateDisplay from "Components/Displays/DateDisplay";
import Dimensions from "Components/Displays/UnitDisplay/Dimensions";
import { Link, useSearchParams } from "react-router-dom";
import PrivateDownload from "Components/Common/PrivateDownload";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";

type Step2Props = {
    
};

const Step2 = (props: Step2Props) => {
    const [params, setParams] = useSearchParams();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [createBoxModal, setCreateBoxModal] = useState(params.get("newbox") === "true");
    const [boxBusyState, setBoxBusyState] = useState(false);
    const [documentBusyState, setDocumentBusyState] = useState(false);
    const documentUploadRef = useRef<DocumentUploadRef>(null);
    const dialogRef = useRef<DialogRef>(null);
    const [deletingBox, setDeletingBox] = useState<ReceivingBoxContract>();

    const toggleCreateBoxModal = () => setCreateBoxModal(prev => !prev);

    const { loading, inbound, customer, receivingEntry, warehouse, shippingPurpose } = useAppSelector(
        (state) => ({
            loading: state.Receiving.loading,
            inbound: state.Receiving.gateEntry.inbound,
            customer: state.Receiving.gateEntry.customer,
            receivingEntry: state.Receiving.gateEntry.receiving,
            warehouse: state.Receiving.gateEntry.warehouse,
            shippingPurpose: state.Receiving.gateEntry.shippingPurpose,
            error: state.Receiving.error,
        })
    );

    const selectedWarehouse = receivingEntry?.warehouse || inbound?.warehouse || warehouse;

    const ensureReceivingEntry = useCallback(async () => {
        if (!receivingEntry) {
            const newEntry = await dispatch(createReceivingEntry({
                inboundId: inbound?.inboundId,
                customerId: customer?.customerId,
                warehouseId: selectedWarehouse!.warehouseId,
                shippingPurpose: shippingPurpose
            }));

            if (newEntry) {
                await dispatch(selectReceivingForGateEntry(newEntry));
            }

            return newEntry;
        }
        else {
            return receivingEntry;
        }
    }, [dispatch, customer?.customerId, inbound?.inboundId, receivingEntry, shippingPurpose, selectedWarehouse]);

    const addBox = useCallback(async (quantity: number, box: ReceivingEntryBoxForm) => {
        const receiving = await ensureReceivingEntry();

        if (receiving) {
            try {
                setBoxBusyState(true);
                await dispatch(addReceivingEntryBox({
                    receivingEntryId: receiving.receivingEntryId,
                    boxQuantity: quantity,
                    box: box
                }));
    
                await dispatch(loadReceivingForGateEntry({ 
                    receivingEntryId: receiving.receivingEntryId 
                }));
            } 
            finally {
                setBoxBusyState(false);
            }
        }
        else {
            toast.error(t("Adding box failed. Receiving entry not found"));
        }

        toggleCreateBoxModal();
    }, [dispatch, ensureReceivingEntry, t]);

    const removeBox = useCallback(async (boxId: string) => {
        const receiving = await ensureReceivingEntry();

        if (receiving) {
            try {
                setBoxBusyState(true);
                await dispatch(removeReceivingEntryBox(receiving.receivingEntryId, boxId));
                await dispatch(loadReceivingEntry({ 
                    receivingEntryId: receiving.receivingEntryId 
                }));
            } 
            finally {
                setBoxBusyState(false);
            }
        }
        else {
            toast.error(t("Removing box failed. Receiving entry not found"));
        }
    }, [dispatch, ensureReceivingEntry, t]);

    const addDocument = useCallback(async (document: DocumentForm) => {
        const receiving = await ensureReceivingEntry();

        if (receiving) {
            try {
                setDocumentBusyState(true);
                await dispatch(addReceivingEntryDocument({
                    receivingEntryId: receiving.receivingEntryId,
                    document
                }));
    
                await dispatch(loadReceivingEntry({ 
                    receivingEntryId: receiving.receivingEntryId 
                }));

                documentUploadRef.current?.reset();
            } 
            finally {
                setDocumentBusyState(false);
            }
        }
        else {
            toast.error(t("Adding document failed. Receiving entry not found"));
        }
    }, [dispatch, ensureReceivingEntry, documentUploadRef, t]);

    useEffect(() => {
        if (deletingBox) {
            dialogRef.current?.show();
        }
        else {
            dialogRef.current?.hide();
        }
    }, [deletingBox]);

    const currentInbound = receivingEntry?.inbound || inbound;
    const currentCustomer = receivingEntry?.customer || customer;

    return <>
        {currentInbound && <>
            <Row>
                <Col md={8}>
                    <Card>
                        <CardBody>
                            <Table size="sm" borderless className="mb-0">
                                <tbody>
                                    <tr>
                                        <th>{t("Inbound Code")}</th>
                                        <td><InboundCodeDisplay inboundCode={currentInbound.inboundCode} /></td>
                                        <th>{t("Shipping Purpose")}</th>
                                        <td>{t(`enums:ShippingPurpose.${currentInbound.shippingPurpose}`)}</td>
                                    </tr>
                                    <tr>
                                        <th>{t("Create Date")}</th>
                                        <td><DateDisplay date={currentInbound.createdAt} /></td>
                                        <th>{t("Supplier")}</th>
                                        <td>
                                            {currentInbound.fromAmazon ? "Amazon" : <ListSummaryDisplay items={currentInbound.suppliers} displayFn={s => s.name} />}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t("Warehouse")}</th>
                                        <td colSpan={3}>
                                            <p className="mb-0">{currentInbound.warehouse.name}</p>
                                            <InlineAddressDisplay address={currentInbound.warehouse.address} />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </CardBody>
                        <CardBody>
                            <h5 className="mb-4">{t("Trackings")}</h5>
                            {currentInbound.trackings.length > 0 ? <>
                                <div className="table-card table-responsive">
                                    <Table size="sm" striped className="align-middle mb-0">
                                        <thead>
                                            <tr>
                                                <th>{t("Tracking Number")}</th>
                                                <th>{t("Carrier")}</th>
                                                <th>{t("Status")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentInbound.trackings.map((tracking, index) => {
                                                return <tr key={index}>
                                                    <td>{tracking.trackingNumber}</td>
                                                    <td>{tracking.carrier.name}</td>
                                                    <td><TrackingStatusBadge value={tracking.status} /></td>
                                                </tr>;
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </>
                            : <>
                                <div className="text-center text-muted">{t("No trackings found")}</div>
                            </>}
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card body>
                        <div className="table-responsive table-card">
                            <Table size="sm" borderless className="mb-0">
                                <tbody>
                                    <tr>
                                        <th colSpan={2} className="text-center pt-3">
                                            {t("Summary")}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>{t("Waiting Package")}</th>
                                        <td>{t("{{waiting}} waiting / {{total}} total", {
                                            waiting: _.filter(currentInbound.items, i => i.quantity > i.received).length,
                                            total: currentInbound.items?.length
                                        })}</td>
                                    </tr>
                                    <tr>
                                        <th>{t("Waiting Products")}</th>
                                        <td>{t("{{waiting}} waiting / {{total}} total", {
                                            waiting: _.sumBy(currentInbound.items, i => i.quantity - i.received),
                                            total: _.sumBy(currentInbound.items, 'quantity')
                                        })}</td>
                                    </tr>
                                    <tr>
                                        <th colSpan={2} className="text-center pt-3">
                                            {t("Customer")}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>{t("Suit No")}</th>
                                        <td>{currentInbound.customer?.suitNumber && <CustomerSuitDisplay suit={currentInbound.customer.suitNumber} />}</td>
                                    </tr>
                                    <tr>
                                        <th>{t("Full Name")}</th>
                                        <td>{currentInbound.customer?.name} {currentInbound.customer?.surname}</td>
                                    </tr>
                                    <tr>
                                        <th>{t("Email")}</th>
                                        <td>{currentInbound.customer?.user.email}</td>
                                    </tr>
                                    <tr>
                                        <th>{t("Phone")}</th>
                                        <td>{currentInbound.customer?.phone}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card body>
                        <div className="table-card table-responsive">
                            <Table className="align-middle mb-0">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>{t("Name")}</th>
                                        <th>{t("Total")}</th>
                                        <th>{t("Recevied")}</th>
                                        <th>{t("Waiting")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentInbound.items?.map((item, index) => {
                                        return <tr key={index}>
                                            <td className="text-center" style={{ width: "3rem" }}>
                                                <ProductImageDisplay largePreview product={item.product} />
                                            </td>
                                            <td>{item.product.name}</td>
                                            <td>{item.quantity}</td>
                                            <td>{item.received}</td>
                                            <td>{item.quantity === item.received ? <span className="text-success">0</span> : <span className="text-warning">{item.quantity - item.received}</span>}</td>
                                        </tr>;
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </Card>
                </Col>
            </Row>
        </>}
        {!currentInbound && currentCustomer && <>
            <Row>
                <Col md={4}>
                    <Card body>
                        <Table size="sm" borderless>
                            <tbody>
                                <tr>
                                    <th colSpan={2} className="text-center">
                                        {t("Customer")}
                                    </th>
                                </tr>
                                <tr>
                                    <td>{t("Suit No")}</td>
                                    <td><CustomerSuitDisplay suit={currentCustomer?.suitNumber} /></td>
                                </tr>
                                <tr>
                                    <td>{t("Full Name")}</td>
                                    <td>{currentCustomer?.name} {currentCustomer?.surname}</td>
                                </tr>
                                <tr>
                                    <td>{t("Email")}</td>
                                    <td>{currentCustomer?.user.email}</td>
                                </tr>
                                <tr>
                                    <td>{t("Phone")}</td>
                                    <td>{currentCustomer?.phone}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card>
                </Col>
                <Col md={8}>
                    <Card body>
                        <SecondaryAlert className="mb-0" kind="top-border">{t("paragraphs:GateEntryNoInboundWarning")}</SecondaryAlert>
                    </Card>
                </Col>
            </Row>
        </>}
        <Row>
            <Col>
                <div className="hstack gap-2">
                    <Button color="primary" onClick={() => setCreateBoxModal(true)}>{t("Create Box")}</Button>
                    <Button color="success" className="w-xl" disabled={!receivingEntry || receivingEntry.boxes.length === 0}>{t("Print All Boxes")}</Button>
                </div>
            </Col>
        </Row>
        <Row>
            <Col className="g-4">
                <h5>{t("Boxes")}</h5>
                <BusyOverlay busy={boxBusyState || loading.new || loading.load}>
                    {(!receivingEntry?.boxes || receivingEntry.boxes.length === 0) && <>
                        <InfoAlert>{t("No boxes added yet")}</InfoAlert>
                    </>}
                    {(receivingEntry?.boxes && receivingEntry.boxes.length > 0) && <>
                        <Card body>
                            <div className="table-responsive table-card">
                                <Table size="sm" borderless className="align-middle mb-0">
                                    <thead>
                                        <tr>
                                            <th>{t("Action")}</th>
                                            <th>#</th>
                                            <th>{t("Tracking Number")}</th>
                                            <th>{t("Dimentions")}</th>
                                            {/* <th>{t("Weight")}</th> */}
                                            <th>{t("Total Received")}</th>
                                            <th>{t("Total Sellable/Unusable")}</th>
                                            <th>{t("Create Date")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {receivingEntry?.boxes?.map((receivingBox, index) => {
                                            return <React.Fragment key={index}>
                                                <tr>
                                                    <td>
                                                        <div className="hstack gap-2">
                                                            <Link to={`/receivings/${receivingEntry.receivingEntryId}?box=${receivingBox.receivingBoxId}`} className="btn-soft-primary btn btn-sm">{t("GRN")}</Link>
                                                            <Button color="soft-success" size="sm">{t("Print")}</Button>
                                                            <Button color="soft-danger" size="sm" onClick={() => setDeletingBox(receivingBox)}>{t("Remove")}</Button>
                                                        </div>
                                                    </td>
                                                    <td># {receivingBox.box.boxNumber}</td>
                                                    <td>{receivingBox.trackingNumber || "-"}</td>
                                                    <td><Dimensions value={receivingBox.box} unit={receivingEntry?.warehouse.settings.unitOfLength} /></td>
                                                    {/* <td><Weight value={box.weight} unit={receivingEntry?.warehouse.settings.unitOfWeight} /></td> */}
                                                    <td>{_.sumBy(receivingBox.box.contents, c => c.sellableQuantity + c.unusableQuantity)}</td>
                                                    <td>{_.sumBy(receivingBox.box.contents, c => c.sellableQuantity)}/{_.sumBy(receivingBox.box.contents, c => c.unusableQuantity)}</td>
                                                    <td><DateDisplay date={receivingBox.createdAt} /></td>
                                                </tr>
                                                <tr className="table-light">
                                                    <td colSpan={7} className="p-3">
                                                        {receivingBox.box.contents.length > 0 ? <>
                                                            <Table size="sm" className="table-nowrap mb-0 bg-white shadow-lg">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{t("SKU")}</th>
                                                                        <th>{t("ASIN")}</th>
                                                                        <th>{t("Total Received")}</th>
                                                                        <th>{t("Total Sellable")}</th>
                                                                        <th>{t("Total Unusable")}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {receivingBox.box.contents.map((content, index) => <tr key={index}>
                                                                        <td>{content.product.sku}</td>
                                                                        <td>{content.product.asin}</td>
                                                                        <td>{content.unusableQuantity + content.sellableQuantity}</td>
                                                                        <td>{content.sellableQuantity}</td>
                                                                        <td>{content.unusableQuantity}</td>
                                                                    </tr>)}
                                                                </tbody>
                                                            </Table>
                                                        </> : <>
                                                            <div className="text-center text-muted">{t("No products in this box")}</div>
                                                        </>}
                                                    </td>
                                                </tr>
                                            </React.Fragment>;
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </>}
                </BusyOverlay>
            </Col>
        </Row>
        <Row>
            <Col>
                <BusyOverlay busy={documentBusyState}>
                    <Card body>
                        <DocumentUpload kind="receiving" onUpload={addDocument} ref={documentUploadRef} />
                    </Card>
                </BusyOverlay>
            </Col>
        </Row>
        <Row>
            <Col>
                <h5>{t("Documents")}</h5>
                <BusyOverlay busy={loading.new || loading.load}>
                    {(!receivingEntry?.documents || receivingEntry.documents.length === 0) && <>
                        <InfoAlert>{t("No documents added yet")}</InfoAlert>
                    </>}
                    {(receivingEntry?.documents && receivingEntry.documents.length > 0) && <>
                        <Card body>
                            <div className="table-card table-responsive">
                                <Table size="sm" striped borderless className="align-middle mb-0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{t("Type")}</th>
                                            <th>{t("Link")}</th>
                                            <th>{t("Uploaded Date")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {receivingEntry?.documents?.map((document, index) => <tr key={index}>
                                            <td>#{index + 1}</td>
                                            <td>{document.documentTypeName}</td>
                                            <td>
                                                <PrivateDownload href={document.path}>
                                                    {url => <a href={url} className="btn btn-ghost-primary btn-sm">{t("Download")}</a>}
                                                </PrivateDownload>
                                            </td>
                                            <td><DateDisplay date={document.createdAt} /></td>
                                        </tr>)}
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </>}
                </BusyOverlay>
            </Col>
        </Row>
        {deletingBox && <Dialog ref={dialogRef} color="warning" buttons={["yes", "no"]} busy={boxBusyState} iconClass="ri-delete-bin-line"  
            message={`Do you want to continue?`} title={`Deleting box #${deletingBox.box.boxNumber}`}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    removeBox(deletingBox.receivingBoxId).then(() => setDeletingBox(undefined));
                }
                else {
                    setDeletingBox(undefined);
                }
            }} />}
        <Modal backdrop="static" size="lg" isOpen={!boxBusyState && createBoxModal} toggle={toggleCreateBoxModal}>
            <ModalCloseButton onClick={toggleCreateBoxModal} />
            <ModalBody>
                <CreateBoxForm trackings={currentInbound?.trackings} onSave={addBox} />
            </ModalBody>
        </Modal>
    </>;
}

export default Step2;
import { Row, Col, Card, Container, Label, Form } from "reactstrap";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { useTenant } from "Components/Hooks/TenantHooks";
import { withCdn } from "helpers/urlHelper";
import logoLight from "../../assets/images/tyloc/logo.png";
import Copyright from "Components/Copyright";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import TextInput from "Components/Form/TextInput";
import { useMutation } from "@tanstack/react-query";
import { DangerAlert, PrimaryAlert, SuccessAlert } from "Components/Alerts";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import BusyOverlay from "Components/Common/BusyOverlay";
import { postSendRecoveryEmail } from "api/user";

const ForgetPasswordPage = () => {
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const { tenant } = useTenant();

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required(t("Please Enter Your Email")),
        }),
        onSubmit: (values) => {
            sendRecoveryPasswordMutation.mutate(values);
        }
    });

    const sendRecoveryPasswordMutation = useMutation({
        mutationFn: postSendRecoveryEmail,
        mutationKey: ["send-recovery-email"],
        onSuccess: () => {
            
        }
    });

    setPageTitle(t("Forgot Password"));

    return <>
        <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
            <div className="bg-overlay bg-tenant-primary"></div>
            <div className="auth-page-content overflow-hidden">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <Card className="overflow-hidden m-0">
                                <Row>
                                    <Col lg={5} className='p-4'>
                                        <div className="text-center mt-2">
                                            <h2 className="mt-3 fw-medium text-tenant-primary">
                                                {tenant?.name}
                                            </h2>
                                            <h5 className="text-primary">{t("Forgot Password")} ?</h5>
                                            <p className="text-muted">{t("Reset password for {{name}} account", { name: tenant?.name })}.</p>
                                            <i className="ri-mail-send-line display-5 text-success my-4"></i>
                                        </div>
                                        {sendRecoveryPasswordMutation.isError && <DangerAlert>{sendRecoveryPasswordMutation.error.message}</DangerAlert>}
                                        {sendRecoveryPasswordMutation.isSuccess && <SuccessAlert>{t("Your recovery email has been sent to {{email}}", { email: validation.values.email })}</SuccessAlert>}
                                        <div className="p-2 mt-2">
                                            <PrimaryAlert className="text-center">
                                                {t("Enter your email and instructions will be sent to you")}!
                                            </PrimaryAlert>
                                            <Form onSubmit={validation.handleSubmit} action="#">
                                                <div className="mb-3">
                                                    <Label htmlFor="email" className="form-label">{ t("Email") }</Label>
                                                    <ValidationWrapper validation={validation} field="email">
                                                        <TextInput type="text" placeholder={ t("Enter email") } tabIndex={1} />
                                                    </ValidationWrapper>
                                                    
                                                </div>
                                                <div className="mb-3">
                                                    <BusyOverlay busy={sendRecoveryPasswordMutation.isPending} size="sm">
                                                        <ValidatorButton color='success' className='w-100' type='submit' validation={validation} tabIndex={3}>
                                                            {t("Send Reset Link")}
                                                        </ValidatorButton>
                                                    </BusyOverlay>
                                                </div>
                                            </Form>
                                        </div>
                                    </Col>
                                    <Col lg={7}>
                                        <div className="p-lg-5 p-4 auth-one-bg tenant-cover-background h-100">
                                            <div className="bg-overlay bg-tenant-secondary"></div>
                                            <div className="position-relative h-100 d-flex flex-column text-center">
                                                <div className="text-center mt-sm-3 mb-4 text-white-50">
                                                    <div>
                                                        <Link to="/" className="d-inline-block auth-logo">
                                                            <img src={tenant?.logo ? withCdn(tenant.logo) : logoLight} alt="" className='mw-100' style={{ maxHeight: "210px" }} />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <p className="">
                                                    {tenant?.coverPageText}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <footer className="footer">
                <div className="text-center">
                    <Copyright />
                </div>
            </footer>
        </div>
    </>;
};

export default ForgetPasswordPage;

import { useMutation } from '@tanstack/react-query';
import { DangerAlert } from 'Components/Alerts';
import BusyOverlay from 'Components/Common/BusyOverlay';
import RequiredLabel from 'Components/Common/RequiredLabel';
import NumberInput from 'Components/Form/NumberInput';
import TextInput from 'Components/Form/TextInput';
import ValidationWrapper from 'Components/Form/Validated/ValidationWrapper';
import ValidatorButton from 'Components/Form/Validated/ValidatorButton';
import { putAisle } from 'api/aisle';
import { AisleContract, AisleForm } from 'api/types/contracts/locations';
import { useFormik } from 'formik';
import { TypedShape } from 'helpers/types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Col, Form, Label, Row } from 'reactstrap';
import * as Yup from 'yup';

type FormProps = {
    aisle: AisleContract,
    onSuccess: VoidFunction
}

const createAisleForm = (aisle: AisleContract): AisleForm => {
    return {
        name: aisle.name,
        ...aisle.properties
    }
};

const UpdateAisleFormView = (props: FormProps) => {
    const aisle = createAisleForm(props.aisle);
    const { t } = useTranslation();

    const updateAisleMutation = useMutation({
        mutationFn: putAisle,
        mutationKey: ["update-aisle"],
        onSuccess: () => {
            toast.success(t("Aisle updated"));
            props.onSuccess();
        }
    })

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: aisle,
        validationSchema: Yup.object<AisleForm, TypedShape<AisleForm>>({
            name: Yup.string().required(t("Please enter aisle name")),
            height: Yup.number().notRequired(),
            width: Yup.number().notRequired(),
            depth: Yup.number().notRequired(),
        }),
        onSubmit: values => {
            updateAisleMutation.mutate({
                aisleId: props.aisle.aisleId,
                aisle: values
            });
        },
    });
    
    return <>
        <Form className="needs-validation form-steps" action="#" onSubmit={validation.handleSubmit}>
            <div>
                <div className="mb-4">
                    <div>
                        <h5 className="mb-1">{t("Aisle Information")}</h5>
                    </div>
                </div>
                <Row>
                    <Col lg={4} className="g-3">
                        <RequiredLabel htmlFor="name">{t("Aisle Name")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="name">
                            <TextInput placeholder="Enter Aisle Name" />
                        </ValidationWrapper>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="height">{t("Aisle Height ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="height">
                            <NumberInput placeholder="Enter aisle height" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="width">{t("Aisle Width ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="width">
                            <NumberInput placeholder="Enter aisle width" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="depth">{t("Aisle Depth ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="depth">
                            <NumberInput placeholder="Enter aisle depth" />
                        </ValidationWrapper>
                    </Col>
                </Row>
            </div>
            <div className="mt-4">{updateAisleMutation.isError && <DangerAlert>{updateAisleMutation.error.message}</DangerAlert>}</div>
            <div className="d-flex justify-content-end gap-3">
                <BusyOverlay busy={updateAisleMutation.isPending} size="sm">
                    <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label right nexttab">
                        <i className="ri-save-3-line label-icon align-middle fs-16 ms-2"></i>
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </>;
}

export default UpdateAisleFormView;
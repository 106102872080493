import type { UnitOfLength } from "api/types/contracts/common";

type LengthDisplayProps = {
    value: number | undefined | null,
    unit: UnitOfLength
}

const LengthDisplay = (props: LengthDisplayProps) => {
    const { value, unit } = props;

    if (value === undefined || value === null) {
        return <span>-</span>;
    }

    return <span>{value} {unit}</span>;
}

export default LengthDisplay;
import List from "./_List";
import BusyOverlay from "Components/Common/BusyOverlay";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { createAppSelector, useAppSelector } from "Components/Hooks/StoreHooks";
import { useTranslation } from "react-i18next";

const BinLocationsView = () => {
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();

    const { selectedShelf, loading } = useAppSelector(
        createAppSelector([state => state.Location], 
            (location) => {
                const selectedArea = location.areas?.find(a => a.areaId === location.selectedAreaId);
                const selectedAisle = selectedArea?.aisles?.find(a => a.aisleId === location.selectedAisleId);
                const selectedBay = selectedAisle?.bays?.find(b => b.bayId === location.selectedBayId);
                const selectedShelf = selectedBay?.shelves?.find(s => s.shelfId === location.selectedShelfId);

                return {
                    view: location.view,
                    loading: location.loading,
                    selectedArea,
                    selectedAisle,
                    selectedShelf,
                    selectedBay
                };
            }
        )
    );

    setPageTitle(`${(t("Shelf {{shelf}}", { shelf: selectedShelf?.name }))} - ${t("Locations")} - ${t("Management")}`);

    return <>
        <BusyOverlay busy={loading.data} blur>
            {selectedShelf && <List binLocations={selectedShelf.binLocations} />}
        </BusyOverlay>
    </>;
}

export default BinLocationsView;
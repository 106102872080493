import CountryDisplay from "Components/Displays/CountryDisplay";
import CustomerDisplay from "Components/Displays/CustomerDisplay";
import FbaShipmentStatusBadge from "Components/Displays/FbaShipmentStatusBadge";
import { InvoiceContract } from "api/types/contracts/payment";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader, CardTitle, Table } from "reactstrap";
import Currency from "Components/Displays/UnitDisplay/Currency";
import InvoiceStatusBadge from "Components/Displays/InvoiceStatusBadge";
import _ from "lodash";
import { useRef } from "react";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import { payFbaShipmentInvoice } from "slices/shipping/fbaShipping/thunk";
import { useProfile } from "Components/Hooks/ProfileHooks";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";

type ViewProps = {
    invoice: InvoiceContract
}

const View = ({ invoice }: ViewProps) => {
    const dispatch = useAppDispatch();
    const { userProfile } = useProfile();
    const { t } = useTranslation();
    const payConfirmationDialogRef = useRef<DialogRef>(null);

    const { loading } = useAppSelector(
        (state) => ({
            loading: state.FbaShipping.loading
        })
    );

    return <>
        <Card body>
            <dl className="row align-items-center mb-0 g-2">
                {invoice.fbaShipment && <>
                    <dt className="col-2">{t("FBA Shipment ID")}</dt>
                    <dd className="col-2 mb-0"><Link to={`/fba-shipment/${invoice.fbaShipment.fbaShipmentId}`}># {invoice.fbaShipment.shipmentCode}</Link></dd>
                    <dt className="col-2">{t("Status")}</dt>
                    <dd className="col-2 mb-0"><FbaShipmentStatusBadge value={invoice.fbaShipment.status} /></dd>
                    <dt className="col-2">{t("Country")}</dt>
                    <dd className="col-2 mb-0"><CountryDisplay country={invoice.fbaShipment.shipmentTarget.address.country} /></dd>
                </>}

                <dt className="col-2">{t("Customer")}</dt>
                <dd className="col-2 mb-0"><CustomerDisplay customer={invoice.customer} /></dd>
                {invoice.fbaShipment && <>
                    <dt className="col-2">{t("Boxes")}</dt>
                    <dd className="col-2 mb-0">{invoice.fbaShipment.packages ? invoice.fbaShipment.packages.length : invoice.fbaShipment.estimatedBoxes.length}</dd>
                    <dt className="col-2">{t("Carrier")} / {t("Carrier")}</dt>
                    <dd className="col-2 mb-0">{invoice.fbaShipment.carrierAccountService.carrierService.carrier.name} / {invoice.fbaShipment.carrierAccountService.carrierService.name}</dd>
                </>}
                <hr className="my-2" />
                <dt className="col-2">{t("Invoice ID")}</dt>
                <dd className="col-2 mb-0"># {invoice.invoiceCode}</dd>
                <dt className="col-2">{t("Total")}</dt>
                <dd className="col-2 mb-0 d-flex align-items-center gap-2">
                    <Currency currency={invoice.unitOfCurrency} value={invoice.total} />
                    {invoice.customer.user.userId === userProfile?.user.userId && invoice.status === "pending" && <>
                        <Button color="success" className="" size="sm" onClick={() => payConfirmationDialogRef.current?.show()}>{t("Pay")}</Button>
                    </>}
                </dd>
                <dt className="col-2">{t("Payment Status")}</dt>
                <dd className="col-2 mb-0"><InvoiceStatusBadge value={invoice.status} /></dd>
            </dl>
        </Card>
        <Card>
            <CardHeader>
                <CardTitle tag="h5">{t("Shipping Cost")}</CardTitle>
            </CardHeader>
            <CardBody>
                <dl className="row align-items-center mb-0">
                    <dt className="col-3">{t("Shipping Cost")}</dt>
                    <dd className="col-2 mb-0"><Currency currency={invoice.unitOfCurrency} value={invoice.shippingCost} /></dd>
                    <div className="w-100"></div>
                    <dt className="col-3">{t("Import Duty")}</dt>
                    <dd className="col-2 mb-0"><Currency currency={invoice.unitOfCurrency} value={invoice.importCost} /></dd>
                    <div className="w-100"></div>
                    <dt className="col-10 text-end">{t("Total")}</dt>
                    <dd className="col-2 mb-0 fs-5 fw-bold"><Currency currency={invoice.unitOfCurrency} value={invoice.total} /></dd>
                </dl>
            </CardBody>
        </Card>
        <Card>
            <CardHeader>
                <CardTitle tag="h5">{t("Items")}</CardTitle>
            </CardHeader>
            <CardBody>
                <div className="table-responsive table-card">
                    <Table className="mb-0 align-middle" borderless>
                        <thead className="table-light text-muted">
                            <tr>
                                <th>{t("Prep Service")}</th>
                                <th style={{ width: "10%" }}>{t("Unit Price")}</th>
                                <th style={{ width: "10%" }}>{t("Count")}</th>
                                <th style={{ width: "10%" }}>{t("Sub Total")}</th>
                                <th style={{ width: "10%" }}>{t("Discount")}</th>
                                <th style={{ width: "10%" }}>{t("Total")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoice.items.map((item, i) => {
                                return <tr>
                                    <td>{item.serviceName}</td>
                                    <td><Currency currency={invoice.unitOfCurrency} value={item.unitPrice} /></td>
                                    <td>{item.count}</td>
                                    <td><Currency currency={invoice.unitOfCurrency} value={item.count * item.unitPrice} /></td>
                                    <td><Currency currency={invoice.unitOfCurrency} value={item.discount} /></td>
                                    <td><Currency currency={invoice.unitOfCurrency} value={item.total} /></td>
                                </tr>;
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={5} className="text-end">{t("Total")}</th>
                                <th><Currency currency={invoice.unitOfCurrency} value={_.sumBy(invoice.items, "total")} /></th>
                            </tr>
                        </tfoot>
                    </Table>
                </div>
            </CardBody>
        </Card>
        <Card body>
            <div className="mx-auto w-50 vstack gap-3">
                <div className="d-flex justify-content-between p-2">
                    <span>{t("Shipping & Tax")}</span>
                    <span className="fw-bold"><Currency currency={invoice.unitOfCurrency} value={invoice.shippingCost + invoice.importCost} /></span>
                </div>
                <div className="d-flex justify-content-between p-2 border-bottom border-dark">
                    <span>{t("FBA Preps")}</span>
                    <span className="fw-bold"><Currency currency={invoice.unitOfCurrency} value={_.sumBy(invoice.items, "total")} /></span>
                </div>
                <div className="d-flex justify-content-end gap-2 p-2">
                    <span className="fw-bold fs-5">{t("Total")}</span>
                    <span className="fw-bold fs-5"><Currency currency={invoice.unitOfCurrency} value={invoice.total} /></span>
                </div>
            </div>
        </Card>
        <Dialog ref={payConfirmationDialogRef} color="info" buttons={["yes", "no"]} busy={loading.invoicePay} iconClass="ri-money-dollar-circle-line"  
            message={`Do you want to continue?`} title={`You are about to pay the invoice`}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    dispatch(payFbaShipmentInvoice({
                        invoiceId: invoice.invoiceId
                    })).then(() => {
                        hide();
                    });
                }
                else {
                    hide();
                }
            }} />
    </>;
}

export default View;
import { useMutation } from "@tanstack/react-query";
import BusyOverlay from "Components/Common/BusyOverlay";
import TextInput from "Components/Form/TextInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { postQuickAddProduct } from "api/product";
import { ProductContract } from "api/types/contracts/products";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import * as Yup from "yup";

type QuickAddFormProps = {
    onProductsAdded?: (products: ProductContract[]) => void
};

const QuickAddForm = (props: QuickAddFormProps) => {
    const { t } = useTranslation();

    const quickAddMutation = useMutation({
        mutationFn: postQuickAddProduct,
        mutationKey: ["quick-add"],
        onSuccess: (result) => {
            props.onProductsAdded?.(result);
        }
    })
    
    const validation = useFormik({
        initialValues: {
            asinList: ""
        },
        validationSchema: Yup.object({
            asinList: Yup.string().required(t("You need to provide ASIN(s) to add products"))
        }).required(),
        onSubmit: values => {
            const asinList = values.asinList.split("\n").map(a => a.trim()).filter(a => a);
            quickAddMutation.mutate({ asinList });
        }
    })

    return <>
        <Row>
            <Col sm={4}>
                <ValidationWrapper validation={validation} field="asinList">
                    <TextInput type="textarea" style={{ height: "6rem" }} placeholder={"XXXXXXXXXXX\nXXXXXXXXXXX\netc..."} />
                </ValidationWrapper>
            </Col>
            <Col sm={8}>
                <p>
                    {t("paragraphs:InboundFormQuickAddDescription")}
                </p>
            </Col>
            <Col xs={12} className="g-3">
                <BusyOverlay busy={quickAddMutation.isPending} size="sm">
                    <ValidatorButton validation={validation} type="button" color="info" className="btn-block w-100" onClick={() => validation.submitForm()}>
                        {t("Quick Add")}
                    </ValidatorButton>
                </BusyOverlay>
            </Col>
        </Row>
    </>;
}

export default QuickAddForm;
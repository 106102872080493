import { type CreditRequestStatus } from "api/types/contracts/payment";
import { StatusBadgeProps } from "helpers/types";
import { useTranslation } from "react-i18next";
import { Badge } from "reactstrap";

const CreditRequestStatusMap: Record<CreditRequestStatus, { className: string }> = {
    "pending": { className: "bg-warning" },
    "processing": { className: "bg-info" },
    "approved": { className: "bg-success" },
    "rejected": { className: "bg-danger" }
};

const CreditRequestStatusBadge = (props: StatusBadgeProps<CreditRequestStatus>) => {
    const { t } = useTranslation("enums");

    if (props.value === undefined) {
        return <></>;
    }

    const statusName = t(`CreditRequestStatus.${props.value}`);
    const { className } = CreditRequestStatusMap[props.value];

    return <Badge className={className}>{statusName}</Badge>
}

export default CreditRequestStatusBadge;
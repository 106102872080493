import { Container, Modal, ModalBody } from "reactstrap";
import List, { type CreditRequestListRef } from "./_List";
import { useRef } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import type { CreditRequestContract } from "api/types/contracts/payment";
import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import ResolveWrapper from "Components/Common/ResolveWrapper";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import ApproveForm from "./_ApproveForm";
import TransactionHistoryList, { type TransactionHistoryListRef } from "./_TransactionsHistoryList";

type CreditRequestPageProps = {
    edit?: boolean
}

type LoaderData = {
    creditRequest: Promise<CreditRequestContract>
} | undefined;

const CreditRequestPage = (props: CreditRequestPageProps) => {
    const loaderData = useLoaderData() as LoaderData;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const listRef = useRef<CreditRequestListRef>(null);
    const transactionHistoryListRef = useRef<TransactionHistoryListRef>(null);

    return <>
        <div className="page-content">
            <Container fluid>
                <TitleBreadcrumb active={t("Credit Load Requests")} parents={[t("Billing")]} />
                <List ref={listRef} />
                <TransactionHistoryList ref={transactionHistoryListRef} />
                {props.edit && 
                    <Modal backdrop="static" size="md" isOpen toggle={() => navigate("/credit-requests")} unmountOnClose>
                        <ModalBody>
                            <ResolveWrapper promise={loaderData?.creditRequest}>
                                {resolvedCreditRequest => <>
                                    <ModalCloseButton onClick={() => navigate("/credit-requests")} />
                                    <ApproveForm creditRequest={resolvedCreditRequest} onSuccess={() => {
                                        navigate("/credit-requests");
                                        listRef.current?.reload();
                                        transactionHistoryListRef.current?.reload();
                                    }} />
                                </>}
                            </ResolveWrapper>
                        </ModalBody>
                    </Modal>
                }
            </Container>
        </div>
    </>;
}

export default CreditRequestPage;
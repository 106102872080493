import { InfoAlert } from "Components/Alerts";
import Loader from "Components/Common/Loader";
import { useAppSelector } from "Components/Hooks/StoreHooks";
import ShipmentPackageView, { PackageItemColors } from "Components/Shared/ShipmentPackageView";
import _ from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

type ViewPackagesProps = {
    
}

const ViewPackages = (props: ViewPackagesProps) => {
    const { t } = useTranslation();
    const { fbaShipment, loading } = useAppSelector(
        (state) => ({
            fbaShipment: state.FbaShipping.processingItem,
            loading: state.FbaShipping.loading
        })
    );

    const productColorCodes = useMemo(() => _.chain(fbaShipment?.items ?? []).map((item, i) => [item.product.productId, PackageItemColors[i % PackageItemColors.length]]).uniqWith(_.isEqual).fromPairs().value(), [fbaShipment?.items]);
    
    if (!fbaShipment) {
        return <Loader height="250px" />;
    }

    return <>
        <Card>
            <CardHeader>
                <CardTitle tag="h5" className="mb-0">{t("Packages")}</CardTitle>
            </CardHeader>
            <CardBody>
                {["boxLabelPending", "paymentWaiting", "paymentCompleted", "shipped"].includes(fbaShipment.status) ? <>
                    <ShipmentPackageView colorCodes={productColorCodes} packedBoxes={fbaShipment.packages.map(p => p.box)} />
                </> : <>
                    <ShipmentPackageView colorCodes={productColorCodes} packedBoxes={fbaShipment.estimatedBoxes} />
                    <InfoAlert className="mb-0 mt-2">
                        {t("paragraphs:EstimatedPackageDescription")}
                    </InfoAlert>
                </>}
            </CardBody>
        </Card>
    </>;
}

export default ViewPackages;
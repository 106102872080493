import { InfoAlert } from "Components/Alerts";
import Loader from "Components/Common/Loader";
import PrivateDownload from "Components/Common/PrivateDownload";
import { useAppSelector } from "Components/Hooks/StoreHooks";
import { dotDotDot } from "helpers/string";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader, CardTitle, Table } from "reactstrap";

type DocumentListProps = {
    
}

const DocumentList = (props: DocumentListProps) => {
    const { t } = useTranslation();

    const { fbaShipment } = useAppSelector(
        (state) => ({
            fbaShipment: state.FbaShipping.processingItem,
        })
    );

    if (!fbaShipment) {
        return <Loader height="50px" />;
    }

    return <Card>
        <CardHeader>
            <CardTitle tag="h5" className="mb-0">{t("Documents")}</CardTitle>
        </CardHeader>
        <CardBody>
            {fbaShipment.documents.length > 0 ? <>
                <div className="table-responsive table-card">
                    <Table className="mb-0 align-middle">
                        <thead className="table-light text-muted">
                            <tr>
                                <th>{t("Action")}</th>
                                <th>{t("File name")}</th>
                                <th>{t("Type")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fbaShipment.documents.map((document, index) => <tr key={index}>
                                <td>
                                    <Button color="info" className="btn-icon" size="sm">
                                        <i className="ri-printer-fill"></i>
                                    </Button>
                                </td>
                                <td>
                                    <PrivateDownload href={document.path}>
                                        {url => <a href={url} target="_blank" rel="noreferrer">
                                            {document.path.split("/").at(-1)}
                                        </a>}
                                    </PrivateDownload>
                                </td>
                                <td>
                                    {document.documentTypeName}
                                </td>
                            </tr>)}
                        </tbody>
                    </Table>
                </div>
            </> : <>
                <InfoAlert className="mb-0">
                    {t("No documents found")}
                </InfoAlert>
            </>}
        </CardBody>
    </Card>;
}

export default DocumentList;
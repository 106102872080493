import { useQuery } from "@tanstack/react-query";
import SelectInput from "Components/Form/SelectInput"
import { WarehouseContract } from "api/types/contracts/warehouses";
import { ListWarehousesForSelectQuery } from "api/types/queries";
import { getWarehouseListForSelect } from "api/warehouse";
import { EntitySelectInputProps } from "helpers/types";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useImmer } from "use-immer";

type WarehouseSelectProps = EntitySelectInputProps<WarehouseContract, "warehouseId"> & {
    companyId?: string,
    fullName?: boolean
}

const WarehouseSelect = ({ isMulti, value, onChange, onSelect, name, companyId, fullName, ...rest }: WarehouseSelectProps) => {
    const { t } = useTranslation();
    const [query, updateQuery] = useImmer<ListWarehousesForSelectQuery>({
        companyId: companyId,
        search: ""
    });

    const { data: warehouses, isFetching: loading } = useQuery({
        queryKey: ["warehouse-select-list", query],
        queryFn: () => getWarehouseListForSelect(query)
    });

    const warehouseList = useMemo(() => (warehouses ? warehouses.map(c => ({ 
        value: c.warehouseId, 
        label: fullName ? `${c.name} - ${c.address.address1} ${c.address.city} ${c.address.state}` : c.name
    })) : []), [warehouses, fullName]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = warehouses?.filter(w => val.includes(w.warehouseId));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = warehouses?.find(s => s.warehouseId === val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.warehouseId);
                return;
            }
        }

        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        if (!value) {
            return;
        }

        if (!warehouses || warehouses.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouses]);

    useEffect(() => {
        updateQuery(q => {
            if (companyId) {
                q.companyId = companyId;
            }
        });
    }, [updateQuery, companyId]);
    
    const onSearch = useCallback((val?: string) => {
        updateQuery(q => {
            q.search = val;
        });
    }, [updateQuery]);

    const selectProps = {
        ...rest,
        placeholder: rest.placeholder ?? t("Warehouse"),
        busy: loading,
        options: warehouseList,
        showClear: true,
        onSearch: onSearch
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default WarehouseSelect;
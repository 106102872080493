import { useMutation } from "@tanstack/react-query";
import BusyOverlay from "Components/Common/BusyOverlay";
import RequiredLabel from "Components/Common/RequiredLabel";
import PrepServiceSelect from "Components/EntitySelects/PrepServiceSelect";
import Checkbox from "Components/Form/Checkbox";
import NumberInput from "Components/Form/NumberInput";
import TextInput from "Components/Form/TextInput";
import CurrencyInput from "Components/Form/UnitInputs/CurrencyInput";
import LengthInput from "Components/Form/UnitInputs/LengthInput";
import WeightInput from "Components/Form/UnitInputs/WeightInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import { useCommon } from "Components/Hooks/CommonHooks";
import { postQuickAddProduct } from "api/product";
import { FbaShipmentFormItem } from "api/types/commands";
import type { UnitOfCurrency, UnitOfLength, UnitOfWeight } from "api/types/contracts/common";
import { ProductContract } from "api/types/contracts/products";
import { useFormik } from "formik";
import { inputKeyEventHandler } from "helpers/componentHelpers";
import { TypedShape } from "helpers/types";
import { convertLength, convertWeight } from "helpers/unitConversions";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

const defaultSplitProduct: FbaShipmentFormItem["splitProduct"] = {
    productId: undefined,
    asin: "",
    name: "",
    count: 1,
    totalItemCount: 1,
    splittedItemCount: 1
};

const defaultItem: FbaShipmentFormItem = {
    productId: "",
    asin: "",
    name: "",
    count: 1,
    buyPrice: 0,
    weight: 0,
    length: 0,
    width: 0,
    height: 0,
    prepServices: [],
    isBundle: false,
    isSplit: false,
    bundleProducts: [],
    splitProduct: undefined
}

type FbaShipmentItemsFormProps = {
    value?: FbaShipmentFormItem[],
    warehouseId?: string,
    onChange?: (items: FbaShipmentFormItem[]) => void,
    existingProducts?: string[],
    unitOfCurrency: UnitOfCurrency,
    unitOfLength: UnitOfLength,
    unitOfWeight: UnitOfWeight,
    size?: "sm" | "lg"
}

const FbaShipmentItemsForm = (props: FbaShipmentItemsFormProps) => {
    const { t } = useTranslation();
    const { calculateExchange } = useCommon();

    const [foundProducts, setFoundProducts] = useState<ProductContract[]>([]);

    const validation = useFormik({
        initialValues: props.value || [defaultItem],
        validationSchema: Yup.array<FbaShipmentFormItem>().of(Yup.object<FbaShipmentFormItem, TypedShape<FbaShipmentFormItem>>({
            productId: Yup.string().notRequired(),
            asin: Yup.string().required(t("You need to enter an ASIN")),
            name: Yup.string().required(t("You need to enter a name")),
            count: Yup.number().required(t("You need to enter a count")),
            buyPrice: Yup.number().required(t("You need to enter a buy price")),
            weight: Yup.number().required(t("You need to enter a weight")),
            length: Yup.number().required(t("You need to enter a length")),
            width: Yup.number().required(t("You need to enter a width")),
            height: Yup.number().required(t("You need to enter a height")),
            prepServices: Yup.array().of(Yup.string().required()).required(),
            isBundle: Yup.boolean().required(),
            isSplit: Yup.boolean().required(),
            bundleProducts: Yup.array().of(Yup.object({
                productId: Yup.string().notRequired(),
                asin: Yup.string().required(t("You need to enter an ASIN")),
                name: Yup.string().required(t("You need to enter a name")),
                count: Yup.number().required(t("You need to enter a count"))
            })).when("isBundle", ([isBundle], schema) => isBundle ? schema.min(1, t("You need to add at least one bundle item")) : schema).required(),
            splitProduct: Yup.object({
                productId: Yup.string().notRequired(),
                asin: Yup.string().required(t("You need to enter an ASIN")),
                name: Yup.string().required(t("You need to enter a name")),
                count: Yup.number().required(t("You need to enter a count")),
                totalItemCount: Yup.number().required(t("You need to enter a total item count")),
                splittedItemCount: Yup.number().required(t("You need to enter a splitted item count"))
            }).default(undefined).when("isSplit", ([isSplit], schema) => isSplit ? schema.required() : schema.notRequired())
        })).required().min(1, t("You need to add at least one product")),
        onSubmit: values => {

        }
    });

    const retrieveItemMutation = useMutation({
        mutationFn: async ({ i }: {i: number}) => {
            const item = validation.values![i];

            if (!item.asin) {
                return;
            }

            return await postQuickAddProduct({ asinList: [item.asin] });
        },
        onSuccess: (products, { i }) => {
            const item = validation.values![i];

            if (products) {
                const [found] = products;
                setFoundProducts(_.uniqBy([...foundProducts, found], "productId"));
    
                item.productId = found.productId;
                item.asin = found.asin || "";
                item.name = found.name;
                item.fnsku = found.fnsku;
                item.buyPrice = calculateExchange(found.buyPrice, found.options.unitOfCurrency, props.unitOfCurrency);
                item.weight = convertWeight(found.weight, found.options.unitOfWeight, props.unitOfWeight);
                item.length = convertLength(found.length, found.options.unitOfLength, props.unitOfLength);
                item.width = convertLength(found.width, found.options.unitOfLength, props.unitOfLength);
                item.height = convertLength(found.height, found.options.unitOfLength, props.unitOfLength);

                validation.setFieldValue(`${i}`, item);
            }
        }
    });

    const retrieveSplitProductMutation = useMutation({
        mutationFn: async ({ i }: {i: number}) => {
            const item = validation.values![i];

            if (!item.splitProduct?.asin) {
                return;
            }

            return await postQuickAddProduct({ asinList: [item.splitProduct.asin] });
        },
        onSuccess: (products, { i }) => {
            const item = validation.values![i];

            if (products && item.splitProduct) {
                const [found] = products;
                setFoundProducts(_.uniqBy([...foundProducts, found], "productId"));
    
                item.splitProduct.productId = found.productId;
                item.splitProduct.asin = found.asin || "";
                item.splitProduct.name = found.name;

                validation.setFieldValue(`${i}`, item);
            }
        }
    });

    const retrieveBundleProductMutation = useMutation({
        mutationFn: async ({ i, j }: {i: number, j: number}) => {
            const item = validation.values![i];
            const bundleProduct = item.bundleProducts[j];

            if (!bundleProduct.asin) {
                return;
            }

            return await postQuickAddProduct({ asinList: [bundleProduct.asin] });
        },
        onSuccess: (products, { i, j }) => {
            const item = validation.values![i];
            const bundleProduct = item.bundleProducts[j];

            if (products) {
                const [found] = products;
                setFoundProducts(_.uniqBy([...foundProducts, found], "productId"));
    
                bundleProduct.productId = found.productId;
                bundleProduct.asin = found.asin || "";
                bundleProduct.name = found.name;

                validation.setFieldValue(`${i}.bundleProducts.${j}`, bundleProduct);
            }
        }
    });

    useEffect(() => {
        if (validation.isValid && validation.dirty) {
            props.onChange?.(validation.values);
        }
    }, [validation.values]);

    const removeItem = (i: number) => {
        const newItems = [...validation.values];
        newItems.splice(i, 1);
        validation.setValues(newItems);
    }

    const addItem = () => {
        const newItems = [...validation.values];
        newItems.push(defaultItem);
        validation.setValues(newItems);
    }

    const addBundleProduct = (i: number) => {
        const newBundleProducts = [...validation.values[i].bundleProducts];
        newBundleProducts.push({
            asin: "",
            name: "",
            count: 1
        });
        
        validation.setFieldValue(`${i}.bundleProducts`, newBundleProducts);
    }

    const removeBundleProduct = (i: number, j: number) => {
        const newBundleProducts = [...validation.values[i].bundleProducts];
        newBundleProducts.splice(j, 1);
        validation.setFieldValue(`${i}.bundleProducts`, newBundleProducts);
    }

    const recalculateSplitItemCount = (i: number, count: number | undefined, totalItemCount: number | undefined, splittedItemCount: number | undefined) => {
        const item = validation.values[i];

        if (!item.splitProduct) {
            return;
        }

        item.count = Math.ceil((count ?? 1) * (totalItemCount ?? 1) / (splittedItemCount ?? 1));

        validation.setFieldValue(`${i}`, item);
    }

    return <>
        {validation.values.map((item, i) => {
            const product = foundProducts.find(p => p.productId === item.productId);

            return <div key={i}>
                <Card body className="border">
                    <div className="position-relative">
                        <div className="position-absolute top-0 end-0" style={{
                            transform: "translate(50%, -50%)"
                        }}>
                            <Button size="sm" color="ghost-danger" disabled={validation.values.length <= 1} className="btn-icon" onClick={() => removeItem(i)}>
                                <i className="ri-close-fill fs-2" />
                            </Button>
                        </div>
                        <div className="pe-3">
                            <Row className="mb-3 g-3">
                                <Col lg={3} md={6}>
                                    <RequiredLabel for={`${i}.asin`}>{t("ASIN")}</RequiredLabel>
                                    <BusyOverlay busy={retrieveItemMutation.isPending && retrieveItemMutation.variables.i === i} size="sm">
                                        <ValidationWrapper validation={validation} field={`${i}.asin`}>
                                            <TextInput placeholder={t("Enter ASIN")} 
                                                onBlur={() => retrieveItemMutation.mutate({ i })} 
                                                onKeyDown={inputKeyEventHandler("Enter", () => retrieveItemMutation.mutate({ i }))}
                                                size={props.size} />
                                        </ValidationWrapper>
                                    </BusyOverlay>
                                </Col>
                                <Col lg={3} md={6}>
                                    <RequiredLabel for={`${i}.name`}>{t("Product Name")}</RequiredLabel>
                                    <ValidationWrapper validation={validation} field={`${i}.name`}>
                                        <TextInput size={props.size} />
                                    </ValidationWrapper>
                                </Col>
                                <Col lg={1} md={2} xs={4}>
                                    <RequiredLabel for={`${i}.count`}>{t("Count")}</RequiredLabel>
                                    <ValidationWrapper validation={validation} field={`${i}.count`}>
                                        <NumberInput readOnly={item.isSplit} size={props.size} />
                                    </ValidationWrapper>
                                </Col>
                                <Col lg={1} md={2} xs={4}>
                                    <RequiredLabel for={`${i}.buyPrice`}>{t("Buy Price")}</RequiredLabel>
                                    <ValidationWrapper validation={validation} field={`${i}.buyPrice`}>
                                        <CurrencyInput size={props.size} currency={props.unitOfCurrency} />
                                    </ValidationWrapper>
                                </Col>
                                <Col lg={1} md={2} xs={4}>
                                    <RequiredLabel for={`${i}.width`}>{t("Width")}</RequiredLabel>
                                    <ValidationWrapper validation={validation} field={`${i}.width`}>
                                        <LengthInput size={props.size} unit={props.unitOfLength} />
                                    </ValidationWrapper>
                                </Col>
                                <Col lg={1} md={2} xs={4}>
                                    <RequiredLabel for={`${i}.height`}>{t("Height")}</RequiredLabel>
                                    <ValidationWrapper validation={validation} field={`${i}.height`}>
                                        <LengthInput size={props.size} unit={props.unitOfLength} />
                                    </ValidationWrapper>
                                </Col>
                                <Col lg={1} md={2} xs={4}>
                                    <RequiredLabel for={`${i}.length`}>{t("Length")}</RequiredLabel>
                                    <ValidationWrapper validation={validation} field={`${i}.length`}>
                                        <LengthInput size={props.size} unit={props.unitOfLength} />
                                    </ValidationWrapper>
                                </Col>
                                <Col lg={1} md={2} xs={4}>
                                    <RequiredLabel for={`${i}.weight`}>{t("Weight")}</RequiredLabel>
                                    <ValidationWrapper validation={validation} field={`${i}.weight`}>
                                        <WeightInput size={props.size} unit={props.unitOfWeight} />
                                    </ValidationWrapper>
                                </Col>
                            </Row>
                            <div className="hstack gap-3 mb-3">
                                <ValidationWrapper validation={validation} field={`${i}.isBundle`}>
                                    <Checkbox label={t("Is Bundle")} disabled={item.isSplit} onClick={() => {
                                        if (item.isBundle) {
                                            item.bundleProducts = [];
                                        }
                                        else {
                                            addBundleProduct(i);
                                        }
                                    }} />
                                </ValidationWrapper>
                                <ValidationWrapper validation={validation} field={`${i}.isSplit`}>
                                    <Checkbox label={t("Is Split")} disabled={item.isBundle} onClick={() => {
                                        if (item.isSplit) {
                                            item.splitProduct = undefined;
                                        }
                                        else {
                                            item.splitProduct = defaultSplitProduct;
                                        }
                                    }} />
                                </ValidationWrapper>
                            </div>
                            {item.isBundle && <div className="mb-3">
                                <h6 className="fw-bold">{t("Bundle Products")}</h6>
                                <div className="vstack gap-3">
                                    {item.bundleProducts.map((bundle, j) => <div key={j} className="hstack gap-3 px-3 py-1 border rounded align-content-center">
                                        <RequiredLabel for={`${i}.bundleProducts.${j}.asin`} className="flex-shrink-0">{t("ASIN")}</RequiredLabel>
                                        <BusyOverlay busy={retrieveBundleProductMutation.isPending && retrieveBundleProductMutation.variables.i === i && retrieveBundleProductMutation.variables.j === j} size="sm">
                                            <ValidationWrapper validation={validation} field={`${i}.bundleProducts.${j}.asin`}>
                                                <TextInput size={props.size} style={{ flex: "2" }} 
                                                    placeholder={t("Enter ASIN")} 
                                                    onBlur={() => retrieveBundleProductMutation.mutate({ i, j })}
                                                    onKeyDown={inputKeyEventHandler("Enter", () => retrieveBundleProductMutation.mutate({ i, j }))} 
                                                />
                                            </ValidationWrapper>
                                        </BusyOverlay>
                                        <RequiredLabel for={`${i}.bundleProducts.${j}.name`} className="flex-shrink-0">{t("Name")}</RequiredLabel>
                                        <ValidationWrapper validation={validation} field={`${i}.bundleProducts.${j}.name`}>
                                            <TextInput size={props.size} style={{ flex: "4" }} />
                                        </ValidationWrapper>
                                        <RequiredLabel for={`${i}.bundleProducts.${j}.count`} className="flex-shrink-0">{t("Count")}</RequiredLabel>
                                        <ValidationWrapper validation={validation} field={`${i}.bundleProducts.${j}.count`}>
                                            <NumberInput size={props.size} style={{ flex: "1" }} />
                                        </ValidationWrapper>
                                        <Label className="flex-shrink-0 mb-0">{t("Total Needed")}</Label>
                                        <NumberInput size={props.size} style={{ flex: "1" }} readOnly value={item.count * bundle.count} />
                                        <Button color="ghost-danger" className="btn-icon" disabled={item.bundleProducts.length <= 1} size="sm" onClick={() => removeBundleProduct(i, j)}>
                                            <i className="ri-close-fill fs-4" />
                                        </Button>
                                    </div>)}
                                    <div>
                                        <Button color="ghost-info" size="sm" onClick={() => addBundleProduct(i)}>{t("Add Another")}</Button>
                                    </div>
                                </div>
                            </div>}
                            {item.isSplit && <div className="mb-3">
                                <h6 className="fw-bold">{t("Split Product")}</h6>
                                <div className="hstack gap-3 px-3 py-1 border rounded">
                                    <RequiredLabel for={`${i}.splitProduct.asin`} className="flex-shrink-0">{t("ASIN")}</RequiredLabel>
                                    <BusyOverlay busy={retrieveSplitProductMutation.isPending && retrieveSplitProductMutation.variables.i === i} size="sm">
                                        <ValidationWrapper validation={validation} field={`${i}.splitProduct.asin`}>
                                            <TextInput size={props.size} style={{ flex: "2" }} placeholder={t("Enter ASIN")} 
                                                onBlur={() => retrieveSplitProductMutation.mutate({ i })}
                                                onKeyDown={inputKeyEventHandler("Enter", () => retrieveSplitProductMutation.mutate({ i }))} 
                                            />
                                        </ValidationWrapper>
                                    </BusyOverlay>
                                    <RequiredLabel for={`${i}.splitProduct.name`} className="flex-shrink-0">{t("Name")}</RequiredLabel>
                                    <ValidationWrapper validation={validation} field={`${i}.splitProduct.name`}>
                                        <TextInput size={props.size} style={{ flex: "3" }} />
                                    </ValidationWrapper>
                                    <RequiredLabel for={`${i}.splitProduct.count`} className="flex-shrink-0">{t("Count")}</RequiredLabel>
                                    <ValidationWrapper validation={validation} field={`${i}.splitProduct.count`}>
                                        <NumberInput size={props.size} style={{ flex: "1" }} onChange={val => recalculateSplitItemCount(i, val, item.splitProduct?.totalItemCount, item.splitProduct?.splittedItemCount)} />
                                    </ValidationWrapper>
                                    <RequiredLabel for={`${i}.splitProduct.totalItemCount`} className="flex-shrink-0">{t("Total Item Count")}</RequiredLabel>
                                    <ValidationWrapper validation={validation} field={`${i}.splitProduct.totalItemCount`}>
                                        <NumberInput size={props.size} style={{ flex: "1" }} onChange={val => recalculateSplitItemCount(i, item.splitProduct?.count, val, item.splitProduct?.splittedItemCount)} />
                                    </ValidationWrapper>
                                    <RequiredLabel for={`${i}.splitProduct.splittedItemCount`} className="flex-shrink-0">{t("Splitted Item Count")}</RequiredLabel>
                                    <ValidationWrapper validation={validation} field={`${i}.splitProduct.splittedItemCount`}>
                                        <NumberInput size={props.size} min={1} style={{ flex: "1" }} onChange={val => recalculateSplitItemCount(i, item.splitProduct?.count, item.splitProduct?.totalItemCount, val)} />
                                    </ValidationWrapper>
                                </div>
                            </div>}
                            <div>
                                <h6 className="fw-bold">{t("Options")}</h6>
                                <ValidationWrapper validation={validation} field={`${i}.prepServices`}>
                                    <PrepServiceSelect productOptionsOnly channel="fba" checkboxes isMulti warehouseId={props.warehouseId} />
                                </ValidationWrapper>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>;
        })}
        <div>
            <Button color="ghost-primary" onClick={addItem}>{t("Add Another")}</Button>
        </div>
    </>
};

export default FbaShipmentItemsForm;
import BusyOverlay from "Components/Common/BusyOverlay";
import RequiredLabel from "Components/Common/RequiredLabel";
import UnitOfCurrencySelect from "Components/EnumSelects/UnitOfCurrencySelect";
import UnitOfLengthSelect from "Components/EnumSelects/UnitOfLengthSelect";
import TextInput from "Components/Form/TextInput";
import CurrencyInput from "Components/Form/UnitInputs/CurrencyInput";
import LengthInput from "Components/Form/UnitInputs/LengthInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { useProfile } from "Components/Hooks/ProfileHooks";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { CarrierOversizeRuleContract, CarrierOversizeRuleForm } from "api/types/contracts/carrier";
import { RangeValueDefinitionContract, UnitOfCurrency, UnitOfLength } from "api/types/contracts/common";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Container, Form, Row } from "reactstrap";
import { createOversizeRule, updateOversizeRule } from "slices/carrierRules/thunk";
import * as Yup from "yup";

type FormProps = {
    oversizeRule?: CarrierOversizeRuleContract,
    onSuccess: VoidFunction
}

const defaultForm: Partial<CarrierOversizeRuleForm> = {
    name: "",
    currencyCode: undefined,
    girthRanges: [],
    lengthRanges: [],
    unitOfLength: undefined
};

const createOversizeRuleForm = (rule: CarrierOversizeRuleContract | undefined): CarrierOversizeRuleForm | undefined => {
    return rule ? {
        name: rule.name,
        currencyCode: rule.currencyCode,
        girthRanges: [...rule.girthRanges],
        lengthRanges: [...rule.lengthRanges],
        unitOfLength: rule.unitOfLength
    } as CarrierOversizeRuleForm : undefined
};

const EditForm = (props: FormProps) => {
    const dispatch = useAppDispatch();
    const { setPageTitle } = useMetadata();
    const { t } = useTranslation();
    const { userProfile } = useProfile();
    const ruleForm = useMemo(() => createOversizeRuleForm(props.oversizeRule) || defaultForm, [props.oversizeRule]);

    setPageTitle(`${t(props.oversizeRule ? "Update Oversize Rule" : "Create Oversize Rule")} - ${t("Carrier Rules")} - ${t("Management")}`);

    const { error, loading } = useAppSelector(
        (state) => ({
            error: state.CarrierRules.error,
            loading: state.CarrierRules.loading.oversize
        })
    );
    
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...ruleForm,
            unitOfLength: ruleForm.unitOfLength ?? userProfile?.user.warehouse?.settings.unitOfLength,
            currencyCode: ruleForm.currencyCode ?? userProfile?.user.warehouse?.settings.unitOfCurrency
        },
        validationSchema: Yup.object<CarrierOversizeRuleForm, TypedShape<CarrierOversizeRuleForm>>({
            name: Yup.string().required(t("Name is required")),
            currencyCode: Yup.string<UnitOfCurrency>().required(t("Currency is required")),
            unitOfLength: Yup.string<UnitOfLength>().required(t("Unit of length is required")),
            girthRanges: Yup.array<RangeValueDefinitionContract<number>>().of(Yup.object({
                from: Yup.number().required(t("Lower bound is required")),
                to: Yup.number().required(t("Upper bound is required")),
                value: Yup.number().required(t("Price is required"))
            })).test("overlapCheck", t("Ranges must not overlap"), (val, { schema }) => {
                if (!val) {
                    return true;
                }

                for (let i = 0; i < val.length; i++) {
                    const range = val[i];
                    for (let j = i + 1; j < val.length; j++) {
                        const otherRange = val[j];
                        if ((range.from >= otherRange.from && range.from <= otherRange.to) 
                            || (range.to >= otherRange.from && range.to <= otherRange.to)) {
                            return false;
                        }
                    }
                }

                return true;
            }).required(),
            lengthRanges: Yup.array<RangeValueDefinitionContract<number>>().of(Yup.object({
                from: Yup.number().required(t("Lower bound is required")),
                to: Yup.number().required(t("Upper bound is required")),
                value: Yup.number().required(t("Price is required"))
            })).min(1, t("At least one range must be defined")).test("overlapCheck", t("Ranges must not overlap"),(val, { schema }) => {
                if (!val) {
                    return true;
                }

                for (let i = 0; i < val.length; i++) {
                    const range = val[i];
                    for (let j = i + 1; j < val.length; j++) {
                        const otherRange = val[j];
                        if ((range.from >= otherRange.from && range.from <= otherRange.to) 
                            || (range.to >= otherRange.from && range.to <= otherRange.to)) {
                            return false;
                        }
                    }
                }

                return true;
            }).required()
        }),
        onSubmit: async values => {
            const carrierOversizeRule = values as CarrierOversizeRuleForm;

            if (props.oversizeRule?.carrierOversizeRuleId) {
                const success = await dispatch(updateOversizeRule({ 
                        carrierOversizeRuleId: props.oversizeRule.carrierOversizeRuleId, 
                        carrierOversizeRule 
                }));

                if (success) {
                    props.onSuccess();
                }
            }
            else {
                const result = await dispatch(createOversizeRule({
                    carrierOversizeRule
                }));

                if (result) {
                    props.onSuccess();
                }
            }
        },
    });

    const addNewGirthRangeRow = () => {
        validation.setFieldValue("girthRanges", [...(validation.values.girthRanges || []), {
            from: 0,
            to: 0
        }]);
    };

    const removeGirthRangeRow = (i: number) => {
        const newRanges = validation.values.girthRanges?.filter((_, index) => index !== i);
        validation.setFieldValue("girthRanges", newRanges);
    }

    const addNewLengthRangeRow = () => {
        validation.setFieldValue("lengthRanges", [...(validation.values.lengthRanges || []), {
            from: 0,
            to: 0
        }]);
    };

    const removeLengthRangeRow = (i: number) => {
        const newRanges = validation.values.lengthRanges?.filter((_, index) => index !== i);
        validation.setFieldValue("lengthRanges", newRanges);
    }

    useEffect(() => {
        if (ruleForm.girthRanges?.length === 0) {
            addNewGirthRangeRow();
        }

        if (ruleForm.lengthRanges?.length === 0) {
            addNewLengthRangeRow();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ruleForm]);

    return <>
        <Form className="needs-validation" action="#" onSubmit={validation.handleSubmit}>
            <Container fluid>
                <Row>
                    <Col className="mb-2">
                    <h4>{t(props.oversizeRule ? "Edit Oversize Rule" : "Create Oversize Rule")}</h4>
                        <p className="mb-0 fs-10">{t("paragraphs:CarrierRulesOversizeRuleDescription")}</p>
                    </Col>
                </Row>
                <Row className="g-2 mb-2">
                    <Col xs={12}>
                        <RequiredLabel>{t("Rule Name")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="name">
                            <TextInput />
                        </ValidationWrapper>
                    </Col>
                    <Col xs={12}>
                        <RequiredLabel>{t("Currency")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="currencyCode">
                            <UnitOfCurrencySelect />
                        </ValidationWrapper>
                    </Col>
                    <Col xs={12} className="mb-2">
                        <RequiredLabel>{t("Unit")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="unitOfLength">
                            <UnitOfLengthSelect />
                        </ValidationWrapper>
                    </Col>
                </Row>
                <Row className="g-2 mb-2">
                    <Col className="vstack gap-2">
                        <div className="label-separator my-2 label-seperator-2">
                            <span className="label">{t("Longest Side")}</span>
                        </div>
                        <div className="hstack gap-3 text-center">
                            <div style={{ flex: 4 }} className="fw-semibold small">
                                {t("Size Range")}
                            </div>
                            <div style={{ flex: 4 }} className="fw-semibold small">
                                {t("Oversize Fee")}
                            </div>
                        </div>
                        {validation.values.lengthRanges?.map((range, i) => <React.Fragment key={i}>
                            <div className="hstack gap-3 align-items-center" key={i}>
                                <span className="small text-muted">{t("from")}</span>
                                <div style={{ flex: 2 }}>
                                    <ValidationWrapper validation={validation} field={`lengthRanges.${i}.from`}>
                                        <LengthInput size="sm" placeholder="From" tabIndex={3 * i + 1}  className="text-center" unit={validation.values.unitOfLength} />
                                    </ValidationWrapper>
                                </div>
                                <span className="small text-muted">{t("to")}</span>
                                <div style={{ flex: 2 }}>
                                    <ValidationWrapper validation={validation} field={`lengthRanges.${i}.to`}>
                                        <LengthInput size="sm" placeholder="To" tabIndex={3 * i + 2}  className="text-center" unit={validation.values.unitOfLength} />
                                    </ValidationWrapper>
                                </div>
                                <span className="small text-muted">{t("then")}</span>
                                <div style={{ flex: 3 }}>
                                    <ValidationWrapper validation={validation} field={`lengthRanges.${i}.value`}>
                                        <CurrencyInput size="sm" placeholder="Price" tabIndex={3 * i + 3} currency={validation.values.currencyCode ?? "usd"} />
                                    </ValidationWrapper>
                                </div>
                                <div style={{ flex: 0 }}>
                                    <Button size="sm" color="ghost-danger" className="btn-icon" onClick={() => removeLengthRangeRow(i)}>
                                        <i className="ri-delete-bin-5-fill"></i>
                                    </Button>
                                </div>
                            </div>
                        </React.Fragment>)}
                        <div className="d-flex justify-content-center">
                            <Button size="sm" type="button" className="btn-ghost-info" onClick={addNewLengthRangeRow}>
                                {t("Add another")}
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row className="g-2 mb-2">
                    <Col className="vstack gap-2">
                        <div className="label-separator my-2 label-seperator-2">
                            <span className="label">{t("Longest Side + Girth")}</span>
                        </div>
                        <div className="hstack gap-3 text-center">
                            <div style={{ flex: 4 }} className="fw-semibold small">
                                {t("Size Range")}
                            </div>
                            <div style={{ flex: 4 }} className="fw-semibold small">
                                {t("Oversize Fee")}
                            </div>
                        </div>
                        {validation.values.girthRanges?.map((range, i) => <React.Fragment key={i}>
                            <div className="hstack gap-3 align-items-center" key={i}>
                                <span className="small text-muted">{t("from")}</span>
                                <div style={{ flex: 2 }}>
                                    <ValidationWrapper validation={validation} field={`girthRanges.${i}.from`}>
                                        <LengthInput size="sm" placeholder="From" tabIndex={3 * i + 30} className="text-center" unit={validation.values.unitOfLength} />
                                    </ValidationWrapper>
                                </div>
                                <span className="small text-muted">{t("to")}</span>
                                <div style={{ flex: 2 }}>
                                    <ValidationWrapper validation={validation} field={`girthRanges.${i}.to`}>
                                        <LengthInput size="sm" placeholder="To" tabIndex={3 * i + 31} className="text-center" unit={validation.values.unitOfLength} />
                                    </ValidationWrapper>
                                </div>
                                <span className="small text-muted">{t("then")}</span>
                                <div style={{ flex: 3 }}>
                                    <ValidationWrapper validation={validation} field={`girthRanges.${i}.value`}>
                                        <CurrencyInput size="sm" placeholder="Price" tabIndex={3 * i + 32} currency={validation.values.currencyCode ?? "usd"} />
                                    </ValidationWrapper>
                                </div>
                                <div style={{ flex: 0 }}>
                                    <Button size="sm" color="ghost-danger" className="btn-icon" onClick={() => removeGirthRangeRow(i)}>
                                        <i className="ri-delete-bin-5-fill"></i>
                                    </Button>
                                </div>
                            </div>
                        </React.Fragment>)}
                        <div className="d-flex justify-content-center">
                            <Button size="sm" type="button" className="btn-ghost-info" onClick={addNewGirthRangeRow}>
                                {t("Add another")}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="d-flex justify-content-end gap-3">
                <BusyOverlay busy={loading.save} size="sm">
                    <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label right nexttab">
                        <i className="ri-save-3-line label-icon align-middle fs-16 ms-2"></i>
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </>;
}

export default EditForm;
import { type PropsWithChildren } from "react";

type LinedHeaderProps = PropsWithChildren<{
    tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6",
    after?: JSX.Element
}>;

const LinedHeader = ({ tag, children, after }: LinedHeaderProps) => {
    const Header = tag || "h5";

    return <div className="hstack gap-3 align-items-center mb-2">
        <Header className="mb-0 flex-grow-1 text-nowrap">{children}</Header>
        {after}
        <hr className="w-100" />
    </div>;
}

export default LinedHeader;
import { useMutation } from '@tanstack/react-query';
import { DangerAlert } from 'Components/Alerts';
import BusyOverlay from 'Components/Common/BusyOverlay';
import RequiredLabel from 'Components/Common/RequiredLabel';
import NumberInput from 'Components/Form/NumberInput';
import TextInput from 'Components/Form/TextInput';
import ValidationWrapper from 'Components/Form/Validated/ValidationWrapper';
import ValidatorButton from 'Components/Form/Validated/ValidatorButton';
import { useAppDispatch, useAppSelector } from 'Components/Hooks/StoreHooks';
import { putShelf } from 'api/shelf';
import { ShelfContract, ShelfForm } from 'api/types/contracts/locations';
import { useFormik } from 'formik';
import { TypedShape } from 'helpers/types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Col, Form, Label, Row } from 'reactstrap';
import { updateShelf } from 'slices/location/thunk';
import * as Yup from 'yup';

type FormProps = {
    shelf: ShelfContract,
    onSuccess: () => void
}

const createShelfForm = (shelf: ShelfContract): ShelfForm => {
    return {
        name: shelf.name,
        ...shelf.properties
    }
};

const UpdateShelfFormView = (props: FormProps) => {
    const aisle = createShelfForm(props.shelf);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const updateShelfMutation = useMutation({
        mutationFn: putShelf,
        mutationKey: ["update-shelf"],
        onSuccess: () => {
            toast.success(t("Shelf updated"));
            props.onSuccess();
        }
    });

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: aisle,
        validationSchema: Yup.object<ShelfForm, TypedShape<ShelfForm>>({
            name: Yup.string().required(t("Please enter shelf name")),
            height: Yup.number().notRequired(),
            width: Yup.number().notRequired(),
            depth: Yup.number().notRequired()
        }),
        onSubmit: values => {
            updateShelfMutation.mutate({
                shelfId: props.shelf.shelfId,
                shelf: values
            });
        },
    });
    
    return <>
        <Form className="needs-validation form-steps" action="#" onSubmit={validation.handleSubmit}>
            <div>
                <div className="mb-4">
                    <div>
                        <h5 className="mb-1">{t("Shelf Information")}</h5>
                    </div>
                </div>
                <Row>
                    <Col lg={4} className="g-3">
                        <RequiredLabel htmlFor="name">{t("Shelf Name")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="name">
                            <TextInput placeholder="Enter Shelf Name" />
                        </ValidationWrapper>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="width">{t("Shelf Width ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="width">
                            <NumberInput placeholder="Enter shelf width" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="height">{t("Shelf Height ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="height">
                            <NumberInput placeholder="Enter shelf height" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="depth">{t("Shelf Depth ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="depth">
                            <NumberInput placeholder="Enter shelf depth" />
                        </ValidationWrapper>
                    </Col>
                </Row>
            </div>
            <div className="mt-4">{updateShelfMutation.isError && <DangerAlert>{updateShelfMutation.error.message}</DangerAlert>}</div>
            <div className="d-flex justify-content-end gap-3">
                <BusyOverlay busy={updateShelfMutation.isPending} size="sm">
                    <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label right nexttab">
                        <i className="ri-save-3-line label-icon align-middle fs-16 ms-2"></i>
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </>;
}

export default UpdateShelfFormView;
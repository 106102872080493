import { createSlice } from "@reduxjs/toolkit";
import { WarehouseContract } from "api/types/contracts/warehouses";
import { PagedList } from "helpers/api_helper";
import { ApiError } from "helpers/types";

export type WarehouseState = {
    list: Partial<PagedList<WarehouseContract>>,
    selectList: WarehouseContract[],
    current: WarehouseContract | undefined,
    error?: ApiError
}

export const initialState: WarehouseState = {
    list: {},
    selectList: [],
    current: undefined
};

const WarehouseSlice = createSlice({
    name: "WarehouseSlice",
    initialState,
    reducers: {
        
    },
});

export const {
    
} = WarehouseSlice.actions;

export default WarehouseSlice.reducer;

import Dialog, { DialogRef } from "Components/Common/Dialog";
import { AisleContract, BayContract } from "api/types/contracts/locations";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Modal, ModalBody } from "reactstrap";
import { reloadLocations, removeBay, selectBay } from "slices/location/thunk";
import classNames from "classnames";
import UpdateBayFormView from "./_UpdateBayFormView";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import { createAppSelector, useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { Link, useSearchParams } from "react-router-dom";

type BayItemProps = {
    bay: BayContract,
    aisle: AisleContract
}

const BayItem = (props: BayItemProps) => {
    const [editModal, setEditModal] = useState(false);
    const dialogRef = useRef<DialogRef>(null);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [params, setParams] = useSearchParams();

    const { warehouse, loading, selectedAisleId, selectedAreaId, selectedBayId } = useAppSelector(
        createAppSelector([state => state.Location],
            (location) => ({
                loading: location.loading,
                warehouse: location.warehouse,
                selectedAreaId: location.selectedAreaId,
                selectedAisleId: location.selectedAisleId,
                selectedBayId: location.selectedBayId
            })
        )
    );

    const reload = () => dispatch(reloadLocations({ warehouseId: warehouse!.warehouseId }));

    const deleteBay = async () => {
        await dispatch(removeBay({ bayId: props.bay.bayId }));
        await reload();
        dialogRef.current?.hide();
    };

    const onUpdated = () => { 
        setEditModal(false);
        reload();
    };

    const select = () => {
        dispatch(selectBay(props.bay, props.aisle));
    }

    return <>
        <div className={classNames("bay-item shadow flex-shrink-0")}>
            <Card body className="bg-white mb-0 p-1">
                <div className="position-relative h-100">
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div>{t("Bay #{{bayName}}", { bayName: props.bay.name })}</div>
                    </div>
                    <div className="bay-actions auto-hide-actions">
                        <div className="position-absolute top-0 end-0 d-flex">
                            <Button color="ghost-primary" size="sm" className="btn-icon" onClick={() => setEditModal(true)} type="button">
                                <i className=" ri-edit-2-line fs-16"></i>
                            </Button>
                            <Button color="ghost-danger" size="sm" className="btn-icon" onClick={() => dialogRef.current?.show()} type="button">
                                <i className="ri-delete-bin-line fs-16"></i>
                            </Button>
                        </div>
                    </div>
                    <Link to={`/locations?warehouse=${warehouse!.warehouseId}&area=${selectedAreaId}&aisle=${props.aisle.aisleId}&bay=${props.bay.bayId}`} 
                        className="btn btn-ghost-dark btn-sm position-absolute bottom-0 end-0 btn-icon" onClick={select}>
                        <i className="ri-arrow-right-down-line fs-16"></i>
                    </Link>
                </div>
            </Card>
        </div>
        <Modal backdrop="static" isOpen={editModal} toggle={() => setEditModal(prev => !prev)} size="lg" unmountOnClose>
            <ModalBody>
                <ModalCloseButton onClick={() => setEditModal(false)} />
                <UpdateBayFormView bay={props.bay} onSuccess={onUpdated} />
            </ModalBody>
        </Modal>
        <Dialog ref={dialogRef} color="warning" buttons={["yes", "no"]} busy={loading.delete} iconClass="ri-delete-bin-line"  
            message={`Do you want to continue?`} title={t("Deleting \"Bay #{{bayName}}\"", { bayName: props.bay.name })}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    deleteBay();
                }
                else {
                    hide();
                }
            }} />
    </>;
}

export default BayItem;
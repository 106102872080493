import SelectInput from "Components/Form/SelectInput";
import { InvoiceStatusList, type InvoiceStatus } from "api/types/contracts/payment";
import { EnumSelectInput, SelectOption } from "helpers/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const InvoiceStatusSelect = (props: EnumSelectInput<InvoiceStatus>) => {
    const { t } = useTranslation();
    const invoiceStatusOptions = useMemo<SelectOption<InvoiceStatus>[]>(() => InvoiceStatusList.map((item) => ({ 
        value: item, 
        label: t(`enums:InvoiceStatus.${item}`) 
    })), [t]);

    const { isMulti, value, onChange, ...rest } = props;

    return <>
        {isMulti && <SelectInput<InvoiceStatus> isMulti value={value} onChange={onChange} {...rest} options={invoiceStatusOptions} placeholder={t("Invoice Status")} />}
        {!isMulti && <SelectInput {...rest} value={value} onChange={onChange} options={invoiceStatusOptions} placeholder={t("Invoice Status")} />}
    </>;
};

export default InvoiceStatusSelect;
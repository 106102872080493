import { AisleContract } from "api/types/contracts/locations";
import { Button, Modal, ModalBody } from "reactstrap";
import UpdateAisleFormView from "./_UpdateAisleFormView";
import { ForwardedRef, RefAttributes, useRef, forwardRef, useImperativeHandle, useState } from "react";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import { reloadLocations, removeAisle, selectAisle } from "slices/location/thunk";
import { useTranslation } from "react-i18next";
import BayItem from "../Bays/_BayItem";
import { NewBayButton } from "../Bays";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import { createAppSelector, useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { useSearchParams } from "react-router-dom";

type AisleItemProps = {
    aisle: AisleContract,
}

export type AisleItemRef = {
    baysDivRef?: HTMLDivElement
}

const AisleItem = (props: AisleItemProps, ref: ForwardedRef<AisleItemRef>) => {
    const [editModal, setEditModal] = useState(false);
    const dialogRef = useRef<DialogRef>(null);
    const baysDivRef = useRef<HTMLDivElement>(null);
    const [params, setParams] = useSearchParams();
    
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { warehouse, loading, selectedAreaId } = useAppSelector(
        createAppSelector([state => state.Location],
            (location) => ({
                ...location
            })
        )
    );

    const reload = () => dispatch(reloadLocations({ warehouseId: warehouse!.warehouseId }));
    const deleteAisle = async () => {
        await dispatch(removeAisle({ aisleId: props.aisle.aisleId }));
        await reload();
        dialogRef.current?.hide();
    };

    const onAisleUpdated = () => { 
        setEditModal(false);
        reload();
    };

    useImperativeHandle(ref, () => {
        return {
            baysDivRef: baysDivRef.current || undefined
        };
    }, [baysDivRef]);

    return <>
        <div className="aisle-item bg-white pt-2 rounded shadow">
            <div className="align-items-center d-flex gap-2 mb-2 ps-2">
                <h5 className="mb-0">{t("{{aisleName}} Aisle", { aisleName: props.aisle.name })}</h5>
                <Button color="ghost-dark" size="sm" className="btn-icon" type="button" onClick={() => {
                    dispatch(selectAisle(props.aisle));
                    setParams({ 
                        warehouse: warehouse!.warehouseId,
                        areaId: selectedAreaId!, 
                        aisle: props.aisle.aisleId 
                    });
                }}>
                    <i className="ri-arrow-right-down-line fs-16"></i>
                </Button>
                <NewBayButton aisleButton aisleId={props.aisle.aisleId} />
                <small>{t("{{count}} Bays", { count: props.aisle.bays?.length })}</small>
                <div className="aisle-actions auto-hide-actions">
                    <Button color="ghost-primary" size="sm" className="btn-icon" onClick={() => setEditModal(true)} type="button">
                        <i className=" ri-edit-2-line fs-16"></i>
                    </Button>
                    <Button color="ghost-danger" size="sm" className="btn-icon" onClick={() => dialogRef.current?.show()} type="button">
                        <i className="ri-delete-bin-line fs-16"></i>
                    </Button>
                </div>
            </div>
            <div className="bays bg-dark-subtle" ref={baysDivRef}>
                <div className="d-flex gap-2 p-2">
                    {props.aisle.bays?.map(bay => <BayItem key={bay.bayId} bay={bay} aisle={props.aisle}></BayItem>)}
                </div>
            </div>
        </div>
        <Modal backdrop="static" isOpen={editModal} toggle={() => setEditModal(prev => !prev)} size="lg" unmountOnClose>
            <ModalBody>
                <ModalCloseButton onClick={() => setEditModal(false)} />
                <UpdateAisleFormView aisle={props.aisle} onSuccess={onAisleUpdated} />
            </ModalBody>
        </Modal>
        <Dialog ref={dialogRef} color="warning" buttons={["yes", "no"]} busy={loading.delete} iconClass="ri-delete-bin-line"  
            message={`Do you want to continue?`} title={t("Deleting Aisle \"{{aisleName}}\"", { aisleName: props.aisle.name })}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    deleteAisle();
                }
                else {
                    hide();
                }
            }} />
    </>;
}

// little type assertion for typed component
export default forwardRef(AisleItem) as (
    props: AisleItemProps & RefAttributes<AisleItemRef>
) => ReturnType<typeof AisleItem>;
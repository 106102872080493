import { getCarrierAccountServiceList, getCarrierPackageTypeList, postCarrierAccount, postCreateShipment, postShipmentRateQuery, putCarrierAccount, putCarrierAccountService } from "api/carrier";
import { GetShipmentRateQuery, ListCarrierAccountServicesQuery, ListCarrierPackageTypesQuery } from "api/types/queries";
import { apiError, loading, setCarrierAccountServiceCharge, setCarrierAccountServiceLoading, setCarrierAccountServices, setPackageTypes } from "./reducer";
import { ApiError } from "helpers/types";
import { CreateCarrierAccountCommand, CreateShipmentCommand, UpdateCarrierAccountCommand, UpdateCarrierAccountServiceCommand } from "api/types/commands";
import { toast } from "react-toastify";
import i18n from "i18n";
import { AppDispatch, RootState } from "slices";

export const loadCarrierAccountServices = (params: ListCarrierAccountServicesQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["list", true ]));

        var result = await getCarrierAccountServiceList(params);

        dispatch(setCarrierAccountServices(result));
        
        return result;
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false ]));
    }
}

export const getCarrierAccountServiceCost = (params: GetShipmentRateQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(setCarrierAccountServiceLoading([params.carrierAccountServiceId, true]));

        var result = await postShipmentRateQuery(params);

        dispatch(setCarrierAccountServiceCharge([params.carrierAccountServiceId, result ]));

        return result;
    } catch (error) {
        console.error(error);
        
    }
    finally {
        dispatch(setCarrierAccountServiceLoading([params.carrierAccountServiceId, false]));
    }
}

export const createCarrierAccount = (params: CreateCarrierAccountCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true ]));

        await postCarrierAccount(params);

        toast.success(i18n.t("Carrier account created"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false ]));
    }
}

export const updateCarrierAccount = (params: UpdateCarrierAccountCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true ]));

        await putCarrierAccount(params);

        toast.success(i18n.t("Carrier account updated"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false ]));
    }
}

export const updateCarrierAccountService = (params: UpdateCarrierAccountServiceCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true ]));

        await putCarrierAccountService(params);

        toast.success(i18n.t("Carrier service updated"));

    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["save", false ]));
    }
}

export const loadCarrierPackageTypes = (params: ListCarrierPackageTypesQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["listPackageTypes", true ]));

        var result = await getCarrierPackageTypeList(params);

        dispatch(setPackageTypes(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["listPackageTypes", false ]));
    }
}

export const createShipment = (params: CreateShipmentCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["createShipment", true ]));

        var result = await postCreateShipment(params);

        return result;
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["createShipment", false ]));
    }
}
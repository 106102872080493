import { EnumSelectRadio } from "helpers/types";
import { useTranslation } from "react-i18next";
import { PaymentMethodList, type PaymentMethod } from "api/types/contracts/payment";
import { useMemo } from "react";
import SelectInput from "Components/Form/SelectInput";

const PaymentMethodRadio = (props: EnumSelectRadio<PaymentMethod>) => {
    const { t } = useTranslation();
    const { value, onChange, direction, disabledCallback, ...rest } = props;

    const options = useMemo(() => PaymentMethodList.map(p => ({
        value: p as PaymentMethod,
        label: t(`enums:PaymentMethod.${p}`),
        disabled: disabledCallback?.(p as PaymentMethod) ?? false
    })), [t, disabledCallback]);
    
    return <SelectInput options={options} onChange={onChange} value={value} 
        display={direction === "horizontal" ? "inline-radio" : "radio"} {...rest} />;
};

export default PaymentMethodRadio;

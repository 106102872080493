import { type EnumSelectInput } from "helpers/types";
import { useTranslation } from "react-i18next";
import { PaymentMethodList, type PaymentMethod } from "api/types/contracts/payment";
import { useMemo } from "react";
import SelectInput from "Components/Form/SelectInput";

const PaymentMethodSelect = (props: EnumSelectInput<PaymentMethod>) => {
    const { t } = useTranslation();
    const { value, onChange, isMulti, disabledCallback, placeholder, ...rest } = props;

    const options = useMemo(() => PaymentMethodList.map(p => ({
        value: p as PaymentMethod,
        label: t(`enums:PaymentMethod.${p}`),
        disabled: disabledCallback?.(p as PaymentMethod) ?? false
    })), [t]);

    return <>
        {isMulti && <SelectInput<PaymentMethod> isMulti value={value} onChange={onChange} {...rest} options={options} placeholder={placeholder ?? t("Payment Method")} />}
        {!isMulti && <SelectInput {...rest} value={value} onChange={onChange} options={options} placeholder={placeholder ?? t("Payment Method")} />}
    </>;
};

export default PaymentMethodSelect;

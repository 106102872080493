import { FormikValidation } from "helpers/types";
import { CSSProperties, MouseEventHandler, PropsWithChildren, useCallback, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { Button, PopoverBody, PopoverHeader, UncontrolledPopover } from "reactstrap";
import { useTranslation } from "react-i18next";
import { FormikErrors } from "formik";
import classNames from "classnames";
import config from "config";

export type ValidatedButtonProps<T, U extends keyof T> = PropsWithChildren & {
    validation: FormikValidation<T>,
    fields?: (U | string)[],
    type?: "submit" | "button" | "reset"
    disabled?: boolean,
    className?: string,
    color?: string,
    style?: CSSProperties,
    tabIndex?: number,
    onClick?: MouseEventHandler<HTMLButtonElement>
}

const ValidatorButton = <T, U extends keyof T>(props: ValidatedButtonProps<T, U>) => {
    const { fields, validation, onClick, children, className, ...rest } = props;
    const pickErrors = useCallback((errors: FormikErrors<T>, parent: string = ""): string[] => {
        const picked = fields && parent === "" ? _.pick(errors, fields) : errors;
        
        return _.flatMap(picked, (e, k) => typeof e === "string" ? [config.env.isDevelopment ? `${k}: ${e}` : e] : pickErrors(e || {}, `${parent}.${k}`));
    }, [fields]);
    const errors = useMemo(() => pickErrors(validation.errors), [pickErrors, validation.errors]);
    const messages = useMemo(() => _.compact(errors), [errors]);
    
    const hasError = messages.length > 0;
    const [errorsPopoverIsOpen, setErrorsPopoverIsOpen] = useState(false);

    // useEffect(() => {
    //     const closePopover = () => setErrorsPopoverIsOpen(false);
    //     document.body.addEventListener("click", closePopover);
    
    //     return () => {
    //         document.body.removeEventListener("click", closePopover);
    //     }
    // }, [setErrorsPopoverIsOpen])
    
    const touchFields = useCallback(async () => {
        let fieldsToCheck: string[] = [];
        if (fields) {
            fieldsToCheck = fields.map(f => f.toString());
        }
        else {
            fieldsToCheck = _.keys(validation.touched);
        }
        
        let touched: FormikErrors<T> | undefined = undefined;

        for (let i = 0; i < fieldsToCheck.length; i++) {
            touched = await validation.setFieldTouched(fieldsToCheck[i], true, true) || undefined;
        }

        if (touched) {
            const errorList = pickErrors(touched);
            return errorList;
        }
        else {
            return undefined;
        }
    }, [fields, pickErrors, validation]);

    const onClickHandler: MouseEventHandler<HTMLButtonElement> = e => {
        touchFields().then(t => _.isEmpty(t) ? onClick?.(e) : null);
    };
    
    const buttonRef = useRef<HTMLButtonElement>(null);
    const { t } = useTranslation();

    return <>
        {hasError && <UncontrolledPopover target={buttonRef} placement="top" isOpen={errorsPopoverIsOpen}>
            <PopoverHeader className="text-danger">{t("You need to fix these errors")}</PopoverHeader>
            <PopoverBody>
                <div className="vstack">
                    {messages.map((m, i) => (<span key={i}>{m}</span>))}
                </div>
            </PopoverBody>
        </UncontrolledPopover>}
        
        <Button 
            innerRef={buttonRef} 
            className={classNames(className, { "opacity-50": hasError })} 
            onClick={onClickHandler} 
            onFocus={() => setErrorsPopoverIsOpen(true)} 
            onBlur={() => setErrorsPopoverIsOpen(false)}
            {...rest}>
                {/*hasError && <i className="ri-alert-fill label-icon align-middle me-2 d-inline-block" style={{ transform: "scale(1.4)" }}></i>*/}
            {children}
        </Button>
    </>;
}

export default ValidatorButton;
import { Row, Col, Card, Container, Form, FormGroup } from "reactstrap";
import { Link, useParams, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { useTenant } from "Components/Hooks/TenantHooks";
import { withCdn } from "helpers/urlHelper";
import logoLight from "../../assets/images/tyloc/logo.png";
import Copyright from "Components/Copyright";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import TextInput from "Components/Form/TextInput";
import { useMutation } from "@tanstack/react-query";
import { DangerAlert, SuccessAlert } from "Components/Alerts";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import BusyOverlay from "Components/Common/BusyOverlay";
import { postRecoverPassword } from "api/user";
import RequiredLabel from "Components/Common/RequiredLabel";

const RecoverPasswordPage = () => {
    const { t } = useTranslation();
    const [params, setParams] = useSearchParams();
    
    const { setPageTitle } = useMetadata();
    const { tenant } = useTenant();

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            token: params.get("token"),
            password: '',
            confirmPassword: ''
        },
        validationSchema: Yup.object({
            token: Yup.string().required(),
            password: Yup.string().required(t("Please Enter Your Password")),
            confirmPassword: Yup.string().required(t("Please Enter Confirm Password")).oneOf([Yup.ref('password')], t("Passwords must match"))
        }),
        onSubmit: (values) => {
            recoverPasswordMutation.mutate({
                recoverToken: values.token!,
                password: values.password
            });
        }
    });

    const recoverPasswordMutation = useMutation({
        mutationFn: postRecoverPassword,
        mutationKey: ["recover-password"],
        onSuccess: () => {
            
        }
    });

    setPageTitle(t("Recover Password"));

    return <>
        <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
            <div className="bg-overlay bg-tenant-primary"></div>
            <div className="auth-page-content overflow-hidden">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <Card className="overflow-hidden m-0">
                                <Row>
                                    <Col lg={5} className='p-4'>
                                        <div className="text-center mt-2">
                                            <h2 className="mt-3 fw-medium text-tenant-primary">
                                                {tenant?.name}
                                            </h2>
                                            <h5 className="text-primary">{t("Change your password")}</h5>
                                            <p className="text-muted">{t("Change password for {{name}} account", { name: tenant?.name })}.</p>
                                            <i className="ri-mail-send-line display-5 text-success my-4"></i>
                                        </div>
                                        {recoverPasswordMutation.isError && <DangerAlert>
                                            {recoverPasswordMutation.error.message}
                                        </DangerAlert>}
                                        {recoverPasswordMutation.isSuccess && <SuccessAlert>
                                            <div className="d-flex justify-content-between align-items-center gap-3">
                                                <span>{t("paragraphs:PasswordRecoverSuccessMessage")}</span>
                                                <Link to="/login" className="btn btn-success">
                                                    {t("Login")}
                                                </Link>
                                            </div>
                                        </SuccessAlert>}
                                        <div className="p-2 mt-2">
                                            <Form onSubmit={validation.handleSubmit} action="#">
                                                <FormGroup>
                                                    <RequiredLabel htmlFor="password">{ t("Password") }</RequiredLabel>
                                                    <ValidationWrapper validation={validation} field="password">
                                                        <TextInput type="password" placeholder={ t("Enter email") } tabIndex={1} />
                                                    </ValidationWrapper>
                                                    
                                                </FormGroup>
                                                <FormGroup>
                                                    <RequiredLabel htmlFor="confirmPassword">{ t("Confirm Password") }</RequiredLabel>
                                                    <ValidationWrapper validation={validation} field="confirmPassword">
                                                        <TextInput type="password" placeholder={ t("Enter email") } tabIndex={1} />
                                                    </ValidationWrapper>
                                                    
                                                </FormGroup>
                                                <div className="mb-3">
                                                    <BusyOverlay busy={recoverPasswordMutation.isPending} size="sm">
                                                        <ValidatorButton color='success' className='w-100' type='submit' validation={validation} tabIndex={3}>
                                                            {t("Change Password")}
                                                        </ValidatorButton>
                                                    </BusyOverlay>
                                                </div>
                                            </Form>
                                        </div>
                                    </Col>
                                    <Col lg={7}>
                                        <div className="p-lg-5 p-4 auth-one-bg tenant-cover-background h-100">
                                            <div className="bg-overlay bg-tenant-secondary"></div>
                                            <div className="position-relative h-100 d-flex flex-column text-center">
                                                <div className="text-center mt-sm-3 mb-4 text-white-50">
                                                    <div>
                                                        <Link to="/" className="d-inline-block auth-logo">
                                                            <img src={tenant?.logo ? withCdn(tenant.logo) : logoLight} alt="" className='mw-100' style={{ maxHeight: "210px" }} />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <p className="">
                                                    {tenant?.coverPageText}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <footer className="footer">
                <div className="text-center">
                    <Copyright />
                </div>
            </footer>
        </div>
    </>;
};

export default RecoverPasswordPage;

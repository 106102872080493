import { configureStore, combineReducers } from "@reduxjs/toolkit";
// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";
import MetadataReducer from './metadata/reducer';
import TenantReducer from "./tenant/reducer";
import CompanyReducer from './company/reducer';
import CommonReducer from "./common/reducer";
import WarehouseReducer from "./warehouse/reducer";
import LocationReducer from "./location/reducer";
import BinReducer from "./bins/reducer";
import CustomerReducer from "./customer/reducer";
import ProductReducer from "./product/reducer";
import ProductCategoryReducer from "./product/category/reducer";
import ProductColorReducer from "./product/color/reducer";
import ProductBrandReducer from "./product/brand/reducer";
import ProductSupplierReducer from "./product/supplier/reducer";
import ProductSizeReducer from "./product/size/reducer";
import InboundReducer from "./inbound/reducer";
import ReceivingReducer from "./receivings/reducer";
import UnusableProductReasonReducer from "./receivings/unusableProductReason/reducer";
import PrepServiceReducer from "./shipping/prepService/reducer";
import FbaShippingReducer from "./shipping/fbaShipping/reducer";
import InventoryReducer from './inventory/reducer';
import CarrierReducer from "./carrier/reducer";
import CarrierRuleReducer from "./carrierRules/reducer";
import ParinterReducer from "./printers/reducer";
import UserReducer from "./user/reducer";

import config from 'config';
//import listeners from 'slices/listeners';

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    ForgetPassword: ForgetPasswordReducer,
    Profile: ProfileReducer,
    Metadata: MetadataReducer,
    Tenant: TenantReducer,
    Company: CompanyReducer,
    Warehouse: WarehouseReducer,
    Bin: BinReducer,
    Common: CommonReducer,
    Location: LocationReducer,
    Customer: CustomerReducer,
    Product: ProductReducer,
    ProductCategory: ProductCategoryReducer,
    ProductColor: ProductColorReducer,
    ProductBrand: ProductBrandReducer,
    ProductSupplier: ProductSupplierReducer,
    ProductSize: ProductSizeReducer,
    Inbound: InboundReducer,
    Receiving: ReceivingReducer,
    UnusableProductReason: UnusableProductReasonReducer,
    PrepService: PrepServiceReducer,
    FbaShipping: FbaShippingReducer,
    Inventory: InventoryReducer,
    Carrier: CarrierReducer,
    CarrierRules: CarrierRuleReducer,
    Printers: ParinterReducer,
    User: UserReducer
});

const store = configureStore({ 
    reducer: rootReducer, 
    middleware: getDefaultMiddleware => {
        const middlewares = getDefaultMiddleware();
        //middlewares.push(...listeners);

        return middlewares;
    },
    devTools: config.env.isDevelopment 
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PrepServiceContract } from "api/types/contracts/shipping";
import { PagedList } from "helpers/api_helper";
import { ApiError, LoadingStates } from "helpers/types";

type PrepServiceLoadingStates = LoadingStates<"list" | "delete">;

export type PrepServiceState = {
    list: Partial<PagedList<PrepServiceContract>>,
    loading: PrepServiceLoadingStates,
    error?: ApiError
}

export const initialState: PrepServiceState = {
    list: {},
    loading: {
        list: false,
        delete: false
    }
};

const PrepServiceSlice = createSlice({
    name: "PrepServiceSlice",
    initialState,
    reducers: {
        setList(state, action: PayloadAction<PagedList<PrepServiceContract>>) {
            state.list = action.payload;
        },
        loading(state, action: PayloadAction<[keyof PrepServiceLoadingStates, boolean]>) {
            const [key, value] = action.payload;
            state.loading[key] = value;

            if (value) {
                state.error = undefined;
            }
        },
        apiError(state, action: PayloadAction<ApiError | undefined>) {
            state.error = action.payload;
        }
    },
});

export const {
    setList,
    loading,
    apiError
} = PrepServiceSlice.actions;

export default PrepServiceSlice.reducer;

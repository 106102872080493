import { useCallback, useEffect } from "react";
import { getTenantInfo, loadCurrentCompany, loadStorageAuthorizationValues, refreshTenantInfo } from "slices/tenant/thunk";
import { useDebounce } from "./HelperHooks";
import { withCdn } from "helpers/urlHelper";
import { StorageAuthorizationMode } from "slices/tenant/reducer";
import { createAppSelector, useAppDispatch, useAppSelector } from "./StoreHooks";

const useTenant = () => {
    const dispatch = useAppDispatch();

    const { tenant, company, loadingState, error, storageAuthorization } = useAppSelector(
        createAppSelector([state => state.Tenant],
            (tenant) => ({
                ...tenant
            })
        )
    );
    
    const loadTenant = useCallback(async (force: boolean) => {
        if (loadingState === "notloaded") {
            await dispatch(getTenantInfo());
            await dispatch(loadCurrentCompany());
        }
        else if (loadingState === "loaded" && force) {
            await dispatch(refreshTenantInfo());
            await dispatch(loadCurrentCompany());
        }
        else if (loadingState === "failed" && force) {
            await dispatch(refreshTenantInfo());
            await dispatch(loadCurrentCompany());
        }
    }, [dispatch, loadingState]);

    const getTenantDebounced = useDebounce(() => loadTenant(false), 100);

    useEffect(() => {   
        getTenantDebounced();
    }, [getTenantDebounced]);

    useEffect(() => {
        if (tenant) {
            if (tenant.primaryColor) {
                document.body.style.setProperty("--tenant-primary-color", tenant.primaryColor);
                document.querySelector<HTMLMetaElement>("meta[name=theme-color]")!.content = tenant.primaryColor;
            }

            if (tenant.secondaryColor) {
                document.body.style.setProperty("--tenant-secondary-color", tenant.secondaryColor);
            }
            
            if (tenant.favicon) {
                document.querySelector<HTMLLinkElement>("link[rel=icon]")!.href = withCdn(tenant.favicon);
            }

            if (tenant.logo) {
                document.querySelector<HTMLLinkElement>("link[rel=apple-touch-icon]")!.href = withCdn(tenant.logo);
            }

            if (tenant.backgroundImage) {
                document.body.style.setProperty("--tenant-background-image", `url(${withCdn(tenant.backgroundImage)})`);
            }
        }
    
        // return () => {
        //     document.body.style.removeProperty("--tyloc-tenant-primary-color");
        //     document.body.style.removeProperty("--tyloc-tenant-secondary-color");
        // }
    }, [tenant]);
    
    // useEffect(() => {
    //     if (storageAuthorization.mode === "cookie") {
    //         for (const key in storageAuthorization.signatureValues) {
    //             const value = storageAuthorization.signatureValues[key];
    
    //             cookie.set(key, value, {
    //                 domain: config.cdn.FILES_URL,
    //                 secure: true
    //             });
    //         }
    //     }
    // }, [storageAuthorization]);

    const refreshStorageAuthorization = useCallback((mode?: StorageAuthorizationMode) => {
        if (!storageAuthorization.loading) {
            return dispatch(loadStorageAuthorizationValues(mode || storageAuthorization.mode));
        }
    }, [dispatch, storageAuthorization.mode]);

    return { tenant, company, refresh: () => loadTenant(true), loadingState, error, storageAuthorization, refreshStorageAuthorization };
};

export { useTenant };

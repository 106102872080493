import classNames from "classnames";
import { ValidatableInputProps } from "helpers/types";
import { InputHTMLAttributes } from "react";
import DatePicker from "react-flatpickr";
import { useTranslation } from "react-i18next";

// Define the type DateInputProps
export type DateInputProps = ValidatableInputProps<Date> & Pick<InputHTMLAttributes<'input'>, 'id' | 'disabled' | 'className' | 'style' | 'placeholder'> & {
    max?: Date,
    min?: Date,
    size?: "sm" | "lg",
    wrapperClassName?: string
};

const DateInput = (props: DateInputProps) => {
    const { value, onChange, className, wrapperClassName, min, max, isValid, size, ...rest } = props;
    const { i18n } = useTranslation();

    const onChangeDate = ([date]: Date[]) => {
        onChange?.(date);
    }

    return <>
        <div className={classNames("form-icon form-control p-0", wrapperClassName)}>
            <DatePicker
                value={value}
                className={classNames("form-control form-control-icon border-0", className, {
                    "form-control-sm": size === "sm",
                    "form-control-lg": size === "lg",
                })}
                style={{
                    backgroundColor: rest.disabled ? "var(--tertiary-bg)" : undefined
                }}
                width={"auto"}
                onChange={onChangeDate}
                options={{
                    minDate: min,
                    maxDate: max,
                    locale: i18n.language as any, //TODO: use type from flatpickr
                    formatDate: (date, format, locale) => {
                        return new Intl.DateTimeFormat(i18n.language, {
                            year: "numeric",
                            month: "short",
                            day: "2-digit"
                        }).format(date);
                    }
                }}
                {...rest}
            />
            <i className="ri-calendar-2-line"></i>
        </div>
    </>;
};

export default DateInput;
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FbaShipmentContract, ShipmentTargetContract } from "api/types/contracts/shipping";
import { PagedList } from "helpers/api_helper";
import { ApiError, LoadingStates } from "helpers/types";

type FbaShippingStateLoadingStates = LoadingStates<"list" | "save" | "delete" | "load" | "invoicePay" | "invoiceCancel" | "invoiceRefund" | "shipmentTargetList" | "print">;

export type FbaShippingState = {
    list: Partial<PagedList<FbaShipmentContract>>,
    processingItem: FbaShipmentContract | undefined,
    shipmentTargets: ShipmentTargetContract[],
    loading: FbaShippingStateLoadingStates,
    error?: ApiError
}

export const initialState: FbaShippingState = {
    list: {},
    processingItem: undefined,
    shipmentTargets: [],
    loading: {
        list: false,
        save: false,
        delete: false,
        load: false,
        invoicePay: false,
        invoiceCancel: false,
        invoiceRefund: false,
        shipmentTargetList: false,
        print: false
    }
};

const FbaShippingSlice = createSlice({
    name: "FbaShippingSlice",
    initialState,
    reducers: {
        setList(state, action: PayloadAction<PagedList<FbaShipmentContract>>) {
            state.list = action.payload;
        },
        setProcessingItem(state, action: PayloadAction<FbaShipmentContract | undefined>) {
            state.processingItem = action.payload;
        },
        setShipmentTargetList(state, action: PayloadAction<ShipmentTargetContract[]>) {
            state.shipmentTargets = action.payload;
        },
        loading(state, action: PayloadAction<[keyof FbaShippingStateLoadingStates, boolean]>) {
            const [key, value] = action.payload;
            state.loading[key] = value;

            if (value) {
                state.error = undefined;
            }
        },
        apiError(state, action: PayloadAction<ApiError | undefined>) {
            state.error = action.payload;
        }
    },
});

export const {
    setList,
    loading,
    setProcessingItem,
    setShipmentTargetList,
    apiError
} = FbaShippingSlice.actions;

export default FbaShippingSlice.reducer;

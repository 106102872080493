import { type EnumSelectInput } from "helpers/types";
import { useTranslation } from "react-i18next";
import { CreditRequestStatusList, type CreditRequestStatus } from "api/types/contracts/payment";
import { useMemo } from "react";
import SelectInput from "Components/Form/SelectInput";

const CreditRequestStatusSelect = (props: EnumSelectInput<CreditRequestStatus>) => {
    const { t } = useTranslation();
    const { value, onChange, isMulti, disabledCallback, placeholder, ...rest } = props;

    const options = useMemo(() => CreditRequestStatusList.map(p => ({
        value: p as CreditRequestStatus,
        label: t(`enums:CreditRequestStatus.${p}`),
        disabled: disabledCallback?.(p as CreditRequestStatus) ?? false
    })), [t]);

    return <>
        {isMulti && <SelectInput<CreditRequestStatus> isMulti value={value} onChange={onChange} {...rest} options={options} placeholder={placeholder ?? t("Credit Request Status")} />}
        {!isMulti && <SelectInput {...rest} value={value} onChange={onChange} options={options} placeholder={placeholder ?? t("Credit Request Status")} />}
    </>;
};

export default CreditRequestStatusSelect;

import ModalCloseButton from "Components/Common/ModalCloseButton";
import ResolveWrapper from "Components/Common/ResolveWrapper";
import Restricted from "Components/Common/Restricted";
import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import Unauthorized from "pages/Errors/_Unauthorized";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Container, Modal, ModalBody } from "reactstrap";
import ImportTaxPricingCarrierRuleList, { ImportTaxPricingCarrierRuleListRef } from "./_List";
import EditForm from "./_EditForm";
import { CarrierImportTaxPricingRuleContract } from "api/types/contracts/carrier";

type ImportTaxPricingCarrierRulePageProps = {
    edit?: boolean
}

const ImportTaxPricingCarrierRulePage = (props: ImportTaxPricingCarrierRulePageProps) => {
    const loaderData = useLoaderData() as { importTaxRule: Promise<CarrierImportTaxPricingRuleContract>};
    const { t } = useTranslation();
    const navigate = useNavigate();
    const listRef = useRef<ImportTaxPricingCarrierRuleListRef>(null);

    return <Restricted require="management.carrier" throw fallback={() => <Unauthorized />}>
            <div className="page-content">
                <Container fluid>
                    <TitleBreadcrumb active={t("Import Tax Pricing Rules")} parents={[t("Management"), t("Carrier Rules")]} />
                    <ImportTaxPricingCarrierRuleList ref={listRef} />
                    {props.edit && 
                        <Modal backdrop="static" isOpen toggle={() => navigate("/carrier-rules/import-tax-rules")} unmountOnClose>
                            <ModalBody>
                                <ResolveWrapper promise={loaderData?.importTaxRule}>
                                    {(importTaxRule) => <>
                                        <ModalCloseButton onClick={() => navigate("/carrier-rules/import-tax-rules")} />
                                        <EditForm importTaxPricingRule={importTaxRule} onSuccess={() => {
                                            navigate("/carrier-rules/import-tax-rules");
                                            listRef.current?.reload();
                                        }} />
                                    </>}
                                </ResolveWrapper>
                            </ModalBody>
                        </Modal>
                    }
                </Container>
            </div>
        </Restricted>;
};

export default ImportTaxPricingCarrierRulePage;
import { useMutation } from "@tanstack/react-query";
import { DangerAlert } from "Components/Alerts";
import BusyOverlay from "Components/Common/BusyOverlay";
import WarehouseSelect from "Components/EntitySelects/WarehouseSelect";
import PrepServiceChannelCheckbox from "Components/EnumSelects/PrepServiceChannelCheckbox";
import PrepServicePricingTypeRadio from "Components/EnumSelects/PrepServicePricingTypeRadio";
import PrepServiceTypeRadio from "Components/EnumSelects/PrepServiceTypeRadio";
import Checkbox from "Components/Form/Checkbox";
import TextInput from "Components/Form/TextInput";
import CurrencyInput from "Components/Form/UnitInputs/CurrencyInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { postPrepService, putPrepService } from "api/prepService";
import { PrepServiceChannel, PrepServiceContract, PrepServiceForm, PrepServicePricingType, PrepServiceRangePricingContract, PrepServiceType } from "api/types/contracts/shipping"
import { WarehouseContract } from "api/types/contracts/warehouses";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button, Col, Form, FormFeedback, FormGroup, Label, Row } from "reactstrap";
import * as Yup from "yup";

type FormProps = {
    prepService?: PrepServiceContract,
    onSuccess: VoidFunction
}

const defaultForm: Partial<PrepServiceForm> = {
    includeAsDefault: false,
    showInProductOptions: false,
    showInChecklist: false
};

const createPrepServiceForm = (prepService: PrepServiceContract | undefined): PrepServiceForm | undefined => {
    return prepService ? {
        companyId: prepService.companyId,
        warehouseId: prepService.warehouse.warehouseId,
        name: prepService.name,
        description: prepService.description,
        pricingType: prepService.pricingType,
        serviceType: prepService.serviceType,
        channels: prepService.channels,
        includeAsDefault: prepService.includeAsDefault,
        showInProductOptions: prepService.showInProductOptions,
        showInChecklist: prepService.showInChecklist,
        price: prepService.price,
        priceRanges: prepService.priceRanges.map(r => ({ ...r }))
    } as PrepServiceForm : undefined
};

const EditForm = (props: FormProps) => {
    const { setPageTitle } = useMetadata();
    const { t } = useTranslation();
    const prepServiceForm = useMemo(() => createPrepServiceForm(props.prepService) || defaultForm, [props.prepService]);
    const [selectedWarehouse, setSelectedWarehouse] = useState<WarehouseContract>();
    const [priceRanges, setPriceRanges] = useState(props.prepService ? prepServiceForm.priceRanges?.length !== 0 : false);

    setPageTitle(`${t(props.prepService ? "Update Prep Service" : "Create Prep Service")} - ${t("Receiving")}`);

    const savePrepServiceMutation = useMutation({
        mutationFn: async (prepService: PrepServiceForm) => {
            if (props.prepService?.prepServiceId) {
                await putPrepService({ prepServiceId: props.prepService.prepServiceId, prepService });
            }
            else {
                await postPrepService({ prepService });
            }
        },
        mutationKey: ["save-prep-service", props.prepService?.prepServiceId],
        onSuccess: (result) => {
            props.onSuccess();

            if (props.prepService?.prepServiceId) {
                toast.success(t("Prep service updated successfully"));
            }
            else {
                toast.success(t("Prep service created successfully"));
            }
        }
    })
    
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: prepServiceForm,
        validationSchema: Yup.object<PrepServiceForm, TypedShape<PrepServiceForm>>({
            name: Yup.string().required(t("Name is required")),
            description: Yup.string().notRequired(),
            warehouseId: Yup.string().required(t("Warehouse is required")),
            pricingType: Yup.string<PrepServicePricingType>().required(t("Pricing type is required")),
            channels: Yup.array().of(Yup.string<PrepServiceChannel>().required()).required(t("Channel is required")),
            serviceType: Yup.string<PrepServiceType>().required(t("Service type is required")),
            price: Yup.number().notRequired(),
            includeAsDefault: Yup.boolean().required(),
            showInProductOptions: Yup.boolean().required(),
            showInChecklist: Yup.boolean().required(),
            priceRanges: Yup.array<PrepServiceRangePricingContract>().of(Yup.object({
                from: Yup.number().required(t("Lower bound is required")),
                to: Yup.number().required(t("Upper bound is required")),
                value: Yup.number().required(t("Price is required"))
            })).test("overlapCheck", t("Ranges must not overlap"),(val, { schema }) => {
                if (!val) {
                    return true;
                }

                for (let i = 0; i < val.length; i++) {
                    const range = val[i];
                    for (let j = i + 1; j < val.length; j++) {
                        const otherRange = val[j];
                        if ((range.from >= otherRange.from && range.from <= otherRange.to) 
                            || (range.to >= otherRange.from && range.to <= otherRange.to)) {
                            return false;
                        }
                    }
                }

                return true;
            }).required()
        }),
        onSubmit: (values) => {
            const prepService = values as PrepServiceForm;

            savePrepServiceMutation.mutate(prepService);
        },
    });

    const addNewPriceRangeRow = () => {
        const oldRanges = validation.values.priceRanges || [];
        const lastRangeEnd = ((oldRanges.at(-1)?.to) || -1) + 1; // Get the last range end or -1 if there are no ranges

        validation.setFieldValue("priceRanges", [...oldRanges, {
            from: lastRangeEnd,
            to: 0
        }]);
    };

    useEffect(() => {
        if (priceRanges && validation.values.priceRanges?.length === 0) {
            addNewPriceRangeRow();
        }
        
        if (priceRanges) {
            validation.setFieldValue("price", undefined);
        }
        else {
            validation.setFieldValue("priceRanges", []);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priceRanges]);

    useEffect(() => {
        if (validation.touched.serviceType) {
            validation.setFieldValue("pricingType", undefined);
        }
    }, [validation.values.serviceType]);
    

    const removePriceRangeRow = (i: number) => {
        const newRanges = validation.values.priceRanges?.filter((_, index) => index !== i);
        validation.setFieldValue("priceRanges", newRanges);
    }

    const validationErrorDisplay = (error: string | Record<string, string>[]) => {
        if (typeof error === "string") {
            return error;
        }
        
        //return error.filter(e => !!e).map((e, i) => <span key={i}><span>{t("Row {{i}}", { i: i + 1 })}: {Object.values(e).join(", ")}</span><br /></span>);
    }

    return <>
        <Form className="needs-validation" action="#" onSubmit={validation.handleSubmit}>
            <div>
                <div className="mb-4">
                    <div>
                        <h5 className="mb-1">{t(props.prepService ? "Edit Prep Service" : "Create Prep Service")}</h5>
                    </div>
                </div>
                <Row>
                    <Col md={6}>
                        <Label htmlFor="name">{t("Service Name")}</Label>
                        <ValidationWrapper validation={validation} field="name">
                            <TextInput placeholder={t("Enter service name")} />
                        </ValidationWrapper>
                    </Col>
                    <Col md={6}>
                        <Label htmlFor="description">{t("Description")}</Label>
                        <ValidationWrapper validation={validation} field="description">
                            <TextInput type="textarea" placeholder={t("Enter description")} />
                        </ValidationWrapper>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="g-3">
                        <Label htmlFor="channels">{t("Channel")}</Label>
                        <ValidationWrapper validation={validation} field="channels">
                            <PrepServiceChannelCheckbox direction="horizontal" />
                        </ValidationWrapper>
                    </Col>
                    <Col md={6} className="g-3">
                        <Label htmlFor="warehouse">{t("Warehouse")}</Label>
                        <ValidationWrapper validation={validation} field="warehouseId">
                            <WarehouseSelect onSelect={setSelectedWarehouse} />
                        </ValidationWrapper>
                    </Col>
                </Row>
                <h5 className="my-3">{t("Options")}</h5>
                <FormGroup row>
                    <div className="hstack align-items-start gap-3">
                        <div>
                            <ValidationWrapper validation={validation} field="includeAsDefault">
                                <Checkbox label={t("Include as default")} onChange={val => {
                                    if (val) {
                                        validation.setFieldValue("showInProductOptions", false);
                                    }
                                }} />
                            </ValidationWrapper>
                        </div>
                        <div>
                            <ValidationWrapper validation={validation} field="showInProductOptions">
                                <Checkbox label={t("Show in product options")} onChange={val => {
                                    if (val) {
                                        validation.setFieldValue("includeAsDefault", false);
                                    }
                                }} />
                            </ValidationWrapper>

                            {/*validation.values.showInProductOptions && <div className="border-5 border-start my-1 px-2">
                                <Checkbox label={t("Checked by default")} />
                            </div>*/}
                        </div>
                        <div>
                            <ValidationWrapper validation={validation} field="showInChecklist">
                                <Checkbox label={t("Show in checklist")} />
                            </ValidationWrapper>
                        </div>
                    </div>
                </FormGroup>
                <FormGroup row>
                    <Col sm={3} md={2}>
                        {t("Service Type")}
                    </Col>
                    <Col sm={9} md={10}>
                        <ValidationWrapper validation={validation} field="serviceType">
                            <PrepServiceTypeRadio direction="horizontal" />
                        </ValidationWrapper>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={3} md={2}>
                        {t("Pricing Type")}
                    </Col>
                    <Col sm={9} md={10}>
                        <ValidationWrapper validation={validation} field="pricingType">
                            <PrepServicePricingTypeRadio direction="horizontal" disabledCallback={pricingType => {
                                if (validation.values.serviceType === "expireDate" && pricingType !== "perSku") {
                                    return true;
                                }
                                if (validation.values.serviceType === "bundling" && pricingType !== "perUnit") {
                                    return true;
                                }
                                if (validation.values.serviceType === "splitting" && pricingType !== "perUnit") {
                                    return true;
                                }

                                return false;
                            }} />
                        </ValidationWrapper>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={3} md={2} className="mt-2">
                        {t("Price")}
                    </Col>
                    <Col sm={3} md={4}>
                        <ValidationWrapper validation={validation} field="price">
                            <CurrencyInput placeholder={t("Enter price")} disabled={priceRanges} currency={selectedWarehouse?.settings.unitOfCurrency} />
                        </ValidationWrapper>
                    </Col>
                    <Col>
                        <div className="">
                            <div className="my-2">
                                <Checkbox label={t("Define price ranges")} disabled={validation.values.pricingType === "custom"} value={priceRanges} onChange={val => setPriceRanges(val || false)} />
                            </div>
                            {priceRanges && <div className="vstack gap-1">
                                {validation.values.priceRanges?.map((range, i) => <React.Fragment key={i}>
                                    <div className="hstack gap-3" key={i}>
                                        <div style={{ flex: 0 }}>
                                            <Button size="sm" color="ghost-danger" className="btn-icon" onClick={() => removePriceRangeRow(i)}>
                                                <i className="ri-delete-bin-5-fill"></i>
                                            </Button>
                                        </div>
                                        <div style={{ flex: 2 }}>
                                            <ValidationWrapper validation={validation} field={`priceRanges.${i}.from`}>
                                                <CurrencyInput size="sm" placeholder="From" tabIndex={3 * i + 0} className="text-center" currency={selectedWarehouse?.settings.unitOfCurrency} />
                                            </ValidationWrapper>
                                        </div>
                                        <div style={{ flex: 2 }}>
                                            <ValidationWrapper validation={validation} field={`priceRanges.${i}.to`}>
                                                <CurrencyInput size="sm" placeholder="To" tabIndex={3 * i + 1} className="text-center" currency={selectedWarehouse?.settings.unitOfCurrency} />
                                            </ValidationWrapper>
                                        </div>
                                        <div style={{ flex: 4 }}>
                                            <ValidationWrapper validation={validation} field={`priceRanges.${i}.value`}>
                                                <CurrencyInput size="sm" placeholder="Price" tabIndex={3 * i + 2} currency={selectedWarehouse?.settings.unitOfCurrency} />
                                            </ValidationWrapper>
                                        </div>
                                    </div>
                                </React.Fragment>)}
                                {validation.errors.priceRanges && <FormFeedback type="invalid" className="d-block">{validationErrorDisplay(validation.errors.priceRanges)}</FormFeedback>}
                                <div className="d-flex justify-content-center">
                                    <Button type="button" className="btn-ghost-info" onClick={addNewPriceRangeRow}>
                                        {t("Add new range")}
                                    </Button>
                                </div>
                            </div>}
                        </div>
                    </Col>
                </FormGroup>
            </div>
            <div className="mt-4">{savePrepServiceMutation.isError && <DangerAlert>{savePrepServiceMutation.error.message}</DangerAlert>}</div>
            <div className="d-flex justify-content-end gap-3">
                <BusyOverlay busy={savePrepServiceMutation.isPending} size="sm">
                    <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label right nexttab">
                        <i className="ri-save-3-line label-icon align-middle fs-16 ms-2"></i>
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </>;
}

export default EditForm;
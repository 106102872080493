import SelectInput from "Components/Form/SelectInput"
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { CountryContract } from "api/types/contracts/common";
import { EntitySelectInputProps } from "helpers/types";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { loadCountries } from "slices/common/thunk";

type CountrySelectProps = EntitySelectInputProps<CountryContract, "code"> & {
    
}

const CountrySelect = ({ isMulti, value, onChange, onSelect, name, isValid, ...rest }: CountrySelectProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { countries, loading } = useAppSelector(
        (state) => ({
            countries: state.Common.countries || [],
            loading: state.Common.loading.countries
        })
    );

    const countryList = useMemo(() => countries.map(c => ({ value: c.code, label: c.name})), [countries]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = countries.filter(c => val.includes(c.code));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = countries.find(c => c.code === val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.code);
                return;
            }
        }
        
        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        dispatch(loadCountries());
    }, [dispatch]);

    useEffect(() => {
        if (!value) {
            return;
        }

        if (countries.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countries]);
    
    const selectProps = {
        ...rest,
        placeholder: rest.placeholder ?? t("Country"),
        busy: loading,
        options: countryList,
        showClear: true,
        isValid: isValid
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default CountrySelect;
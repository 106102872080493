import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PagedList } from "helpers/api_helper";
import { ApiError, LoadingStates } from "helpers/types";
import { CustomerContract } from "api/types/contracts/customers";

type CustomerLoadingStates = LoadingStates<"list" | "save" | "delete">;

export type CustomerState = {
    list: Partial<PagedList<CustomerContract>>,
    loading: CustomerLoadingStates,
    error?: ApiError
}

export const initialState: CustomerState = {
    list: {},
    loading: {
        list: false,
        save: false,
        delete: false
    }
};

const CustomerSlice = createSlice({
    name: "CustomerSlice",
    initialState,
    reducers: {
        setList(state, action: PayloadAction<PagedList<CustomerContract>>) {
            state.list = action.payload;
        },
        loading(state, action: PayloadAction<[keyof CustomerLoadingStates, boolean]>) {
            const [operation, loadingState] = action.payload;
            state.loading[operation] = loadingState;
        },
        apiError(state, action: PayloadAction<ApiError>) {
            state.error = action.payload;
        }
    },
});

export const {
    setList,
    loading,
    apiError
} = CustomerSlice.actions;

export default CustomerSlice.reducer;
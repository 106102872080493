import { GetProductByAsinQuery, GetProductQuery, ListProductsQuery, SearchProductByAsinQuery } from "api/types/queries";
import { CreateProductCommand, DeleteProductCommand, QuickAddProductsCommand, UpdateProductCommand } from "api/types/commands";
import { APIClient, PagedList } from "../helpers/api_helper";
import { ProductSearchResultContract, ProductContract } from "./types/contracts/products";

const api = new APIClient();

export const getProduct = async (data: GetProductQuery) => {
    const result = await api.get<ProductContract>(`/api/product/${data.productId}`);
    return result;
}

export const getProductByAsin = async (data: GetProductByAsinQuery) => {
    const result = await api.get<ProductContract>(`/api/product/asin/${data.asin}`);
    return result;
}

export const searchProductByAsin = async (data: SearchProductByAsinQuery) => {
    const result = await api.get<ProductSearchResultContract>(`/api/product/asin/${data.asin}/search`);
    return result;
}

export const getProductList = async (data: ListProductsQuery) => {
    const result = await api.get<PagedList<ProductContract>>(`/api/product`, data);
    return result;
}

export const postProduct = async (data: CreateProductCommand): Promise<void> => {
    await api.post(`/api/product`, data);
}

export const putProduct = async (data: UpdateProductCommand): Promise<void> => {
    await api.put(`/api/product/${data.productId}`, { ...data.product });
}

export const deleteProduct = async (data: DeleteProductCommand): Promise<void> => {
    await api.delete(`/api/product/${data.productId}`);
}

export const postQuickAddProduct = async (data: QuickAddProductsCommand) => {
    const result = await api.post(`/api/product/quick-add`, data);

    return result.data as ProductContract[];
}
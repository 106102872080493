import { useProfile } from "Components/Hooks/ProfileHooks";
import { UnitOfCurrency } from "api/types/contracts/common";
import { useTranslation } from "react-i18next";

export type CurrencyProps = {
    value: number | undefined | null,
    currency: UnitOfCurrency | undefined,
    maxDigits?: number
}

const Currency = (props: CurrencyProps) => {
    const { userProfile } = useProfile();
    const { i18n } = useTranslation();

    const currency = (props.currency ?? userProfile?.user.warehouse?.settings.unitOfCurrency ?? "usd").toUpperCase();
    const value = (props.value !== undefined && props.value !== null) ? Intl.NumberFormat(i18n.language, {
        maximumFractionDigits: props.maxDigits ?? 2,
        style: 'currency',
        currency
    }).format(props.value) : "-"

    return <span className="text-nowrap">{value}</span>;
}

export default Currency;
import { WarningAlert } from "Components/Alerts";
import { useTranslation } from "react-i18next";
import Grid from "./_Grid";
import BusyOverlay from "Components/Common/BusyOverlay";
import List from "../BinLocations/_List";
import { createAppSelector, useAppSelector } from "Components/Hooks/StoreHooks";
import { useMetadata } from "Components/Hooks/MetadataHooks";

type AislesViewProps = {
    
}

const AislesView = (props: AislesViewProps) => {
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();

    const { selectedArea, loading } = useAppSelector(
        createAppSelector([state => state.Location],
            (location) => ({
                loading: location.loading,
                selectedArea: location.areas?.find(a => a.areaId === location.selectedAreaId)
            })
        )
    );

    setPageTitle(`${(t("Area {{area}}", { area: selectedArea?.name }))} - ${t("Locations")} - ${t("Management")}`);

    return <>
        <BusyOverlay busy={loading.data} blur>
            {selectedArea && (selectedArea.aisles?.length !== 0 || selectedArea.binLocations?.length !== 0) ? <>
                {selectedArea.aisles && <Grid aisles={selectedArea.aisles} />}
                {selectedArea.binLocations && <>
                    <List binLocations={selectedArea.binLocations} />
                </>}
            </>

            :
                <WarningAlert>{t("There are no defined aisles/bin locations yet")}</WarningAlert>
            }
        </BusyOverlay>
    </>;
}

export default AislesView;
import SelectInput from "Components/Form/SelectInput"
import TextInput from "Components/Form/TextInput";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { StateContract } from "api/types/contracts/common";
import { EntitySelectInputProps } from "helpers/types";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { loadStates } from "slices/common/thunk";

type StateSelectProps = EntitySelectInputProps<StateContract, "name"> & {
    countryCode?: string
}

const StateSelect = ({ isMulti, value, onChange, onSelect, name, isValid, countryCode, ...rest }: StateSelectProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { states, loading } = useAppSelector(
        (state) => ({
            states: state.Common.states || [],
            loading: state.Common.loading.states
        })
    );

    const stateList = useMemo(() => states.map(c => ({ value: c.name, label: c.name})), [states]);

    useEffect(() => {
        if (countryCode) {
            dispatch(loadStates({
                countryCode: countryCode
            }));
        }
    }, [countryCode]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = states.filter(s => val.includes(s.name));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            if (states.length > 0) {
                const selected = states.find(s => s.name === val);
    
                if (selected) {
                    onSelect?.(selected);
                    onChange?.(selected.name);
                    return;
                }
            }
            else {
                onSelect?.(undefined);
                onChange?.(val);
                return;
            }
        }

        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        if (!value) {
            return;
        }

        if (states.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [states]);

    const selectProps = {
        ...rest,
        placeholder: rest.placeholder ?? t("State"),
        busy: loading,
        options: stateList,
        showClear: true,
        isValid: isValid
    }

    if (states.length > 0) {
        if (isMulti) {
            return <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />;
        }
        else {
            return <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />;
        }
    }
    else {
        if (isMulti) {
            <SelectInput<string> isMulti display="tags" {...selectProps} />;
        }
        else {
            return <TextInput value={value} onChange={onChangedSingle} placeholder={t("State")} isValid={isValid} />;
        }
    }
}

export default StateSelect;
import { InboundStatus } from "api/types/contracts/inbound";
import { StatusBadgeProps } from "helpers/types";
import { useTranslation } from "react-i18next";
import { Badge } from "reactstrap";

const InboundStatusMap: Record<InboundStatus, { className: string }> = {
    "draft": { className: "bg-dark-subtle text-dark" },
    "confirmed": { className: "bg-secondary" },
    "checkIn": { className: "bg-primary" },
    "receiving": { className: "bg-warning" },
    "completed": { className: "bg-success" },
};

const InboundStatusBadge = (props: StatusBadgeProps<InboundStatus>) => {
    const { t } = useTranslation("enums");

    if (props.value === undefined) {
        return <></>;
    }

    const statusName = t(`InboundStatus.${props.value}`);
    const { className } = InboundStatusMap[props.value];

    return <Badge color="inbound-status" className={className}>{statusName}</Badge>
}

export default InboundStatusBadge;
import { APIClient } from "../helpers/api_helper";
import type { CreateStripeCreditCardCommand, CreateStripeSetupIntentCommand } from "./types/commands";
import type { StripeSetupIntentContract } from "./types/contracts/payment";

const api = new APIClient();

export const postCreateSetupIntent = async (data: CreateStripeSetupIntentCommand) => {
    const result = await api.post("/api/stripe/setup-intent", { ...data });
    return result.data as StripeSetupIntentContract;
}

export const postCreateStripeCreditCard = async (data: CreateStripeCreditCardCommand) => {
    await api.post(`/api/stripe/card`, { ...data });
}
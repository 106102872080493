import { useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";
import { useTranslation } from 'react-i18next';
import UpdateForm from './_UpdateForm';
import Activities from './_Activities';
import { CustomerContract } from 'api/types/contracts/customers';
import AvatarUpload from 'Components/AvatarUpload';
import { useMetadata } from 'Components/Hooks/MetadataHooks';
import UserStatusBadge from 'Components/Displays/UserStatusBadge';
import { useTenant } from 'Components/Hooks/TenantHooks';
import BusyOverlay from 'Components/Common/BusyOverlay';
import DateDisplay from 'Components/Displays/DateDisplay';
import { useMutation, useQuery } from '@tanstack/react-query';
import { patchUpdateUserStatus, postResetPassword } from 'api/user';
import { toast } from 'react-toastify';
import { getCustomer } from 'api/customer';

type ProfileProps = {
    customer: CustomerContract,
    onSuccess?: VoidFunction
}

const Profile = (props: ProfileProps) => {
    const [activeTab, setActiveTab] = useState("1");
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const { company } = useTenant();

    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const { data: customer, isPending: customerLoading, refetch: refreshCustomer } = useQuery({
        initialData: props.customer,
        queryKey: ["customer", props.customer.customerId],
        queryFn: async () => await getCustomer({ customerId: props.customer.customerId }),
        enabled: false
    });

    const activateUserMutation = useMutation({
        mutationFn: async () => {
            await patchUpdateUserStatus({
                userId: customer.user.userId,
                userStatus: "active"
            });
        },
        mutationKey: ["activate-user"],
        onSuccess: () => {
            toast.success(t("User activated"));

            refreshCustomer();
        }
    });

    const suspendUserMutation = useMutation({
        mutationFn: async () => {
            await patchUpdateUserStatus({
                userId: customer.user.userId,
                userStatus: "suspend"
            });
        },
        mutationKey: ["suspend-user"],
        onSuccess: () => {
            toast.success(t("User suspended"));

            refreshCustomer();
        }
    });

    const userPassworResetMutation = useMutation({
        mutationFn: postResetPassword,
        mutationKey: ["reset-password"],
        onSuccess: () => {
            toast.success(t("Password reset email has been sent to {{email}}", { email: customer.user.email }));
        }
    })
    
    setPageTitle(`${t("{{user}}'s Profile", { user: `${customer.name} ${customer.surname}` })} - ${t("Management")}`);

    return <>
        <Row>
            <Col xxl={3}>
                <Card body className='mb-0'>
                    <div>
                        <div className="text-center mb-3">
                            <AvatarUpload userId={customer.user.userId} avatar={customer.user.properties.avatar} onChange={() => refreshCustomer()} />
                            <h5 className="fs-16 mb-1">{customer.user.name}</h5>
                            <p className="mb-1">{customer.user.email}</p>
                            <p className='text-muted mb-1'>{customer.company.name}</p>
                        </div>
                    </div>
                    <div>
                        <div className="text-center mb-3">
                            <h5>{t("Last Login")}</h5>
                            <p><DateDisplay date={customer.user.lastLoginDate} /></p>
                        </div>
                        <div className="text-center mb-3">
                            <h5>{t("Status")}</h5>
                            <div>
                                <p>
                                    <UserStatusBadge value={customer.user.userStatus} />
                                </p>
                            </div>
                            {customer.user.userStatus !== "active" && <div>
                                <BusyOverlay size='sm' busy={activateUserMutation.isPending} inline>
                                    <Button size='sm' color="success" onClick={() => activateUserMutation.mutate()}>{t("Activate")}</Button>
                                </BusyOverlay>
                            </div>}
                        </div>
                        {company && <div className="text-center mb-3">
                            <h5>{t("Warehouses")}</h5>
                            <ul className="list-group">
                                {company.warehouses.filter(w => customer.properties.availableWarehouses.includes(w.warehouseId))
                                    .map(w => <li className="list-group-item" key={w.warehouseId}>{w.name}</li>)}
                            </ul>
                        </div>}
                    </div>
                    <div className='vstack gap-3'>
                        <BusyOverlay busy={userPassworResetMutation.isPending} size='sm'>
                            <Button size='sm' color="soft-warning" block
                                onClick={() => userPassworResetMutation.mutate({ userId: customer.user.userId })}>
                                    {t("Send Password Reset")}
                            </Button>
                        </BusyOverlay>
                        {customer.user.userStatus !== "suspend" && <div>
                            <BusyOverlay busy={suspendUserMutation.isPending} size='sm'>
                                <Button size='sm' color="soft-danger" block
                                    onClick={() => suspendUserMutation.mutate()}>
                                        {t("Suspend")}
                                </Button>
                            </BusyOverlay>
                        </div>}
                    </div>
                </Card>
            </Col>

            <Col xxl={9}>
                <Card className='mb-0'>
                    <CardHeader>
                        <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === "1" })} onClick={() => tabChange("1")}>
                                    <i className="fas fa-home"></i>
                                    {t("Personal Details")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink to="#" className={classnames({ active: activeTab === "2" })} onClick={() => tabChange("2")} type="button">
                                    <i className="far fa-user"></i>
                                    {t("Activities")}
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </CardHeader>
                    <CardBody className="p-4">
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <UpdateForm customer={customer} onSuccess={props.onSuccess} />
                            </TabPane>
                            <TabPane tabId="2">
                                <Activities />
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>
};

export default Profile;